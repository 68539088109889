import React from 'react';
import styled from 'styled-components';
import { useObject } from 'react-firebase-hooks/database'
import { NoticeDb } from '../../firebaseConfig'

const StyledNotice = styled.div`
  background-color: #fff;
  width: calc(100%);
  position: absolute;
  bottom: 220px;
  margin: 0px;
  z-index: 100;
  .text {
    background-color: rgba(209, 219, 226, 0.9);
    padding: 14px 10px;
    border-radius: 0 0 20px 20px;
  }
  .notice-message {
    font-weight: 500;
    color: #525252;
    font-size: 13px;
    vertical-align: bottom;
  }
  @media screen and (min-width: 540px) {
    bottom: 275px;
  }
`;

export default function MobileNotice() {
  const [snapshot, loading, error] = useObject(NoticeDb)

  if (loading || error) {
    return <></>
  }
  return (
    <StyledNotice>
      {snapshot.val() &&
        <div className='text'>
          <span className="notice-message">{snapshot.val()}</span>
        </div>
      }
    </StyledNotice>
  )
}