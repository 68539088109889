import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBmWL3XrV7bRXyD1sl_CmIIzRIyUlrzX2I",
  authDomain: "iswc2023-4b58d.firebaseapp.com",
  databaseURL: "https://iswc2023-4b58d-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "iswc2023-4b58d",
  storageBucket: "iswc2023-4b58d.appspot.com",
  messagingSenderId: "1038949656987",
  appId: "1:1038949656987:web:fb45674e76df6cce10593e"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const getDbRef = (dbRef) => {
  const envDbRef = `${process.env.NODE_ENV === 'production' ? 'production' : 'local'}/${dbRef}`
  return firebaseDb.ref(envDbRef)
}

const ChatDb = getDbRef('chat')
const HallOneDb = getDbRef('Hall_A_Question')
const HallTwoDb = getDbRef('Hall_B_Question')
const HallOneChatDb = getDbRef('Hall_A_Chat')
const HallTwoChatDb = getDbRef('Hall_B_Chat')
const NoticeDb = getDbRef('notice')
const NotificationDb = getDbRef('pushNotifications')
const OnlineDb = getDbRef('onlineStatus')
const PopupRef = getDbRef('LaunchPopup')
const VideoRef = getDbRef('GrowthCentre')
const TokenDb = getDbRef('token')

const getUniqueSessionDb = (uid) => getDbRef(`uniqueSession/${uid}`)
const getUserCountDb = (uid, path) => getDbRef(`userCount/${path}/${uid}`)
const getUserSessionDb = (uid) => getDbRef(`userSessions/${uid}`)
const getOnlineStatusDb = (uid) => getDbRef(`onlineStatus/${uid}`)
const getLiveCheckInSessionDb = (hallNumber) => getDbRef(`liveCheckInSession/hall-${hallNumber}`)
const getRefreshDb = (uid) => getDbRef(`shouldRefresh/${uid}`)
const getLiveSessionDb = (hallNumber) => getDbRef(`liveSession/${hallNumber}`)
const getLiveTokenDb = (hallNumber) => getDbRef(`missions/token-${hallNumber}`)

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  HallOneDb,
  HallTwoDb,
  HallOneChatDb,
  HallTwoChatDb,
  NoticeDb,
  NotificationDb,
  PopupRef,
  VideoRef,
  OnlineDb,
  TokenDb,
  getUniqueSessionDb,
  getUserCountDb,
  getUserSessionDb,
  getOnlineStatusDb,
  getLiveCheckInSessionDb,
  getRefreshDb,
  getLiveSessionDb,
  getLiveTokenDb,
}
