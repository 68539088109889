import React, { useCallback, useEffect } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { SIZES } from 'src/styles/theme'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBooths } from 'src/ducks/booth'
import Amplitude from 'src/utils/amplitudeHelper'

const StyledModal = styled(Modal)`
  .modal-content {
    border: none;
    background: transparent;
    border-radius: 0;
  }
  .box-dialog {
    border-radius: 8px;
    width: 90%;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 2rem 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    p {
      font-weight: 700;
      color: #5D4591;
    }

    a {
      display: block;
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
      color: #3A3D45;
      text-decoration: none;
      margin-top: 12px;
      background: #73E0EF;
      padding: 8px;
      border-radius: 8px;
      border-bottom: 3px solid #012F5B;
      box-shadow: 0px 2px 3px #012F5B;
      width: 100%;

      span {
        margin-right: 8px;
        visibility: hidden;
      }
      i {
        font-weight: 400;
      }
      &:hover {
        color: #fff;
        background: linear-gradient(#8468CE, #96F19C);
        span {
          visibility: visible;
        }
      }
    }
  }
  button.close {
    opacity: 1;
    padding: 4px auto !important;
    z-index: 100;
    position: absolute;
    top: 5%;
    right: 5%;
    span {
      font-weight: 400;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.9);
      text-shadow: none;
    }
  }
  ${SIZES.mobile} {
    .box-dialog {
      width: 100%;
      a {
        font-size: 13px;
        letter-spacing: 0;
      }
    }
  }
`

export default function ExhibitionEntryModal({ showModal, hideModal }) {
  const dispatch = useDispatch()
  const { booths } = useSelector((state) => state.booth)

  useEffect(() => {
    dispatch(getBooths(1))
    Amplitude.clickExhibition()
  }, [])

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        hideModal()
      }
    },
    [hideModal]
  )

  const clickOutsideToClose = (e) => {
    if ((e.target).id === 'ENTRY_SELECTION_MODAL') {
      hideModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <StyledModal id={'ENTRY_SELECTION_MODAL'} show={showModal} onHide={hideModal} aria-labelledby="game-modal" size="lg" centered onClick={clickOutsideToClose}>
      <div className="box-dialog">
        <button className='close' onClick={hideModal}>
          <span>x</span>
        </button>
        <p>Select a Booth to Visit</p>
        {booths.length === 0 && <p className='text-muted text-center'>Loading...</p>}
        <Row noGutters className='w-100'>
          {booths.map((entry) => (
            <Col md={6} className='px-2' key={entry.title}>
              <Link to={`/booths/${entry.id}`} onClick={hideModal}>
                {entry.title}
              </Link>
            </Col>
          ))}

        </Row>
      </div>
    </StyledModal>
  )
}
