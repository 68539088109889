import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom, EffectCoverflow } from 'swiper'
import { CoinComponent } from '../Gamification/CoinComponent'

const StyledModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
  }
  h5 {
    background-color: #fff;
    text-align: center;
    padding: 24px;
    border-radius: 20px;
  }
  .modal-header {
    padding: 8px;
    border: none;
    background: rgba(0, 0, 0, 0);
   
    button.close {
      opacity: 1;
      padding: 4px auto !important;
      z-index: 100;
      position: absolute;
      top: -3%;
      right: -3%;
      span {
        font-weight: 400;
        font-size: 48px;
        background: -webkit-linear-gradient(#8468CE, #5DE6E4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;
      }
    }
  }
`

export default function PosterModal({ mission = {}, images, posterOpen, setPosterOpen }) {
  return (
    <StyledModal
      show={posterOpen}
      onHide={()=>setPosterOpen(false)}
      aria-labelledby="images-modal"
      centered
      size="lg"
    > 
      { mission.mission_type === "image_gallery" && <CoinComponent mission={mission} />}

      <Modal.Header closeButton />

      <Modal.Body>
        {images.length > 0 &&
          <Swiper
            className='mySwiper'
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom, EffectCoverflow]}
            slidesPerView={1}
            navigation={true}
            effect="coverflow"
            zoom={true}
            pagination={{ 
              clickable: true,
              renderBullet: function (index, className) {
                return '<span class="' + className + '">' + (index + 1) + "</span>";
              },
            }}
            scrollbar={{ draggable: true }}
          >
            {images.map((poster,index)=>(
              <SwiperSlide key={`poster-${index}`} zoom={true}>
                <img className="d-block" src={poster.url} alt={`poster-${index}`} width="100%" />
              </SwiperSlide>
            ))}
          </Swiper>
        }
      </Modal.Body>
    </StyledModal>
  )
}
