import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import config from '../../config'
import { loginUser } from '../../ducks/auth'
import routes from '../../routes'
import { ForgetPassword } from './ForgetPassword'
import { LoginPageStyle } from './styled'
import whiteArrow from '../../images/right-arrow-white.png'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    loginUser(dispatch, {
      email,
      password,
    })
  }
  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  useEffect(() => {
    document.title = `${config.eventName} | Login`
  }, [])

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  } else {
    return (
      <LoginPageStyle>
        <>
          <form onSubmit={handleLogin} className='shadow'>

            <p className="title">Login</p>
            <input type="email" placeholder="Email Address" value={email} onChange={onEmailInput} />
            <input type="text" value={password} placeholder="Access Code" onChange={onPasswordInput} />
            <ForgetPassword />
            <div className="btn login-btn" onClick={handleLogin} style={{ background: isFetching ? 'lightgray' : 'linear-gradient(#8468CE, #96F19C)' }}>
              <input disabled={!(email.length && password.length) || isFetching} type="submit" value={isFetching ? '' : ''} />
              <div>
                <img src={whiteArrow} className="white-arrow" alt="icon" />
              </div>
            </div>
          </form>
        </>
      </LoginPageStyle>
    )
  }
}
