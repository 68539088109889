import React, { useState } from 'react'
import config from '../config'
import Amplitude from 'src/utils/amplitudeHelper'
import Axios from 'axios'
import { Button, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { SIZES } from 'src/styles/theme'

const NamecardWrapper = styled.div`
  color: #505c7c;
 
  .btn {
    display: block;
    width: 100%;
    margin-left: auto;
    border-radius: 28px;
    border: none;
    padding: 6px 16px;
    background: linear-gradient(#846CCC, #5D4591);
  }
  textarea {
    border-radius: 10px;
    padding: 10px 15px;
    border: solid 1px #707070;
    &:focus {
      box-shadow: none;
      border: 1px solid #846CCC;
    }
  }
  ${SIZES.mobile} {
    font-size: 14px;
    .btn {
      font-size: 14px;
    }
    textarea {
      font-size: 14px;
      border-radius: 8px;
      padding: 8px;
      border: solid 1px #707070;
      &:focus {
        box-shadow: none;
        border: 1px solid #846CCC;
      }
      &::placeholder {
        font-size: 12px;
      }
    }
  }
`

export default function BoothNameCard({ booth, currentUser, hideModal }) {
  const [message, setMessage] = useState('')
  const [load, setLoad] = useState(false)

  const handleInput = (e) => setMessage(e.target.value)

  const submitNamecard = () => {
    setLoad(true)
    Amplitude.dropNamecard({
      senderId: currentUser.id,
      senderFullName: currentUser.fullName,
      senderEmail: currentUser.email,
      receiverFullName: booth.title,
      receiverEmail: booth.email,
    })
    Axios({
      method: 'post',
      url: `${config.apiUrl}/api/booths/drop_namecard`,
      data: {
        booth_id: booth.id,
        message,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    })
      .then(() => {
        toast(`An email has been sent to ${booth.title}!`)
      })
      .catch((err) => {
        setLoad(false)
        setMessage('')
        toast('Sending name card failed. Something went wrong.');
      })
      .finally(() => {
        setLoad(false)
        hideModal(0)
        setMessage('')
      })
  }

  return (
    <NamecardWrapper>
      <p>
        Drop <strong>{booth.title}</strong> your name card!
      </p>
      <p>
        This will send {booth.title} an email with your contact information
        indicating that you wish to connect.
      </p>
      <p>You can also include custom message:</p>
      <Form.Control
        placeholder="(Optional)"
        value={message}
        onChange={handleInput}
        as="textarea"
        rows={3}
      />
      <Button disabled={load} onClick={submitNamecard} className="mt-2">
        {load ? 'Sending...' : 'Drop your name card'}
      </Button>
    </NamecardWrapper>
  )
}
