import styled from 'styled-components'

interface PinWrapperProps {
  css: string
}

export const PinWrapper = styled.div<PinWrapperProps>`
  ${(props) => props.css}
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;

  .pinImage,
  .pinText {
    position: absolute;
  }
  &:hover {
    .pinText {
      font-weight: 800;
      letter-spacing: 2px;
    }
  }

  .pinImage {
    z-index: 10;
    min-height: 100px;
    min-width: 100px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${(props) => props.textCss ? 'floating 7s infinite' : 'heartbeat 7s infinite'} ;
    filter: ${(props) => props.textCss ? "none" : "brightness(95%) contrast(140%);"};
    
    &:hover {
      animation-name: ${(props) => props.textCss ? 'floating' : 'bounce'} ;
      animation-duration: ${(props) => props.textCss ? '7s' : '1s'} ;
      animation-fill-mode: ${(props) => props.textCss ? 'none' : 'both'} ;
    }
  }
  .textParent {
    perspective: 200px;
    position: absolute;
    z-index: 1000;
    inset: 0;
  }
  .pinText {
    color: #fff;
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: 500;
    z-index: 1000;
    text-align: center;
    transform-style: preserve-3d;
    transition: all 200ms ease-in;
    ${(props) => props.textCss}

    span span {
      position: relative;
      bottom: -1em;
      opacity: 0;
      animation: move-text 3s infinite forwards;
    }
    
  }
  @keyframes move-text {
    0% {
      bottom: -0.25em;
      opacity: 1;
    }
    50% {
      bottom: 0.25em;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`
