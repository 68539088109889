import { useObject } from 'react-firebase-hooks/database'
import { firebaseDb, getRefreshDb, getOnlineStatusDb } from '../firebaseConfig'



function useRefresh(currentUser) {
  
  const refreshRef = getRefreshDb(currentUser.id)
  const statusRef = getOnlineStatusDb(currentUser.id)

  const [onlineSnapshot, onlineLoading, onlineError] = useObject(firebaseDb.ref(statusRef))
  const [snapshot, loading, error] = useObject(firebaseDb.ref(refreshRef))
  if (!currentUser.id) return

  
  if (!loading && !error) {
    if ( snapshot.val() === "yes" && onlineSnapshot.val() ) {
      window.location.reload()
    }
  }
}

export { useRefresh }
