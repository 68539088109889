import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import MobileNotice from './MobileNotice'
import Amplitude from '../../utils/amplitudeHelper'
import routes from '../../routes'
import config from '../../config'
import auditoriumIcon from '../../images/mobile/auditorium-icon.png'
import exhibitionIcon from '../../images/mobile/exhibition-icon.png'
import videoIcon from '../../images/mobile/video-icon.png'
import networkingIcon from '../../images/mobile/networking-icon.png'
import helpdeskIcon from '../../images/mobile/helpdesk-icon.png'
import { fixedAssets } from 'src/containers/PreCache'
import { useDispatch } from 'react-redux'
import { GlobalModalTypes } from 'src/components/GlobalModals/GlobalModalContainer'
import { showGlobalModal } from 'src/ducks/layout'
import { CoinComponent } from 'src/components/Gamification/CoinComponent'
import luckyDraw from '../../images/icons/coin-icon.png'

const MobileLobby = ({ currentUser }) => {
  const scrollRef = useRef(null)
  const dispatch = useDispatch()
  const [mission, setMission] = useState(null)

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
    Amplitude.visit('Lobby')
  }, [currentUser.id])

  useEffect(() => {
    setMission(currentUser.missions?.filter(ms=>ms.task_name==="lobby")[0])
  }, [currentUser.missions])

  const handleScrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400,
      behavior: 'smooth'
    });
  };

  const showModal = (type) => {
    if (type === "video") {
      dispatch(showGlobalModal(GlobalModalTypes.Welcome))
      Amplitude.click("Welcome Video")
    }
    if (type === "exhibition") {
      dispatch(showGlobalModal(GlobalModalTypes.EntrySelection))
    }
  }

  const showHelpDesk = () => {
    const cpt = window.chaport
    cpt.on('ready', function () {
      cpt.setVisitorData({
        name: currentUser.fullName,
        email: currentUser.email,
      })
      cpt.open()
    })
    cpt.on('collapsed', function () {
      cpt.close()
    })
    Amplitude.clickHelpDesk()
  }

  return (
    <Page>
      <div className='position-relative'>
        <img id="banner" src={fixedAssets.lobbyBanner} width="100%" className="mx-auto d-block" alt="event visual" />
        <img src={fixedAssets.lobbyM} width="100%" className="mx-auto d-block" alt="event visual" />
      </div>
      <CoinComponent mission={mission} hide={true} />

      <MobileNotice />
      <div className="menu">
        <p className="title">Virtual Halls</p>
        <div className="btn-container">
          <div id="moreBtn" onClick={handleScrollRight}>
            <em>More</em>
            <svg width="23.81" height="6.985" viewBox="0 0 23.81 6.985">
              <g transform="translate(0.75 1.06)">
                <path d="M677.491,759h21.685" transform="translate(-677.491 -754)" fill="none" stroke="#2f2651" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path d="M711.207,746.126l5.294,4.866" transform="translate(-694.501 -746.126)" fill="none" stroke="#2f2651" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
              </g>
            </svg>
          </div>
        </div>
        <div className="list-wrapper" ref={scrollRef}>
          <div className="item-box">
            <div className="icon-wrapper">
              <img className="menuicon" src={videoIcon} alt="icon" />
            </div>
            <p className="item-label">Welcoming Video</p>
            <div onClick={() => showModal('video')} className="item-button">
              Enter
            </div>
          </div>
          <div className="item-box">
            <div className="icon-wrapper">
              <img className="menuicon" src={auditoriumIcon} alt="icon" />
            </div>
            <p className="item-label">Gallery</p>
            <Link to={routes.onDemand} className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="menuicon" src={exhibitionIcon} alt="icon" />
            </div>
            <p className="item-label">Exhibition</p>
            <div onClick={() => showModal('exhibition')} className="item-button">
              Enter
            </div>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="menuicon" src={networkingIcon} alt="icon" />
            </div>
            <p className="item-label">Networking</p>
            <Link to={routes.networking} className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="menuicon" src={helpdeskIcon} alt="icon" />
            </div>
            <p className="item-label">Help Desk</p>
            <div onClick={showHelpDesk} className="item-button">
              Enter
            </div>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img width="100%" src={luckyDraw} alt="icon" />
            </div>
            <p className="item-label">Token Hunter</p>
            <Link to={routes.lucky} className="item-button">
              Enter
            </Link>
          </div>
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: #fff;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100% - 60px);
  width: 100vw;
  overflow: hidden;
  position: absolute;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  
  scrollbar-width: none; 

  #banner {
    position: absolute;
    top: 5px;
  }
  .menu {
    width: 100%;
    height: 220px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    background: #fff;
    border-radius: 0px;
  }

  .title {
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .btn-container {
    display: flex;
    position: absolute;
    top: 17px;
    right: 20px;
  }
  #moreBtn {
    width: 80px;
    height: 22px;
    display: flex;
    align-items: center;
    em {
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .list-wrapper {
    width: 100%;
    height: 160px;
    position: absolute;
    top: 50px;
    left: 0;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .item-box {
    width: 130px;
    height: 160px;
    margin-left: 20px;
    border: solid 2px #5DE6E4;
    border-radius: 10px;
    background: white;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-label {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: #272727;
    margin: 0px;
  }

  .item-button {
    background-color: #5DE6E4;
    color: #000;
    border: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
    width: 90px;
    height: 25px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menuicon {
    width: 100%;
  }

  @media screen and (min-width: 540px) {
    .menu {
      height: 275px;
    }
    .list-wrapper {
      top: 65px;
    }
  }
`

export default MobileLobby
