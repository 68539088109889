import Axios from 'axios'

export const get = (url: string, successCallback, errorCallback, params = {}, otherConfigs = {}) => {
  Axios({
    method: 'get',
    url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
    },
    params,
    ...otherConfigs,
  })
    .then(({ data, status }) => {
      successCallback(data, status)
    })
    .catch((error) => {
      errorCallback(error)
    })
}

export const post = (url: string, successCallback, errorCallback, data = {}, otherConfigs = {}) => {
  Axios({
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
    },
    data,
    ...otherConfigs,
  })
    .then(({ data, status }) => {
      successCallback(data, status)
    })
    .catch((error) => {
      errorCallback(error)
    })
}
