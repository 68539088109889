import React from 'react'
import styled from 'styled-components'
import { SIZES } from 'src/styles/theme'
import hintIcon from '../../images/gamification/hint.png'
import { useObject } from 'react-firebase-hooks/database'
import { TokenDb } from 'src/firebaseConfig'

const Wrapper = styled.div`
  position: fixed;
  top: 8.5%;
  left: 5%;
  cursor: pointer;
  color: #8468CE;
  font-weight: 500;
  span {
    transition: 100ms ease;
    opacity: 1;
    font-size: 0;
    margin-left: 8px;
  }
  &:hover {
    span {
      font-size: 14px;
      opacity: 1;
    }
  }
  img {
    filter: invert(46%) sepia(83%) saturate(2280%) hue-rotate(228deg) brightness(89%) contrast(78%);
  }
  ${SIZES.mobile} {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: static;
    span {
      opacity: 1;
      font-size: 14px;
      transition: 100ms ease;
      margin: 0 8px;
    }
  }
`

function HintComponent ({ showMission, handleShowHint, missionName }) {
  const [snapshot, loading, error] = useObject(TokenDb)

  if (!loading && !error && snapshot && snapshot.val() === "on") {
    return (
      <Wrapper onClick={handleShowHint}>
        <img src={hintIcon} width="36px" alt="hint"/>
        {showMission ? <small className="mx-2 ml-md-3 font-weight-bold">{missionName} Hint</small> : <span>{missionName} Hint</span>}
      </Wrapper>
    )
  }
  return (
    <></>
  )
}
export { HintComponent }