import React, { useRef } from 'react'
import { Carousel, Modal, Row } from 'react-bootstrap'
import { fixedAssets } from 'src/containers/PreCache'
import styled from 'styled-components'

export const speakers = [
  fixedAssets.speaker1, 
  fixedAssets.speaker2, 
  fixedAssets.speaker3, 
  fixedAssets.speaker4, 
  fixedAssets.speaker5, 
  fixedAssets.speaker6, 
  fixedAssets.speaker7, 
  fixedAssets.speaker8, 
  fixedAssets.speaker9, 
  fixedAssets.speaker10, 
  fixedAssets.speaker11, 
  fixedAssets.speaker12, 
  fixedAssets.speaker13, 
  fixedAssets.speaker14, 
  fixedAssets.speaker15, 
  fixedAssets.speaker16, 
]
const StyledModal = styled(Modal)`
  text-align: center;
  color: #002060;

  .carousel-control-next, 
  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-prev {
    left: -10%;
  } 
  .carousel-control-next {
    right: -10%;
  }
  span.carousel-control-next-icon, span.carousel-control-prev-icon {
    background-color: #29005A;
    padding: 8px;
    border: 10px solid #29005A;
    border-radius: 50%;
  }

  .modal-dialog {
    min-width: 75%;
  }
  .modal-content {
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 0;
    padding: 24px 36px;
    margin-bottom: 24px;

    .modal-header {
      color: #FFAD2C;
      border: none;
      text-align: left;
      .modal-title {
        font-size: 36px;
        font-weight: bold;
        font-style: italic;
      }
      button {
        font-size: 36px;
        opacity: 1;
        color: rgba(0, 0, 0, 0.55);
      }
    }
    .header-section {

      .navigation {
        height: 100%;
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        .animated-btn {
          background: linear-gradient(#AB48FF, #29005A);
        }
        button {
          background: linear-gradient(#AB48FF, #29005A);
          color: white;
          border: 2px solid #fff;
          border-radius: 4px;
          padding: 6px 16px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          h5 {
            margin: 0 4px;
            font-size: 18px;
          }
          &:hover {
            background: gold;
          }
        }
      }
    }
    .divider {
      background: #002060;
      height: 4.5px;
      margin: 32px 0;
    }
  }
  .back-top {
    border: none;
    border-radius: 50%;
    background: linear-gradient(#AB48FF, #29005A);
    position:absolute;
    padding: 0;
    bottom: 0;
    right: 0;
    svg {
      width:50;
      height:50;
    }
    &:hover {
      transition: all 300ms;
      transform: scale(1.1);
    }
  }
  .modal-body {
    padding-bottom: 36px;
  }
  .agenda {
    button {
      margin: 0 16px;
      background: #F87949;
      border: 2px solid #FF9821;
      font-weight: 500;
      border-radius: 12px;
      padding: 4px 12px;
      color: white;
      font-size: 16px;
    }
    .active {
      font-style: italic;
      color: #002060;
      text-decoration: underline;
    }
  }
  .image {
    height: 184px;
    width: 184px;
    transition: 100ms linear;
    background-position: center;
    background-size: cover;
  }
  h5.speaker-name {
    font-size: 15px;
    font-weight: 600;
    color:#0c6bae;
  }
  .speaker-title {
    font-size: 24px;
    color: #426fa8;
    font-weight: bold;
  }
  #agenda-container {
    width: 100%;
    margin: 0 auto;
    header {
      font-size: 24px;
      button {
        padding: 6px 16px;
      }
    }
    section {
      text-align: left;
      font-size: 18px;
    }
  }
`

export default function ProgrammeModal({ showModal, hideModal }) {
  const speakerSec = useRef()
  const agendaSec = useRef()
  const startModal = useRef()

  const scrollTop = (ref) =>{
    ref.current.scrollIntoView({ behavior: 'smooth' })
  };
  
  return (
    <StyledModal
      show={showModal}
      onHide={hideModal}
      size="xl"
      aria-labelledby="menu-modal"
      centered
    >
      <Modal.Header closeButton>
        <span ref={startModal}></span>
      </Modal.Header>
      <Modal.Body>
        <Row className="header-section" noGutters>
          <div className="navigation">
            <div className='animated-btn' onClick={()=>scrollTop(agendaSec)}>
              <span>Agenda</span>
            </div>
            <div className='animated-btn' onClick={()=>scrollTop(speakerSec)}>
              <span>Speakers</span>
            </div>
          </div>
        </Row>
        <span ref={agendaSec}></span>
        <div className="divider"></div>

        <div id="agenda-container">
          <img src={fixedAssets.agenda} width="100%" className='mx-auto d-block' alt="event agenda" />
        </div>

        <div className="divider"></div>

        <div className="container">
          <div ref={speakerSec}></div>
          <Carousel>
            {speakers.map((speaker,index)=>(
              <Carousel.Item key={`speaker-${index}`} interval={4000}>
                <img className="d-block" src={speaker} alt={`speaker-${index}`} width="100%" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        <button onClick={()=>scrollTop(startModal)} className="back-top">
          <svg width="45" height="45" viewBox="0 0 45 45">
            <g transform="translate(-1767 -3747)">
              <g transform="translate(120 -2428)">
                <path d="M22.175,9.358a1.43,1.43,0,0,0-1.43-1.43H5.063l5.813-5.42A1.43,1.43,0,1,0,8.923.421L.455,8.311a1.43,1.43,0,0,0,0,2.091L8.923,18.3A1.43,1.43,0,1,0,10.875,16.2L5.063,10.788H20.746A1.43,1.43,0,0,0,22.175,9.358Z" transform="translate(1678.681 6187) rotate(90)" fill="#fff"/>
              </g>
            </g>
          </svg>
        </button>
      </Modal.Body>
    </StyledModal>
  )
}
