import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import config from '../config'
import { COLORS, SIZES } from '../styles/theme'
import { ChatDb, firebaseDb } from '../firebaseConfig'
import ChatCard from '../components/Networking/ChatCard'
import UserDirectory from '../components/Networking/UserDirectory'
import useChat from '../utils/chatHelper'
import Amplitude from '../utils/amplitudeHelper'
import { fixedAssets } from 'src/containers/PreCache'
import { getUsers } from 'src/ducks/networking'
import { useDispatch, useSelector } from 'react-redux'
import { CoinComponent } from 'src/components/Gamification/CoinComponent'

const Page = styled.div`
  padding-top: ${SIZES.navHeight};
  height: 100vh;
  background-size: cover;
  background-image: url(${fixedAssets.networking});
  background-repeat: no-repeat;
  text-align: center;
  overflow: hidden;

  main {
    width: 96%;
    margin: auto;
    background: rgba(255, 255, 255, 0.8);
    height: calc(100vh - 100px);
    border-radius: 10px;
    padding: 1rem;
    margin-top: 2rem;
  }
  .chat-content {
    height: 80%;
  }
  .chat-header {
    header {
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(30, 37, 58, 0.2);
      
      .contact-list {
        padding-bottom: 3px;
        border-bottom: 2px solid transparent;
        color: #1E253A;
        cursor: pointer;
        
        span {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
      .active {
        color: #846CCC;
        border-bottom: 2px solid #846CCC;
      }
    }
  }
  #refresh-btn {
    border: none;
    background: white;
    padding: 5px 10px;

    &:hover {
      border: solid 1px ${COLORS.primary};
    }
  }
`

export default function NetworkingLoungePage({ currentUser }) {
  const {
    messages,
    messagesLoading,
    messagesError,
    handleSubmitMessage,
    submitLoading,
    handleFetchOlderMessages,
    fetchLoading,
    scrollTrigger,
  } = useChat(ChatDb);

  const dispatch = useDispatch()
  const [viewUser, setViewUser] = useState(null)
  const { users } = useSelector((state) => state.networking)
  const [showDirectory, setShowDirectory] = useState(false)
  const [mission, setMission] = useState(null)

  const onlineUsersDbRef = `${
    process.env.NODE_ENV === 'production' ? 'production' : 'local'
  }/onlineStatus`
  const [onlineUsers, setOnlineUsers] = useState([])
 
  useEffect(() => {
    dispatch(getUsers())
    document.title = `${config.eventName} | Networking`
    Amplitude.visit('Networking')
  }, [])

  useEffect(() => {
    setMission(currentUser.missions?.filter(ms=>ms.task_name==="networking")[0])
  }, [currentUser.missions])

  useEffect(() => {
    const ref = firebaseDb.ref(onlineUsersDbRef)
    ref.on(
      'value',
      function (snapshot) {
        const data = snapshot.val() || {}
        let combinedArrays = []
        Object.keys(data).forEach((key) => {
          combinedArrays = [
            ...combinedArrays,
            {
              id: key,
              online: data[key]
            }
          ]
        })  
        setOnlineUsers(combinedArrays)
      },
      function (errorObject) {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [])

  const refreshPage = () => {
    window.location.reload()
  }

  const toggleDirectory = () => {
    if (showDirectory) {
      setViewUser(null)
    }
    setShowDirectory(!showDirectory)
  }

  const toggleAvatar = (avatarId) => {
    if (avatarId) {
      if (users) {
        let filtered = users.filter((user) => user.id === avatarId)
        setViewUser(filtered[0])
      }
    } else {
      setViewUser(null)
    }
  }

  return (
    <Page>
      <div id="content">
        <Row noGutters className="row-wrapper">
          <Col sm={6} lg={{ span: 6,  offset: 3 }}>
            <main className='shadow'>
              <div className="chat-header">
                <header className="d-flex justify-content-between align-items-end">
                  <div className={`${!showDirectory && 'active'} contact-list`} onClick={toggleDirectory}>
                    <span>Networking</span>
                  </div>
                  <div className={`${showDirectory && 'active'} contact-list`} onClick={toggleDirectory}>
                    <span>Find who’s here </span>
                  </div>
                </header>
              </div>
              {!showDirectory ?
                <>
                  {messagesError ? (
                    <button id="refresh-btn" onClick={refreshPage}>
                      Connection failed. Click here to refresh.
                    </button>
                  ) : (
                    <div className="chat-content">
                      <ChatCard
                        messages={messages}
                        messagesLoading={messagesLoading}
                        handleFetchOlderMessages={handleFetchOlderMessages}
                        fetchLoading={fetchLoading}
                        scrollTrigger={scrollTrigger}
                        handleSubmitMessage={handleSubmitMessage}
                        submitLoading={submitLoading}
                        currentUser={currentUser}
                        toggleAvatar={toggleAvatar}
                      />
                    </div>
                  )}
                </>
                :
                <div className="px-3 h-100">
                  <UserDirectory
                    currentUser={currentUser}
                    users={users}
                    onlineUsers={onlineUsers}
                    toggleAvatar={toggleAvatar}
                    showUser={viewUser}
                  />
                </div>
              }
            </main>
          </Col>
          <Col sm={3} lg={4}></Col>
        </Row>
        { !fetchLoading && <CoinComponent mission={mission} hide={true} />}
      </div>
    </Page>
  )
}
