import { fixedAssets } from 'src/containers/PreCache'
import { COLORS, SIZES } from 'src/styles/theme'
import styled from 'styled-components'

export const LoginPageStyle = styled.section`
  position: fixed;
  max-height: 100%;
  text-align: center;
  color: white;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background: url(${fixedAssets.entranceBg});
  background-size: cover;
  background-position: center;
 
  .action-text {
    cursor: pointer;
  }
  
  .mobile-show {
    display: none;
    height: 50vh;
    top: -25%;
    object-fit: scale-down;
  }
 
  form {
    background: #fff;
    color: #404040;
    border-radius: 28px;
    margin: auto;
    margin-top: 30px;
    max-width: 375px;
    position: fixed;
    bottom: 5vh;
    right: 5vw;
    padding: 2vw 3vw;
    max-height: 100%;
    text-align: center;

    input {
      width: 100%;
      background: ${COLORS.lightGray};
      border: none;
      padding: 12px 18px;
      border-radius: 8px;
      margin-top: 20px;

      &::placeholder {
        color: ${COLORS.lightGray1};
      }
    }

    .forget-pass {
      color: ${COLORS.primaryTrans};
      margin-top: 10px;
      font-size: 0.9em;
    }

    .login-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 1em;
      margin-bottom: 0em;
      margin-right: 0;
      border-radius: 50%;
      letter-spacing: 1px;
      background: linear-gradient(#8468CE, #96F19C);
      width: 60px;
      height: 60px;
      cursor: pointer;
      opacity: 1;
      outline: none;
      border: none;
      input {
        cursor: pointer;
        opacity: 0;
        padding: 0;
        margin: 0;
        display: none;
      }
    }
    .title {
      font-weight: 500;
      font-size: 20px;
      margin: 0;
      color: #404040;
    }
    .white-arrow {
      width: 24px;
      line-height: 60px;
      cursor: pointer;
    }
  }

  ${SIZES.mobile} {
   
    .mobile-show {
      display: block;
    }
  }
`
export const MobileLogin = styled.section`
  background: #fff;
  #formContainer {
    padding: 2rem 2rem;
  }
  #inputContainer {
    width: 100%;
    text-align: center;
  }
  .inputWrapper {
    width: 100%;
    padding: 1rem 20px;
    border-radius: 8px;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    flex: 1; 
    i {
      color: ${COLORS.lightGray1};
      font-size: 20px;
      width: 20px;
      height: 20px;
      flex: 1; 
    }
    input {
      flex: 10; 
      font-size: 15px;
      background: transparent;
      border: none;
      margin-left: 14px;
      font-weight: 500;
      &::placeholder {
        color: ${COLORS.lightGray1};
      }
    }
  }
  .title {
    border-bottom: 2px solid #5DE6E4;
    padding: 12px 20px;
    text-align: center;
    p {
      color: #292929;
      margin: 0 auto;
      width: 90%;
      font-weight: 600;
      font-size: 17px;
    }
    span {
      color: #51ADB5;
      font-size: 28px;
      font-weight: 800;
    }
  }
  #banner {
    position: absolute;
    top: 5px;
  }
  .login-btn {
    color: #000;
    margin: 1em auto;
    border-radius: 18px;
    letter-spacing: 1px;
    background: #5DE6E4;
    cursor: pointer;
    width: 100%;
    padding: 8px 20px;

    input[type="submit"] {
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: #000;
      background: transparent;
      padding: 0;
      border: none;
      margin: 0;
    }
  }

`