import axios from 'axios'
import { LIVE_SESSION_APIS } from 'src/apis/liveSessionApi'

const SEND_REQUEST = 'SEND_REQUEST'
const RECEIVE_SESSIONS = 'RECEIVE_SESSIONS'
const RECEIVE_SESSION = 'RECEIVE_SESSION'
const FILTER_FIELD = 'FILTER_FIELD'
const SET_LOADING = 'SET_LOADING'

const initialState = {
  sessions: [],
  session: {},
  filterBy: { type: 'date', tabText: 'Day 1', tabValue: '18 MAR' },
  sessionLoading: false,
}

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_REQUEST:
      return {
        ...state,
        sessions: [],
        sessionLoading: true,
      }
    case RECEIVE_SESSIONS:
      return {
        ...state,
        sessions: action.sessions,
        sessionLoading: false,
      }
    case RECEIVE_SESSION:
      return {
        ...state,
        session: action.session,
      }
    case FILTER_FIELD:
      return {
        ...state,
        filterBy: action.filterBy,
      }
    case SET_LOADING:
      return {
        ...state,
        sessionLoading: action.loading,
      }
    default:
      return state
  }
}

export function getLiveSessions() {
  return (dispatch) => {
    dispatch(requestSessions())

    return axios
      .get(LIVE_SESSION_APIS.GET_SESSIONS, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveSessions(data.live_sessions))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getLiveSession(sessionId) {
  return (dispatch) => {
    return axios
      .get(LIVE_SESSION_APIS.GET_SESSION(sessionId), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveSession(data.session))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function requestSessions() {
  return {
    type: SEND_REQUEST,
  }
}
export function receiveSessions(sessions) {
  return {
    type: RECEIVE_SESSIONS,
    sessions,
  }
}
export function receiveSession(session) {
  return {
    type: RECEIVE_SESSION,
    session,
  }
}
export function receiveFilter(filterBy) {
  return {
    type: FILTER_FIELD,
    filterBy,
  }
}
export function setLoading(loading) {
  return {
    type: SET_LOADING,
    loading,
  }
}