import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ChatDb, firebaseDb } from '../../firebaseConfig'
import config from '../../config'
import ChatCard from '../../components/Networking/ChatCard'
import UserDirectory from '../../components/Networking/UserDirectory'
import useChat from '../../utils/chatHelper'
import Amplitude from '../../utils/amplitudeHelper'
import { fixedAssets } from 'src/containers/PreCache'
import { getUsers } from 'src/ducks/networking'
import { useDispatch, useSelector } from 'react-redux'
import MobileHeader from 'src/components/MobileHeader'
import { CoinComponent } from 'src/components/Gamification/CoinComponent'

const MobileNetworking = ({ currentUser }) => {
  const dispatch = useDispatch()
  const onlineUsersDbRef = `${
    process.env.NODE_ENV === 'production' ? 'production' : 'local'
  }/onlineStatus`

  const [viewUser, setViewUser] = useState(null)
  const [showDirectory, setShowDirectory] = useState(false)
  const { users } = useSelector((state) => state.networking)
  const [onlineUsers, setOnlineUsers] = useState([])
  const [mission, setMission] = useState(null)

  useEffect(() => {
    dispatch(getUsers())
    document.title = `${config.eventName} | Networking`
    Amplitude.visit('Networking')
  }, [])

  useEffect(() => {
    setMission(currentUser.missions?.filter(ms=>ms.task_name==="networking")[0])
  }, [currentUser.missions])

  const {
    messages,
    messagesLoading,
    messagesError,
    handleSubmitMessage,
    submitLoading,
    handleFetchOlderMessages,
    fetchLoading,
    scrollTrigger,
  } = useChat(ChatDb);

  useEffect(() => {
    const ref = firebaseDb.ref(onlineUsersDbRef)
    ref.on(
      'value',
      function (snapshot) {
        const data = snapshot.val() || {}
        let combinedArrays = []
        Object.keys(data).forEach((key) => {
          combinedArrays = [
            ...combinedArrays,
            {
              id: key,
              online: data[key]
            }
          ]
        })  
        setOnlineUsers(combinedArrays)
      },
      function (errorObject) {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [])


  const refreshPage = () => {
    window.location.reload()
  }

  const toggleDirectory = () => {
    if (showDirectory) {
      setViewUser(null)
    }
    setShowDirectory(!showDirectory)
  }

  const toggleAvatar = (avatarId) => {
    if (avatarId) {
      if (users) {
        let filtered = users.filter((user) => user.id === avatarId)
        setViewUser(filtered[0])
      }
    } else {
      setViewUser(null)
    }
  }

  return (
    <Networking>
      <MobileHeader />
      <main className='shadow'>
        <div className="chat-header">
          <header className="d-flex justify-content-between align-items-end">
            <div className={`${!showDirectory && 'active'} contact-list`} onClick={toggleDirectory}>
              <span>Networking</span>
            </div>
            <div className={`${showDirectory && 'active'} contact-list`} onClick={toggleDirectory}>
              <span>Find who’s here </span>
            </div>
          </header>
        </div>
        {(!showDirectory ?
          <>
            {messagesError ? (
              <button onClick={refreshPage}>
                Connection failed. Click here to refresh.
              </button>
            ) : (
              <div className="chat-content">
                <ChatCard
                  toggleAvatar={toggleAvatar}
                  messages={messages}
                  messagesLoading={messagesLoading}
                  handleFetchOlderMessages={handleFetchOlderMessages}
                  fetchLoading={fetchLoading}
                  scrollTrigger={scrollTrigger}
                  handleSubmitMessage={handleSubmitMessage}
                  submitLoading={submitLoading}
                  currentUser={currentUser}
                />
              </div>
            )}
          </>
          :
          <div className="chat-content">
            <UserDirectory
              currentUser={currentUser}
              users={users}
              onlineUsers={onlineUsers}
              toggleAvatar={toggleAvatar}
              showUser={viewUser}
            />
          </div>
        )}
      </main>
      {!fetchLoading && <CoinComponent mission={mission} hide={true} />}
    </Networking>
  )
}

const Networking = styled.div`
  background: url(${fixedAssets.networkingM});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  height: calc(100% - 50px);
  width: 100vw;
  overflow: hidden;
  position: absolute;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  
  scrollbar-width: none; 

  .backBtn {
    width: 90%;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    small {
      font-size: 14px;
      margin-left: 8px;
    }
  }
  main {
    width: 96%;
    margin: auto;
    background: rgba(255, 255, 255, 0.8);
    height: calc(100% - 100px);
    border-radius: 10px;
    padding: 1rem;
  }
  .chat-content {
    height: 100%;
  }
  .chat-header {
    header {
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(30, 37, 58, 0.2);
      
      .contact-list {
        padding-bottom: 3px;
        border-bottom: 2px solid transparent;
        color: #1E253A;
        
        span {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
      .active {
        color: #846CCC;
        border-bottom: 2px solid #846CCC;
      }
    }
  }
`

export default MobileNetworking
