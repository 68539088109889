import { fixedAssets } from 'src/containers/PreCache'
import { SIZES } from 'src/styles/theme'
import styled from 'styled-components'

export const StyledLobby = styled.div`
  background: url(${fixedAssets.lobby});
  background-size: 100% 100%;
  background-position: center top;
  text-align: center;
  max-height: 100%;
  min-width: 100%;
  min-height: 100vh;
  
  #current-user {
    pointer-events: none;
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 10px;
    color: gray;
  }
  .trailer {
    /* background-color: #000; */
    position: absolute;
    top: 22%;
    left: 37%;
    right: 37.1%;
    bottom: 52.5%;
    img {
      width: 100%;
      /* height: 100%; */
    }
  }
  #game {
    position: absolute;
    width: 20%;
    display: flex;
    justify-content: center;
    pointer-events: none;
    top: 55%;
    left: 80%;
    bottom: 3%;
    right: 0%;
  }
 
  ${SIZES.mobile} {
    .mobile-show {
      display: block;
    }
    .visual {
      width: 30%;
    }
  }
`
