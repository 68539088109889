import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { CoinComponent } from './CoinComponent'
import { Link } from 'react-router-dom'
import routes from 'src/routes'
import { useObject } from 'react-firebase-hooks/database'
import { TokenDb } from 'src/firebaseConfig'

const StyledModal = styled(Modal)`
  animation: appear 0s 1s forwards;
  opacity: 0;
  .modal-content {
    margin: 0 auto;
    border: none;
    border-radius: 28px;
    background: rgba(255, 255, 255, 1);
    text-align: center;
    border: 2px solid #8468CE;
  }
  .modal-header {
    border: none;
    
    .close {
      color: #8468CE;
      opacity: 1;
      padding: 0 16px;
      font-size: 40px;
      font-weight: 400;
      text-shadow: none;
    }
  }

  .modal-body {
    /* padding: 18px 12px; */
  }
  h5, strong {
    color: #8468CE;
    font-weight: 600;
    margin-bottom: 4px;
  }
  p {
    font-size: 15px;
    margin-bottom: 4px;
  }
  a {
    font-size: 13px;
    font-weight: 600;
    color: #8468CE;
  }
`

function MobileCoinModal ({ mission, coinModal, setCoinModal }) {
  const [snapshot, loading, error] = useObject(TokenDb)

  if (!loading && !error && snapshot && snapshot.val() === "on") {
    return (
      <StyledModal backdrop={false} show={coinModal} onHide={()=>setCoinModal(false)} aria-labelledby="collect-coin-modal" size="lg" >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CoinComponent mission={mission} isMobile />
          <h5>Good Work!</h5>
          <p><strong>You've found a token!</strong></p>
          <p>Click on the token above to collect.</p>
          <Link to={routes.lucky}>See My Progress</Link>
        </Modal.Body>
      </StyledModal>
    )
  }
  return (
    <></>
  )
}
export { MobileCoinModal }