import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { fixedAssets } from 'src/containers/PreCache'
import { SIZES } from 'src/styles/theme'
import Amplitude from 'src/utils/amplitudeHelper'

const StyledModal = styled(Modal)`
  left: ${(props) => (props.open ? '0' : '-100%')};
  z-index: 10001;
  transition: 1s !important;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  
  scrollbar-width: none; 

  .modal-dialog {
    width: 100%;
    max-width: 75%;
    margin: 0 !important;
    border-radius: 0;
  }
  .modal-content {
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 0;
    padding: 24px 72px;
    margin-bottom: 24px;
    width: 100%;
    min-height: 100vh;
    max-width: 100%;

    .modal-header {
      background: -webkit-linear-gradient(#8468CE, #5DE6E4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border: none;
      .modal-title {
        font-size: 36px;
        font-weight: bold;
      }
      button {
        font-size: 36px;
        opacity: 1;
        font-weight: 400;
        background: -webkit-linear-gradient(#8468CE, #5DE6E4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;
      }
    }
  }
  .divider {
    background: linear-gradient(90deg, #8468CE, #5DE6E4);
    height: 3px;
  }
  .modal-body {
    padding-bottom: 36px;
    width: 100%;
    max-width: 100%;
  }
  .flex-container {
    display: flex;
    justify-content: center;
  }
  .back-top {
    border: none;
    border-radius: 50%;
    background: linear-gradient(to bottom, #8468CE, #5DE6E4);
    position: absolute;
    padding: 0;
    bottom: 10px;
    right: 10px;
    svg {
      width: 40px;
      height: 40px;
    }
    &:hover {
      transition: all 300ms;
      transform: scale(1.1);
    }
  }
  aside {
    position: fixed;
    top: 100px;
    left: 75%;
    transition: 1s;
    z-index: 1000;
    opacity: 0;
    animation: appear 500ms 1s forwards;
    display: ${(props) => (props.open ? 'block' : 'none')};
  }
  .side-tab {
    background: rgba(0, 0, 0, 0.55);
    width: 100px;
    color: rgba(93, 230,228, 1);
    border: 2px solid rgba(93, 230,228, 1);
    border-left: none;
    padding: 8px 16px;
    margin-bottom: 1rem;
    letter-spacing: 2px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    transition: all 300ms ease-in;
    border-radius: 0px 10px 10px 0px;
  }
  aside {
    .active {
      background: rgba(93, 230,228, 0.85);
      color: #000;
      border: 2px solid rgba(0, 0, 0, 0.55);
      border-left: none;
    }
  }
  .session-tab {
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    letter-spacing: 2px;
    border-bottom: 2px solid #000;
    transition: all 300ms ease-in;
  }
  header {
    display: none;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0.5rem;
  }
  header {
    .active {
      color: #8468CE;
      border-bottom: 2px solid #8468CE;
    }
  }
  
  ${SIZES.mobile} {
    aside {
      display: none;
    }
    header {
      display: flex;
    }
    
    .modal-dialog {
      max-width: 100%;
    }
    .modal-content {
      padding: 0 24px;
      .modal-header {
        button {
          font-size: 30px;
        }
        .modal-title {
          font-size: 24px;
        }
      }
    }
    .modal-body {
      position: static;
      overflow: initial;   
      padding-top: 0px !important;   
      padding-left: 0px !important;   
      padding-right: 0px !important;   
    }
  }
`

export default function AgendaModal({ showModal, hideModal }) {
  const startModal = useRef()
  const [open, setOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(1)

  useEffect(() => {
    Amplitude.clickAgenda()
    setOpen(true)
  }, [])

  const scrollTop = (ref) =>{
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  const toggleModal = () => {
    setOpen(false)
    setTimeout(hideModal, 900)
  }

  return (
    <StyledModal
      open={open} 
      show={showModal} 
      onHide={toggleModal}
      size="xl"
      aria-labelledby="agenda-speakers"
      centered
    >
      <aside>
        <div className={`${selectedTab === 1 && 'active'} side-tab`} onClick={() => setSelectedTab(1)}>
          <span>DAY 1</span>
        </div>
        <div className={`${selectedTab === 2 && 'active'} side-tab`} onClick={() => setSelectedTab(2)}>
          <span>DAY 2</span>
        </div>
      </aside>
      <Modal.Header closeButton>
        <span ref={startModal}></span>
        <Modal.Title>PROGRAMME</Modal.Title>
      </Modal.Header>

      <div className="divider"></div>

      <Modal.Body>
        <header>
          <div className={`${selectedTab === 1 && 'active'} session-tab`} onClick={() => setSelectedTab(1)}>
            <span>DAY 1</span>
          </div>
          <div className={`${selectedTab === 2 && 'active'} session-tab`} onClick={() => setSelectedTab(2)}>
            <span>DAY 2</span>
          </div>
        </header>
        <div className='flex-container'>
          {selectedTab === 1 ?
            <img src={fixedAssets.agenda1} className="d-block mx-auto" width="100%" alt="Agenda" />
            :
            <img src={fixedAssets.agenda2} className="d-block mx-auto" width="100%" alt="Agenda" />
          }
        </div>
        <button onClick={()=>scrollTop(startModal)} className="back-top">
          <svg width="45" height="45" viewBox="0 0 45 45">
            <g transform="translate(-1767 -3747)">
              <g transform="translate(120 -2428)">
                <path d="M22.175,9.358a1.43,1.43,0,0,0-1.43-1.43H5.063l5.813-5.42A1.43,1.43,0,1,0,8.923.421L.455,8.311a1.43,1.43,0,0,0,0,2.091L8.923,18.3A1.43,1.43,0,1,0,10.875,16.2L5.063,10.788H20.746A1.43,1.43,0,0,0,22.175,9.358Z" transform="translate(1678.681 6187) rotate(90)" fill="#fff"/>
              </g>
            </g>
          </svg>
        </button>
      </Modal.Body>
    </StyledModal>
  )
}
