const cacheWebImages = (images) => {
  // Remove nulls and empty strings from array
  const imgUrls = images.filter((link) => link && link.length)
  const promises = imgUrls.map(
    (url) =>
      new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url
        img.onload = () => {
          resolve(url)
        }
        img.onerror = reject
      })
  )

  return Promise.all(promises)
}

export { cacheWebImages }
