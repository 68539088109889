import React, { useState } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import ReactTimeAgo from 'react-time-ago'

const Message = styled.div`
  text-align: left;
  margin: 8px 0;

  .message {
    border-radius: 15px;
    padding: 8px;
    margin: 0 4px;
    color: #3c3c3c;
  }
  .received {
  }
  .sent {
    flex-direction: row-reverse;
  }
  .sender {
    font-size: 10px;
    min-width: 100px;
    max-width: 100px;

    .name {
      margin-top: 3px;
      color: black;
      text-transform: capitalize;
    }

    .timestamp {
      font-size: 0.8em;
    }
  }
  .chat-profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;

    .avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`

const ChatMessage = React.memo(
  ({
    dbMessage,
    dbUser,
    dbTimestamp,
    dbUserAvatarUrl,
    dbUserId,
    currentUser,
    toggleAvatar,
  }) => {
    const received = dbUser !== currentUser.fullName
    const [showAvatar, setShowAvatar] = useState(true)

    return (
      <Message>
        <div
          className={`d-flex align-items-center mb-2 ${
            received ? 'received' : 'sent'
          }`}
        >
          <div className="text-center sender">
            <div
              onClick={() => toggleAvatar(dbUserId)}
              className="chat-profile-image d-block mx-auto"
            >
              {dbUserAvatarUrl ? (
                <>
                  <img
                    alt="avatar"
                    src={dbUserAvatarUrl}
                    width="30px"
                    onError={(e) => {
                      e.target.onerror = null
                      setShowAvatar(false)
                    }}
                    className={showAvatar ? 'avatar' : 'd-none'}
                  />
                  <Avatar
                    name={dbUser}
                    size="30"
                    round
                    className={!showAvatar ? 'd-block' : 'd-none'}
                  />
                </>
              ) : (
                <Avatar name={dbUser} size="30" round />
              )}
              {/* {
                dbUserAvatarUrl ?
                <img src={dbUserAvatarUrl} width="30px" className="avatar" alt="profile-image" />
                :
                <Avatar name={dbUser} size="30" round />
              } */}
              {/* <Avatar name={dbUser} size="30" round /> */}
            </div>
            <div className="name">{dbUser.toLowerCase()}</div>
            <small className="text-muted d-block timestamp">
              {dbTimestamp && <ReactTimeAgo date={dbTimestamp} />}
            </small>
          </div>
          <span className="message">{dbMessage}</span>
        </div>
      </Message>
    )
  }
)

export default ChatMessage
