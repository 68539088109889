import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import BoothNameCard from '../BoothNameCard'

const CustomModal = styled(Modal)`
  color: #505c7c;
  .modal-content {
    padding: 16px;
    border-radius: 24px;
  }
  .modal-header {
    border: none;
    padding: 0 8px;
    justify-content: flex-end;
  }
  .modal-body {
    padding: 0 16px;
  }
`

export default function BoothNamecardModal({ currentUser, booth, modal, hideModal }) {
  return (
    <CustomModal
      show={modal}
      onHide={hideModal}
      aria-labelledby="drop-name-card"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <BoothNameCard booth={booth} currentUser={currentUser} hideModal={hideModal} />
      </Modal.Body>
    </CustomModal>
  )
}
