import routes from './routes'
import { LobbyEntryProps, LobbyEntryTypes } from './components/LobbyEntry/LobbyEntry'

// icons & decorations
import { GlobalModalTypes } from './components/GlobalModals/GlobalModalContainer'
import { fixedAssets } from './containers/PreCache'

const dev = {
  apiUrl: 'http://localhost:3000',
  // apiUrl: 'https://iswc23-kwsp-7773cc115731.herokuapp.com',
}

const prod = {
  apiUrl: 'https://iswc23-kwsp-7773cc115731.herokuapp.com',
}

const API_KEYS = {
  amplitude: '859971d753a8f84adc135014cd24ffd6',
}

const VOLUME_VALUE = 0.4

// Root page will show either EntrancePage or CountdownPage
// based on the following time setting
const EVENT_START_TIME = {
  year: 2023,
  month: 7,
  day: 5,
  hour: 12,
  minute: 0,
}

const config = process.env.NODE_ENV === 'production' ? prod : dev

const layout = {
  auditoriumVid: `
    top: 9%;
    bottom: 21%;
    left: 5%;
    right: 32%;
  `,
}

const lobbyEntries: Array<LobbyEntryProps & { id: string }> = [
  {
    id: 'networking-entrance',
    type: LobbyEntryTypes.DirectRoute,
    destination: routes.networking,
    css: `
      top: 54.5%;
      left: 18.5%;
      right: 67.3%;
      bottom: 31%;
    `,
    pinText: "NETWORKING",
    textCss: `
      top: 50%;
      left: 0%;
      right: 0%;
      bottom: 57%;
      text-shadow: -3px -3px 3px rgba(255,255,255,.1), 3px 3px 3px rgba(0,78,255,.5);
    `,
  },
  {
    id: 'auditorium-entrance',
    type: LobbyEntryTypes.DirectRoute,
    destination: routes.onDemand,
    css: `
      top: 54.5%;
      left: 41.5%;
      right: 44.4%;
      bottom: 31%;
    `,
    pinText: "GALLERY",
    textCss: `
      top: 50%;
      left: 0%;
      right: 0%;
      bottom: 57%;
      text-shadow: -3px -3px 3px rgba(255,255,255,.1), 3px 3px 3px rgba(255,0,186,.5);
    `,
  },
  {
    id: 'exhibition-entrance',
    type: LobbyEntryTypes.GlobalModal,
    modalId: GlobalModalTypes.EntrySelection,
    css: `
      top: 55%;
      left: 65%;
      right: 20.5%;
      bottom: 31%;
    `,
    pinText: "EXHIBITION",
    textCss: `
      top: 50%;
      left: 0%;
      right: 0%;
      bottom: 57%;
      text-shadow: -3px -3px 3px rgba(255,255,255,.1), 3px 3px 3px rgba(255,39,0,.5);
    `,
  },
  {
    id: 'helpdesk',
    type: LobbyEntryTypes.Helpdesk,
    css: `
      top: 70%;
      left: 8.5%;
      right: 83.5%;
      bottom: 27%;
    `,
    pinText: "HELPDESK",
    textCss: `
      top: 0%;
      left: 0%;
      right: 0%;
      bottom: 0%;
      font-size: 20px;
      text-shadow: -3px -3px 3px rgba(255,255,255,.1), 3px 3px 3px rgba(0,245,255,.5);
    `,
  },
  {
    id: 'token-hunter',
    type: LobbyEntryTypes.GlobalModal,
    modalId: GlobalModalTypes.Game,
    pinImage: fixedAssets.entranceIcon,
    css: `
      top: 75%;
      left: 85%;
      right: 8%;
      bottom: 12%;
    `,
    pinText: "TOKEN HUNTER",
    textCss: `
      top: -80%;
      left: 0%;
      right: 0%;
      bottom: 0%;
      font-size: 26px;
      text-shadow: -3px -3px 3px rgba(255,255,255,.1), 3px 3px 3px rgba(0,0,0,.5);
    `,
  },
]

export default {
  API_KEYS,
  layout,
  lobbyEntries,
  VOLUME_VALUE,
  EVENT_START_TIME,

  eventName: 'ISWC 2023 | International Social Wellbeing Conference 2023',

  ...config,
}
