import { toast } from 'react-toastify'
import { get, post } from 'src/apis/apiService'
import { firebaseLogout } from 'src/utils/logoutStatus'
import config from '../config'
import { firebaseAuth } from '../firebaseConfig'
import { handleDeviceSession } from '../utils/sessionHelper'

const LOGIN_REQUEST = 'LOGIN_REQUEST'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export interface CurrentUserType {
  id: string
  role: string
  email: string
  [key: string]: any
}

export interface LoginCredentialsTypes {
  email: string
  password: string
}

export interface SsoCredentialsType {
  sso_token: string
}

/**** Initial States ****/

const initialState = {
  appReady: false,
  isFetching: false,
  isSsoLoading: true,
  isAuthenticated: localStorage.getItem('jwt_token') ? true : false,
  currentUser: {},
}

/**** Reducers ****/

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        currentUser: action.user,
        appReady: true,
        isSsoLoading: false,
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        isSsoLoading: false,
      }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        currentUser: {},
      }
    default:
      return state
  }
}

/**** Actions ****/

export function refreshUser(dispatch) {
  get(
    `${config.apiUrl}/api/auto_login`,
    (data, status) => {
      if (status === 200 && data.success) {
        dispatch(receiveLogin(data.user))
      } else {
        dispatch(rejectLogin())
      }
    },
    (error) => {
      dispatch(rejectLogin())
      console.error('Error: ', error)
    }
  )
}

export function loginUser(dispatch, credentials?: LoginCredentialsTypes | SsoCredentialsType, ssoLogin?: boolean) {
  dispatch(requestLogin())
  const loginEndpoint = ssoLogin ? `${config.apiUrl}/api/sso_login` : `${config.apiUrl}/api/login`
  post(
    loginEndpoint,
    async (data, status) => {
      if (status === 200 && data.success) {
        const { user: firebaseUser } = await firebaseAuth.signInWithCustomToken(data.firebaseToken)
        // Because we are not setting up a dev env firebase db
        // we do not want to set user's email in Firebase if the log in
        // is happening on development environment to prevent email unique constraint error
        if (process.env.NODE_ENV === 'production') firebaseUser.updateEmail(data.user.email)
        handleDeviceSession(data.user.id)
        localStorage.setItem('jwt_token', data.token)
        dispatch(receiveLogin(data.user, `Hello ${data.user.fullName}, welcome to ${config.eventName}!`))
        console.info('Firebase: ', firebaseUser.email)
      } else {
        dispatch(rejectLogin(data.error.response?.data?.message))
      }
    },
    (error) => {
      console.log(error.response)
      dispatch(rejectLogin(error.response?.data?.message))
      console.error('Error: ', error)
    },
    credentials
  )
}

const clearUserSession = () => {
  localStorage.removeItem('jwt_token')
  localStorage.removeItem('session_token')
  localStorage.removeItem('node')
  localStorage.removeItem('shouldLogout')
}

export function logoutUser(dispatch, currentUser: CurrentUserType, currentPagePath: string, message: string) {
  firebaseLogout(currentUser, currentPagePath)
  firebaseAuth
    .signOut()
    .then(() => {
      clearUserSession()
      dispatch(receiveLogout(message))
    })
    .catch((error) => {
      console.error(error)
    })
}

function requestLogin() {
  return {
    type: LOGIN_REQUEST,
  }
}

function receiveLogin(user, message?: string) {
  if (message) toast(message, { toastId: message })
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

function rejectLogin(message?: string) {
  if (message) toast.error(message)
  return {
    type: LOGIN_FAILURE,
  }
}

function receiveLogout(message?: string) {
  if (message) toast(message, { toastId: 'receiveLogoutToast' })
  return {
    type: LOGOUT_SUCCESS,
  }
}
