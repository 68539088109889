import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'

import WelcomePage from '../pages/WelcomePage'
import routes from '../routes'
import { SIZES } from '../styles/theme'
import { fixedAssets } from './PreCache'

const Page = styled.div`
  background: url(${fixedAssets.entranceBg}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  ${SIZES.mobile} {
    background: url(${fixedAssets.entranceBg});
    background-size: cover;
    background-position: top;

  }
`

export default function EntrancePages({ children }) {
  return (
    <Page>
      <Switch>
        <Route exact path={routes.root} component={WelcomePage} />
      </Switch>
    </Page>
  )
}
