import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom, EffectCoverflow } from 'swiper'
import Amplitude from 'src/utils/amplitudeHelper'

const StyledModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
  }
  h5 {
    background-color: #fff;
    text-align: center;
    padding: 24px;
    border-radius: 20px;
  }
  .modal-header {
    padding: 8px;
    border: none;
    background: rgba(0, 0, 0, 0);
   
    button.close {
      opacity: 1;
      padding: 4px auto !important;
      z-index: 100;
      position: absolute;
      top: -3%;
      right: -3%;
      span {
        font-weight: 400;
        font-size: 48px;
        background: -webkit-linear-gradient(#8468CE, #5DE6E4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .download-btn {
    border-radius: 0;
    color: #000;
    background: linear-gradient(45deg, #8468CE, #5DE6E4);
    border: none;
    padding: 8px;
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    transition: all 1s ease;
    &:hover {
      background: linear-gradient(135deg, #5DE6E4, #8468CE);
    }
  }
`

export default function GalleryPosterModal({ image, posterOpen, setPosterOpen }) {
  
  const handleDownload = () => {
    window.open(image.embedUrl, "_blank")
    Amplitude.downloadGallery(image)
  }

  return (
    <StyledModal
      show={posterOpen}
      onHide={setPosterOpen}
      aria-labelledby="images-modal"
      centered
      size="xl"
    > 
      <Modal.Header closeButton />

      <Modal.Body>
        <button className='download-btn' onClick={handleDownload}>
          <span className='mr-4'>Download</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="#000" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline stroke="#000" points="7 10 12 15 17 10"></polyline>
            <line stroke="#000" x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
        </button>
        <Swiper
          className='mySwiper'
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom, EffectCoverflow]}
          slidesPerView={1}
          navigation={true}
          effect="coverflow"
          zoom={true}
          pagination={{ 
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '">' + (index + 1) + "</span>";
            },
          }}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide key={`poster-${image.title}`} zoom={true}>
            <img className="d-block" src={image.embedUrl} alt={`poster-${image.title}`} width="100%" />
          </SwiperSlide>
      </Swiper>
      </Modal.Body>
    </StyledModal>
  )
}
