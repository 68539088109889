import { get } from '../apis/apiService'
import config from '../config'

const SEND_REQUEST = 'SEND_REQUEST'
const RECEIVE_USERS = 'RECEIVE_USERS'
const RECEIVE_PRIVATE_CHANNELS = 'RECEIVE_PRIVATE_CHANNELS'
const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL'
const SET_CURRENT_TIME = 'SET_CURRENT_TIME'
const GET_LAST_READ = 'GET_LAST_READ'
const SEND_PUBNUB_REQUEST = 'SEND_PUBNUB_REQUEST'
const SET_MEMBER_NAME = 'SET_MEMBER_NAME'
const REQUEST_CHANNEL_DATA = 'REQUEST_CHANNEL_DATA'
const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

const initialState = {
  users: [],
  onlineUsers: [],
  usersLoading: false,
  pubnubLoading: false,
  channelsLoading: false,
  privateChannels: [],
  channelsWithLastRead: [],
  selectedChannel: {},
  currentTimeToken: '',
  searchQuery: null,
  initialLoading: true,
}

export default function networkingReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_REQUEST:
      return {
        ...state,
        usersLoading: true,
      }
    case SEND_PUBNUB_REQUEST:
      return {
        ...state,
        pubnubLoading: true,
      }
    case REQUEST_CHANNEL_DATA:
      return {
        ...state,
        channelsLoading: true,
      }
    case RECEIVE_USERS:
      return {
        ...state,
        usersLoading: false,
        users: action.users,
      }
    case RECEIVE_PRIVATE_CHANNELS:
      return {
        ...state,
        privateChannels: action.channels.sort((a,b) =>{
          return new Date(b.updated).getTime() - new Date(a.updated).getTime()
        }),
        channelsLoading: false,
        initialLoading: false,
      }
    case SET_CURRENT_CHANNEL:
      return {
        ...state,
        selectedChannel: action.channel,
      }
    case SET_CURRENT_TIME:
      return {
        ...state,
        currentTimeToken: action.time,
      }
    case GET_LAST_READ:
      return {
        ...state,
        channelsWithLastRead: action.channelsWithLastRead,
        pubnubLoading: false,
      }
    case SET_MEMBER_NAME:
      return {
        ...state,
        privateChannels: state.privateChannels.map((chan) => {
          if (chan.id === action.channel) {
            return {
              ...chan,
              name: action.displayName,
            }
          } else return chan
        }),
      }
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: state.query,
      }
    default:
      return state
  }
}

export function getUsers(query = '') {
  return (dispatch) => {
    dispatch(requestData())

    const queryParams = {}
    // if (query && query.length) {
    //   queryParams['search'] = query
    // } else {
    //   queryParams['page'] = 1
    // }

    return get(
      `${config.apiUrl}/api/users`,
      (data) => {
        if (data.success) {
          dispatch(receiveUsers(data.users))
        }
      },
      (err) => {
        console.error('Error: ', err)
      },
      queryParams
    )
  }
}

export function fetchPrivateChannels(fullName, channels) {
  return (dispatch) => {
    dispatch(requestChannels())
    // get private channels array of user
    return dispatch(
      receivePrivateChannels(
        channels.map((chan) => {
          return { 
            id: chan.id,
            name: getChannelName(chan, fullName),
            description: chan.description,
            updated: chan.updated
          }
        })
      )
    )
  }
}
const getChannelName = (channel, fullName) => {
  const names = channel.name.split('_')
  return names.find((name) => name !== fullName)
}

export function retrieveLastReadTime(pubnub) {
  return (dispatch) => {
    dispatch(requestPubnubData())
    return pubnub.objects.getMemberships(
      {
        include: { customFields: true },
      },
      (status, response) => {
        const channels = response?.data
        const updated = channels?.map((item) => {
          return {
            id: item.channel.id,
            lastReadTimetoken: item.custom?.lastReadTimetoken || '16357430607809643',
          }
        })
        dispatch(getLastReadTime(updated))
      }
    )
  }
}

function requestData() {
  return {
    type: SEND_REQUEST,
  }
}

function requestPubnubData() {
  return {
    type: SEND_PUBNUB_REQUEST,
  }
}
function requestChannels() {
  return {
    type: REQUEST_CHANNEL_DATA,
  }
}

function receiveUsers(users) {
  return {
    type: RECEIVE_USERS,
    users,
  }
}

function receivePrivateChannels(channels) {
  return {
    type: RECEIVE_PRIVATE_CHANNELS,
    channels,
  }
}

function getLastReadTime(channelsWithLastRead) {
  return {
    type: GET_LAST_READ,
    channelsWithLastRead,
  }
}

// function getChannelMember(channel, displayName) {
//   return {
//     type: SET_MEMBER_NAME,
//     channel,
//     displayName,
//   }
// }

export function selectPrivateChannel(channel) {
  return {
    type: SET_CURRENT_CHANNEL,
    channel,
  }
}
export function setCurrentTimeToken(time) {
  return {
    type: SET_CURRENT_TIME,
    time,
  }
}

export function setSearchQuery(query) {
  return {
    type: SET_SEARCH_QUERY,
    query,
  }
}