import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`

  .underlay {
    height: 100vh;
    display: inline-block;
    position: relative;

    &#lobby {
      .landscape {
        display: inline-block;
      }
    }

    .bg-img {
      position: relative;
      width: 100vw;
      height: 100%;
      pointer-events: none;
    }
  }
`

export default function PageWithBg({
  children,
  bgImg,
  avoidNavOverlap = false,
}) {
  return (
    <Wrapper avoidNavOverlap={avoidNavOverlap}>
      <div id='others' className="underlay">
        <img className="bg-img landscape" src={bgImg} alt="Page Background" />
        {children}
      </div>
    </Wrapper>
  )
}
