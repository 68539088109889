import React, { FC } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { onDemandCategories } from '../categories'
import { setPageTitle, setPreviousPage } from '../../../ducks/onDemand'
import routes from 'src/routes'
import { toast } from 'react-toastify'

export const CategorySelection: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(setPageTitle('Gallery'))
    dispatch(setPreviousPage(routes.lobby))
  }, [dispatch])

  const handlePlayback = (item) => {
    history.push(item.destination)
  }

  return (
    <>
      <div className="grid-container">
        {onDemandCategories.map((item) => (
          <div onClick={()=>handlePlayback(item)} className="card-thumbnail" key={item.title}>
            <img src={item.thumbnail} width="100%" alt="thumbnail" />
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    </>
  )
}
