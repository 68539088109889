import React from 'react'
import styled from 'styled-components'
import coin from '../../images/gamification/instruction-coin.png'
import { SIZES } from '../../styles/theme'

const Wrapper = styled.div`
  padding: 12px;
  color: #022136;
  font-size: 90%;
  h6 {
    color: #2A6CFF;
    font-weight: 600;
  }
  h6, p {
    width: 90%;
    margin: 10px auto;
  }
  p {
    span {
      margin-left: 6px;
    }
  }
  
  ${SIZES.mobile} {
    h6 {
      text-align: center;
      padding-bottom: 6px;
      border-bottom: 2px solid #2A6CFF;
    }
    p {
      font-size: 80%;
    }
  }
`

function InstructionTab({ setTab }) {
  return (
    <Wrapper>
      <h6>How-To-Play Guide</h6>
      <p>
        Embark on the ISWC 2023 Virtual Token Quest, where incredible prizes worth up to RM300 await you! 
        To play, simply stay active and complete all the tasks, aiming for a score of <strong>150</strong> points. 
        Reach for the stars and secure the ultimate triumph, with the top three prizes valued at RM300, RM250, and RM150! 
        Brace yourself for a thrilling and fun-filled adventure that will keep you on the edge of your seat!
      </p>
      <img src={coin} className="ml-5" width="50%" alt="coin sample" />
      <p>(1 token = 10 points)</p>
      {/* <button onClick={()=>setTab("Mission Tasks")} className="start-button">START PLAYING</button> */}
    </Wrapper>
  )
}

export { InstructionTab }