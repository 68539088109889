import React from 'react'
import { Link } from 'react-router-dom'
import { fixedAssets } from 'src/containers/PreCache'
import styled from 'styled-components'
import routes from '../routes'
import { SIZES } from '../styles/theme'
import whiteArrow from '../images/right-arrow-white.png'

const Page = styled.div`
  position: fixed;
  max-height: 100%;
  text-align: center;
  color: white;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background: url(${fixedAssets.entranceBg});
  background-size: cover;
  background-position: center;

  #enter-btn {
    position: fixed;
    top: 78%;
    bottom: 5%;
    right: 10%;
    color: #fff;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: heartbeat 7s infinite;
    div {
      line-height: 32px;
    }
  }
  .click-login-text {
    font-weight: 600;
  }
  .button-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(#8468CE, #96F19C);
    width: 60px;
    height: 60px;
    cursor: pointer;
    opacity: 1;
    outline: none;
    border: none;
    input {
      cursor: pointer;
      opacity: 0;
      padding: 0;
      margin: 0;
      display: none;
    }
  }
  .white-arrow {
    width: 24px;
    line-height: 60px;
    cursor: pointer;
  }

  ${SIZES.mobile} {
    #enter-btn {
      top: 60%;
      bottom: 15%;
      left: 50%;
      transform: translateX(-50%);
    }
    .click-login-text {
      font-size: 16px;
    }
  }
`

export default function WelcomePage() {
  return (
    <Page>
      <Link id="enter-btn" to={routes.lobby} className="btn">
        <div className='button-bg'>
          <img src={whiteArrow} className="white-arrow" alt="icon" />
        </div>
        <div className="click-login-text">Click To Login</div>
      </Link>
    </Page>
  )
}
