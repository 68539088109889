import { fixedAssets } from 'src/containers/PreCache'
import styled from 'styled-components'

interface OnDemandStyleProps {
  mobileHeight: number
}

export const OnDemandStyle = styled.div<OnDemandStyleProps>`
  // background: url(${fixedAssets.galleryBg});
  background: #F5F5F5;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  height: ${(props) => props.mobileHeight - 50}px;
  width: 100vw;
  overflow-y: scroll;
  position: absolute;
  .backBtn {
    width: 90%;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    small {
      font-size: 14px;
      margin-left: 8px;
    }
  }
  main {
    width: 98%;
    min-height: 100%;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
  }
  .title {
    font-size: 18px;
    font-weight: 800;
    padding-bottom: 8px;
    background: -webkit-linear-gradient(#8468CE, #5DE6E4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1px solid rgba(30, 37, 58, 0.2);
  }
  .grid-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 45%);
    grid-auto-rows: max-content;
    justify-content: center;
    text-align: center;
    
    .card-thumbnail {
      display: block;
      cursor: pointer;
      position: relative;
      text-align: center;
      color: #525252;
      font-weight: 600;
      text-decoration: none;
      span {
        font-size: 12px;
      }
    }
  }
  .soon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #569099;
    font-weight: 800;
  }

`
