import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import { firebaseDb } from '../firebaseConfig'
import { COLORS } from '../styles/theme'
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from 'src/ducks/networking'

const Page = styled.div`
  background: white;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 50px;
  #board {
    border-radius: 24px;
    padding: 32px;
    width: 35%;
  }
  h4 {
    text-decoration: underline;
    margin: 8px auto;
  }
  span {
    background-color: #2e3188;
    color: white;
    border-radius: 4px;
    padding: 2px 4px;
  }
  .aggregate {
    border: 2px solid ${COLORS.primary};
  }
`

export default function SessionReportPage() {
  const sessionDbRef = `${
    process.env.REACT_APP_STAGE === 'production' ? 'production' : 'production'
  }/userSessions`

  const [pageData, setPageData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterStartTime, setFilterStartTime] = useState("July 6 2023 00:00:00 GMT+0800") // date need to format to local timezone
  const filterEndTime = "July 6 2023 23:59:59 GMT+0800"
  const [selectedPath, setSelectedPath] = useState('')
  const [showSession, setShowSession] = useState(false)
  const [aggregateDuration, setAggregateDuration] = useState([])
  const optionList = [
    '/home',
    '/lobby',
    '/join-session',
    '/networking',
    '/auditorium-hall',
    '/booths/1',
    '/booths/2',
    '/booths/3',
    '/booths/4',
    '/booths/5',
    '/booths/6',
    '/booths/7',
    '/booths/8',
    '/booths/9',
    '/booths/10',
    '/booths/11',
    '/booths/12',
    '/booths/13',
    '/booths/14',
    '/booths/15',
    '/booths/16',
  ]
  const dispatch = useDispatch()
  const { users } = useSelector((state) => state.networking)

  useEffect(() => {
    dispatch(getUsers())
  }, [])

  const headers = [
    { label: "Id", key: "id" },
    { label: "Role", key: "role" },
    { label: "Email", key: "email" },
    { label: "Full Name", key: "fullName" },
    { label: "Nationality", key: "nationality" },
    { label: "Attendance", key: "category" },
    { label: "First Enter", key: "enter" },
    { label: "Last Exit", key: "exit" },
    { label: "Duration", key: "duration" },
  ]
  console.log(users)

  const fullHeaders = [
    { label: "User Id", key: "userId" },
    { label: "Email", key: "email" },
    { label: "Page", key: "path" },
    { label: "Start", key: "start" },
    { label: "End", key: "end" },
    { label: "Duration", key: "duration" }
  ]
  const fullCsvReport = {
    data: filteredData,
    headers: fullHeaders,
    filename: `Full_${filterStartTime}.csv`
  }
  const getSessionsWithPath = (path) => {
    return pageData.filter(session => session['path'] === path)
  }

  const handleSelect = (e) => {
    const updated = getSessionsWithPath(`${e.target.value}`)
    const startEnd = updated.map((ses) => {
      return {
        ...ses,
        start: ses.start < Date.parse(filterStartTime) ? Date.parse(filterStartTime) : ses.start,
        end: ses.end > Date.parse(filterEndTime) ? Date.parse(filterEndTime) : ses.end,
      }
    }) 
    setFilteredData(startEnd)
    setSelectedPath(`${e.target.value}`)
    setAggregateDuration(calculateAggregateDuration(updated))
    console.log(`${e.target.value}`)
  }

  const handleShow = (e) => {
    setShowSession(e.target.checked)
  }
  const sortData = () => {
    // console.log(users)
    const newTotal = users.map(u => {
      const cur = aggregateDuration.filter(data => data.userId == u.id)
      console.log(cur)
      if (cur.length > 0) {
        return {
          ...u,
          enter: cur[0].enter,
          exit: cur[0].exit,
          duration: cur[0].duration
        }
      } 
      else {
        return {
          ...u,
          enter: 0,
          exit: 0,
          duration: 0
        }
      }
    })
    // console.log(newTotal)
    console.log("sort data!!")
    console.log("aggregate:",newTotal.filter(item => item['duration'] != 0))
    setAggregateDuration(newTotal)
  }
  
  const calculateAggregateDuration = (data) => {
    let usersAggDuration = []
    let userObject = {}
    console.log("data: ", data)

    data.forEach(session => {
      let initialValue;
      let enterTime;
      if (!userObject[session.userId]?.duration) {
        initialValue = 0
      } else {
        initialValue = userObject[session.userId].duration
      }

      let currentStart = session['start'] < Date.parse(filterStartTime) ? Date.parse(filterStartTime) : session['start']
      let currentEnd = session['end'] > Date.parse(filterEndTime) ? Date.parse(filterEndTime) : session['end']
      let currentDuration = currentEnd - currentStart
      
      if (!userObject[session.userId]?.firstEnter) {
        enterTime = currentStart
      } else {
        enterTime = userObject[session.userId].firstEnter
      }
      userObject = {
        ...userObject,
        [session.userId] : {
          userId: session.userId,
          email: session['email'],
          firstEnter: enterTime,
          lastExit: currentEnd,
          duration: initialValue + currentDuration
        },
      }
    })
    console.log("userObj: ", userObject)

    usersAggDuration = Object.keys(userObject).map(key=>({ 
      userId: key, 
      email: userObject[key]['email'], 
      enter: userObject[key]['firstEnter'], 
      exit: userObject[key]['lastExit'], 
      duration: userObject[key]['duration'] 
    }))
    console.log("userAgg: ", usersAggDuration)
    return usersAggDuration
  }

  useEffect(() => {
    const ref = firebaseDb.ref(sessionDbRef)
    ref.on(
      'value',
      function (snapshot) {
        const data = snapshot.val() || {}

        // combine all rows in one arrays
        let combinedArrays = []
        Object.keys(data).forEach((key) => {
          combinedArrays = [
            ...combinedArrays,
            ...Object.keys(data[key]).map( (fbKey)=> data[key][fbKey] )
          ]
        })  
        // start => 13:50, end => 14:40, filterStartTime => 14:00 - 14:05
        setPageData(combinedArrays.filter(session => 
          (session['start'] > Date.parse(filterStartTime) && session['start'] < Date.parse(filterEndTime)) 
          || 
          (session['start'] < Date.parse(filterStartTime) && (session['end'] > Date.parse(filterStartTime) && session['end'] < Date.parse(filterEndTime)) )
          ||
          (session['start'] < Date.parse(filterStartTime) && (session['end'] > Date.parse(filterEndTime)))
        ))
        setFilteredData(combinedArrays.filter(session => 
          (session['start'] > Date.parse(filterStartTime) && session['start'] < Date.parse(filterEndTime)) 
          || 
          (session['start'] < Date.parse(filterStartTime) && (session['end'] > Date.parse(filterStartTime) && session['end'] < Date.parse(filterEndTime)) )
          ||
          (session['start'] < Date.parse(filterStartTime) && (session['end'] > Date.parse(filterEndTime)))
        ))
        setAggregateDuration(calculateAggregateDuration(combinedArrays.filter(session => 
          (session['start'] > Date.parse(filterStartTime) && session['start'] < Date.parse(filterEndTime)) 
          || 
          (session['start'] < Date.parse(filterStartTime) && (session['end'] > Date.parse(filterStartTime) && session['end'] < Date.parse(filterEndTime)) )
          ||
          (session['start'] < Date.parse(filterStartTime) && (session['end'] > Date.parse(filterEndTime)))
        )))
        console.log("first")
      },
      function (errorObject) {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [])

  return (
    <Page>
      <div className="d-flex my-3">
        <select className="form-input" value={selectedPath} name="pagepath" onChange={handleSelect}>
          <option value="" disabled selected hidden>-- SELECT --</option>
          {optionList.map((item, i) => (
            <option key={`option-${i}`} value={item}>{item}</option>
          ))}
        </select>
        <div className="form-check mx-3">
          <input className="form-check-input" type="checkbox" value={showSession} onChange={sortData} id="flexCheckDefault" />
          <label className="form-check-label">
            Show Sessions
          </label>
        </div>
        <CSVLink 
          data={aggregateDuration}
          headers={headers}
          filename={`Total_${filterStartTime}.csv`}
          asyncOnClick={true}
          onClick={(event, done) => {
            sortData()
          }}
        >Export CSV</CSVLink>
        {/* <CSVLink 
          // {...fullCsvReport}
          data={aggregateDuration}
          headers={sessionHeaders}
          filename={`StartEnd_${filterStartTime}.csv`}
          asyncOnClick={true}
          onClick={(event, done) => {
            sortData()
          }}
        >Full CSV</CSVLink> */}
      </div>
      
      {
        !showSession ?
        <Table striped bordered>
          <thead>
            <tr>
              <th>User Id</th>
              <th>Email</th>
              <th>Duration(sec)</th>
            </tr>
          </thead>
          <tbody>
            {/* {aggregateDuration.map(({ userId, email, duration }) => (
              <tr key={duration}>
                <td>{userId}</td>
                <td>{email}</td>
                <td><strong>{(duration)/1000}</strong></td>
              </tr>
            ))} */}
          </tbody>
        </Table>
        :
        <Table striped bordered>
          <thead>
            <tr>
              <th>User Id</th>
              <th>Start</th>
              <th>End</th>
              <th>Page</th>
              <th>Duration(sec)</th>
            </tr>
          </thead>
          <tbody>
            {/* {aggregateDuration.map(({ userId, email, duration }) => (
              <>
                {filteredData.filter(session=> session.userId == userId).map(({ userId, path, start, end }) => (
                  <tr key={start}>
                    <td>{userId}</td>
                    <td>{new Date(start).toLocaleString()}</td>
                    <td>{new Date(end).toLocaleString()}</td>
                    <td>{path}</td>
                    <td>{(end - start)/1000}</td>
                  </tr>
                ))}
                <tr key={duration} className="aggregate">
                  <td>{userId}</td>
                  <td>{email}</td>
                  <td></td>
                  <td></td>
                  <td><strong>{(duration)/1000}</strong></td>
                </tr>
              </>
            ))} */}
          </tbody>
        </Table>
      }
    </Page>
  )
}