import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import { SIZES } from 'src/styles/theme'
import Amplitude from 'src/utils/amplitudeHelper'
import styled from 'styled-components'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const SharedModal = styled(Modal)`
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: initial !important;
  }

  .pdfPageSelector {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background: #2d2c3c;
    button {
      color: white;
      background: transparent;
      font-size: 12px;
      border: solid 1px white;
      margin: 0 8px;
      border-radius: 8px;
      padding: 1px 6px;
      &:hover {
        background: white;
        color: #2d2c3c;
      }
    }
  }
  .modal-header {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0;

    .modal-title {
      color: white;
      font-size: 16px;
    }
   
    .close {
      opacity: 1;
      z-index: 100;
      padding: 0.5rem;
      span {
        font-weight: 400;
        font-size: 30px;
        color: #fff;
      }
    }
  }
  .modal-content {
    background: transparent;
    border: none;
  }
  .modal-body, .modal-footer {
    padding: 0;
  }
  .download-btn {
    border-radius: 0;
    color: #000;
    background: linear-gradient(45deg, #8468CE, #5DE6E4);
    border: none;
    padding: 8px;
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    transition: all 1s ease;
    &:hover {
      background: linear-gradient(135deg, #5DE6E4, #8468CE);
    }
  }
  ${SIZES.mobile} {
    .modal-header {
      .modal-title {
        b {
          font-size: 14px;
        }
      }
    }
  }
`


export const GalleryDocumentViewer = ({ file, setDocumentSelected }) => {
  const [pdfPages, setPdfPages] = useState(null)

  const handleDownload = () => {
    window.open(file.embedUrl, "_blank")
    Amplitude.downloadGallery(file)
  }

  return (
    <SharedModal show={true} onHide={() => setDocumentSelected(null)} size="lg" aria-labelledby="gallery-pdf-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>{file?.title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <button className='download-btn' onClick={handleDownload}>
            <span className='mr-4'>Download</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="#000" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline stroke="#000" points="7 10 12 15 17 10"></polyline>
              <line stroke="#000" x1="12" y1="15" x2="12" y2="3"></line>
            </svg>
          </button>
          <Document
            file={file?.embedUrl}
            loading={<p className="text-center text-white mt-3">Loading pdf...</p>}
            onLoadSuccess={({ numPages }) => {
              setPdfPages(numPages)
            }}
          >
            {Array.apply(null, Array(pdfPages))
              .map((x, i)=>i+1)
              .map(page =>  <Page pageNumber={page} />)
            }
          </Document>
        </>
      </Modal.Body>
    </SharedModal>
  )
}
