import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import { firebaseDb } from '../firebaseConfig'

const Page = styled.div`
  background: white;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 50px;

  #board {
    border-radius: 24px;
    padding: 32px;
    width: 35%;
  }
  h4 {
    text-decoration: underline;
    margin: 8px auto;
  }
  span {
    background-color: #2e3188;
    color: white;
    border-radius: 4px;
    padding: 2px 4px;
  }
`

export default function UserSessionPage({ current_user }) {
  const envDbRef = `${
    process.env.NODE_ENV === 'production' ? 'production' : 'production'
  }/userSessions`

  const [pageData, setPageData] = useState([])
  const [filterUser, setFilterUser] = useState('1')

  useEffect(() => {
    const ref = firebaseDb.ref(envDbRef)
    ref.on(
      'value',
      function (snapshot) {
        const data = snapshot.val() || {}

        setPageData(
          Object.keys(data).map((key) => ({
            pageName: key,
            sessions: data[key],
          }))
        )
      },
      function (errorObject) {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [envDbRef])

  return (
    <Page>
      <label>User id : </label> <input onChange={(e)=>setFilterUser(e.target.value)} value={filterUser} />
      {pageData.map(({ pageName, sessions }) => (
        <>
          {
            filterUser === '' ?
            <>
              <h5 key={pageName}>
                UserId : <span>{pageName}</span>
              </h5>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Page</th>
                    <th>Duration(sec)</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(sessions).map((key, index)=>{
                      if (sessions[key]['start']) {
                        return (
                          <tr key={key}>
                            <td>{index+1}</td>
                            <td>{new Date(sessions[key]['start']).toString()}</td>
                            <td>{new Date(sessions[key]['end']).toString()}</td>
                            <td>{sessions[key]['path']}</td>
                            <td>{(sessions[key]['end'] - sessions[key]['start'])/1000}</td>
                          </tr>
                        )
                      }
                      return
                    })
                  }
                </tbody>
              </Table>
            </>
            :
            pageName === filterUser ?
              <>
                <h5 key={pageName}>
                  UserId : <span>{pageName}</span>
                </h5>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Page</th>
                      <th>Duration(sec)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(sessions).map((key, index)=>{
                        if (sessions[key]['start']) {
                          return (
                            <tr key={key}>
                              <td>{index+1}</td>
                              <td>{new Date(sessions[key]['start']).toString()}</td>
                              <td>{new Date(sessions[key]['end']).toString()}</td>
                              <td>{sessions[key]['path']}</td>
                              <td>{(sessions[key]['end'] - sessions[key]['start'])/1000}</td>
                            </tr>
                          )
                        }
                        return
                      })
                    }
                  </tbody>
                </Table>
              </>
            :
            <></>
          }
        </>
      ))}
    </Page>
  )
}
