import React, { FC } from 'react'
import { ReactChild } from 'react'
import * as Styled from './styled'

interface LoaderType {
  loading: boolean
  children?: ReactChild
  fullPage?: boolean
}

export const Loader: FC<LoaderType> = ({ loading, children, fullPage }) => {
  if (loading) {
    return (
      <Styled.LoadIcon fullPage={fullPage}>
        <div className="preloader">
          <div className="preloader-body">
            <div className="cssload-container">
              <div className="cssload-speeding-wheel"></div>
            </div>

            <p>Loading...</p>
          </div>
        </div>
      </Styled.LoadIcon>
    )
  }

  return <>{children}</>
}
