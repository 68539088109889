import { COLORS } from 'src/styles/theme'
import styled from 'styled-components'

export const StyledEntrySelectionModal = styled.div`
  z-index: 2000; /* bootstrap nav has 1030 */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .box-dialog {
    border-radius: 3px;
    width: 90%;
    max-height: 70vh;
    max-width: 600px;
    background-color: rgba(255, 255, 255, 0.9);
    text-align: center;
    padding: 10px 20px 10px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      padding-left: 1em;
      font-weight: 700;
      color: ${COLORS.text};
    }

    a {
      display: block;
      font-size: 1.9em;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: ${COLORS.cyan};
      text-decoration: none;
      margin-top: 5px;

      span {
        margin-right: 8px;
        visibility: hidden;
      }

      &:hover {
        color: ${COLORS.blue};
        span {
          visibility: visible;
        }
      }
    }
  }
`
