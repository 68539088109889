import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import config from '../../config'
import { loginUser } from '../../ducks/auth'
import routes from '../../routes'
import { fixedAssets } from 'src/containers/PreCache'
import { MobileLogin } from '../LoginPage/styled'
import { ForgetPassword } from '../LoginPage/ForgetPassword'

export const MobileLoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    loginUser(dispatch, {
      email,
      password,
    })
  }
  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  useEffect(() => {
    document.title = `${config.eventName} | Login`
  }, [])

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  } else {
    return (
      <MobileLogin>
        <>
          <div className='position-relative'>
            <img id="banner" src={fixedAssets.lobbyBanner} width="100%" className="mx-auto d-block" alt="event visual" />
            <img src={fixedAssets.lobbyM} width="100%" className="mx-auto d-block" alt="event visual" />
          </div>
          <div className='title shadow'>
            <p>Sign In</p>
          </div>
          <form id="formContainer" onSubmit={handleLogin}>
            <div className='flexBox'>
              <div id="inputContainer">
                <div className='inputWrapper shadow'>
                  <i className="fa fa-envelope"></i>
                  <input type="email" placeholder="E-mail" value={email} onChange={onEmailInput} />
                </div>
                <div className='inputWrapper shadow'>
                  <i className="fa fa-lock"></i>
                  <input type="password" value={password} placeholder="Password" onChange={onPasswordInput} />
                </div>
                <ForgetPassword />
                <div className="login-btn" onClick={handleLogin} style={{ background: isFetching ? 'lightgray' : '#5DE6E4' }}>
                  <input disabled={!(email.length && password.length) || isFetching} type="submit" value={isFetching ? 'Submitting...' : 'LOGIN'} />
                </div>
              </div>
            </div>
          </form>
        </>
      </MobileLogin>
    )
  }
}
