import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { onDemandVideosType } from 'src/components/OnDemandGallery/videos'
import Amplitude from 'src/utils/amplitudeHelper'
import { onDemandCategoryType } from '../categories'
import { setPageTitle, setPreviousPage } from '../../../ducks/onDemand'
import { StyledModal } from './styled'
import routes from 'src/routes'
import GalleryPosterModal from 'src/components/GlobalModals/GalleryPosterModal'
import { GalleryDocumentViewer } from 'src/components/GlobalModals/GalleryDocumentViewer'

enum UrlType {
  'mailto',
  'external',
  'internal',
}

function getLinkType(url: string): UrlType {
  if (url.startsWith('mailto')) {
    return UrlType.mailto
  }

  if (url.startsWith('http') || url.startsWith('//')) {
    return UrlType.external
  }

  return UrlType.internal
}

const externalAnchorProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

interface MainCategoryTypes {
  category: onDemandCategoryType
}

const MainCategory: FC<MainCategoryTypes> = ({ category }) => {
  const dispatch = useDispatch()
  const [currentVideo, setCurrentVideo] = useState<null | onDemandVideosType>(null)
  const [viewPoster, setViewPoster] = useState<null | onDemandVideosType>(null)
  const [viewDocument, setViewDocument] = useState<null | onDemandVideosType>(null)

  const closeVimeoPlayer = () => {
    setCurrentVideo(null)
  }

  useEffect(() => {
    dispatch(setPageTitle(category.title))
    dispatch(setPreviousPage(routes.onDemand))
  }, [category.categoryName, dispatch])

  const handleOpen = (item) => {
    if (item.itemType === "video") setCurrentVideo(item)
    if (item.itemType === "poster") setViewPoster(item)
    if (item.itemType === "pdf") setViewDocument(item)
    Amplitude.viewGalleryVideo(item.title, category.title)
  }

  return (
    <div className="grid-container">
      {(category.videos && category.videos.length > 0) ?
        category.videos.map((item) => (
          <React.Fragment key={item.embedUrl}>
            <div
              onClick={() => handleOpen(item)}
              className="card-thumbnail"
            >
              <img src={item.thumbnail} width="100%" alt="thumbnail" />
              <span>{item.title}</span>
            </div>
          </React.Fragment>
        ))
        :
        <h2 className='soon'>Coming Soon</h2>
      }
      <StyledModal show={currentVideo !== null} onHide={closeVimeoPlayer} size="lg" aria-labelledby="vimeoPlayer" centered>
        <Modal.Header closeButton>
          <Modal.Title id="vimeoPlayer">
            <b>{currentVideo?.title}</b>
            <small className='text-info'><br />{currentVideo?.desc}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="vimeoContainer">
            <iframe
              src={`${currentVideo?.embedUrl}&autoplay=1`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Vimeo Video"
            ></iframe>
          </div>
        </Modal.Body>
        {currentVideo?.buttonUrl && (
          <Modal.Footer>
            {getLinkType(currentVideo.buttonUrl) === UrlType.internal ? (
              <Link to={currentVideo.buttonUrl} className="btn btn-warning w-100">
                {currentVideo.buttonText}
              </Link>
            ) : (
              <a
                href={currentVideo.buttonUrl}
                className="btn btn-warning w-100"
                // inject target="_blank" and rel="noopener" props if url is external site
                {...(getLinkType(currentVideo.buttonUrl) === UrlType.external ? externalAnchorProps : {})}
              >
                {currentVideo.buttonText}
              </a>
            )}
          </Modal.Footer>
        )}
      </StyledModal>
      {viewPoster &&
        <GalleryPosterModal image={viewPoster} setPosterOpen={() => setViewPoster(null)} posterOpen={true} />
      }
      {viewDocument &&
        <GalleryDocumentViewer file={viewDocument} setDocumentSelected={setViewDocument} />
      }
    </div>
  )
}

export default MainCategory
