import React, { useMemo, useEffect, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import AOS from 'aos';
import Media from 'react-media'

import config from './config'
import routes from './routes'
import { SIZES } from './styles/theme'
import PrivateRoute from './containers/PrivateRoute'
import { PreCache } from './containers/PreCache'

import { MobileLoginPage } from './pages/MobileLoginPage/MobileLoginPage';
import MobileLobby from './mobileOnlyPages/MobileLobby';
import MobileNotifications from './mobileOnlyPages/MobileNotifications';
import MobileBooth from './webOnlyPages/MobileBooth';

import { LoginPage } from './pages/LoginPage';
import { LobbyPage } from './webOnlyPages/LobbyPage';
import BoothPage from './webOnlyPages/BoothPage';
import ActiveUserCountPage from './pages/ActiveUserCountPage';
import UserSessionPage from './pages/UserSessionPage';
import SessionReportPage from './pages/SessionReportPage';
import EntrancePages from './containers/EntrancePages';
import MobileNetworking from './mobileOnlyPages/MobileNetworking';
import NetworkingLoungePage from './webOnlyPages/NetworkingLoungePage';
import { MobileGamification } from './mobileOnlyPages/MobileGamification';
import { lazyWithRetry } from './utils/lazyWithRetry';
import { DesktopOnDemand } from './webOnlyPages/DesktopOnDemand';
import { MobileOnDemand } from './mobileOnlyPages/MobileOnDemand';

declare global {
  interface Window {
    chaport: any
  }
}

const ZoomContainer = lazyWithRetry(() => import('./pages/zoom/ZoomContainer'))
const ZoomPage = lazyWithRetry(() => import('./pages/zoom/ZoomPage'))

const ZoomExit = () => {
  useEffect(() => {
    // window.location.href = appConfig.EVENT_URL + '/agenda'
    window.parent.postMessage('LEAVE_ZOOM', "*")
  }, [])
  return <div>Exit</div>
}

function App() {
  useEffect(() => {
    document.title = config.eventName
    AOS.init({
      easing: "ease-in-out",
      once: true,
      offset: 50,
      anchorPlacement: 'top-bottom',
    });
  }, [])

  useEffect(() => {
    window.chaport.q('hideLauncher')
  }, [])

  return useMemo(() => {
    return (
      <Suspense fallback={<p className='text-center text-muted fw-bold'>Loading...</p>}>
        <div id="innerBody">
          <PreCache deviceType={'desktop'}>
            <Switch>
              <Route exact path={routes.root} component={EntrancePages} />

              <Route exact path="/session-reports" component={SessionReportPage} />
              <PrivateRoute blockedRoles={['participant', 'exhibitor', 'speaker']} exact path="/realtime" component={ActiveUserCountPage} />
              <PrivateRoute blockedRoles={['participant', 'exhibitor', 'speaker']} exact path="/sessions" component={UserSessionPage} />

              <Route path={routes.zoom} component={ZoomPage} />
              <PrivateRoute fixedNav path={routes.joinSession} component={ZoomContainer} />
              <PrivateRoute fixedNav path={routes.leaveSession} component={ZoomExit} />
            </Switch>
          </PreCache>

          <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
            {(matches) =>
              matches ? (
                // MOBILE ROUTES
                <PreCache deviceType={'mobile'}>
                  <Switch>
                    
                    <Route exact path={routes.login} component={MobileLoginPage} />

                    <PrivateRoute mobileNav path={routes.lobby} component={MobileLobby} />
                    <PrivateRoute mobileNav path={routes.networking} component={MobileNetworking} />
                    <PrivateRoute mobileNav path={routes.notifications} component={MobileNotifications} />

                    <PrivateRoute mobileNav path="/booths/:boothId" component={MobileBooth} />
                    <PrivateRoute mobileNav path={routes.lucky} component={MobileGamification} />
                    <PrivateRoute mobileNav path={routes.onDemand} component={MobileOnDemand} />

                  </Switch>
                </PreCache>
              ) : (
                // DESKTOP ROUTES
                <PreCache deviceType={'desktop'}>
                  <Switch>
                    <Route exact path={routes.login} component={LoginPage} />

                    <PrivateRoute fixedNav path={routes.lobby} component={LobbyPage} />
                    <PrivateRoute fixedNav path={routes.networking} component={NetworkingLoungePage} />
                    <PrivateRoute fixedNav path="/booths/:boothId" component={BoothPage} />
                    <PrivateRoute fixedNav path={routes.onDemand} component={DesktopOnDemand} />
                  </Switch>
                </PreCache>
              )
            }
          </Media>
        </div>
      </Suspense>
    )
  }, [])
}

export default App
