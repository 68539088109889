import { Modal } from 'react-bootstrap'
import { SIZES } from 'src/styles/theme'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  #vimeoContainer {
    // Vimeo default styling
    padding: 56.25% 0 0 0;
    position: relative;
    flex: 1;
  }
 

  .modal-header {
    .modal-title {
      color: white;
      font-size: 18px;
    }
    .close {
      color: white;
    }
  }

  .modal-content {
    background: rgba(0, 0, 0, 0.7);
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    padding: 0;
  }
  ${SIZES.mobile} {
    .modal-header {
      padding: 8px;
    }
    #vimeoPlayer {
      b {
        font-weight: 500;
        font-size: 14px;
      }
      small {
        font-size: 12px;
      }
    }
  }
`
