import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import { useHistory, useParams } from 'react-router-dom'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'
import { getBooth, getBooths } from '../ducks/booth'
import PageWithBg from '../components/PageWithBg'
import DownloadModal from '../components/GlobalModals/DownloadModal'
import IframeModal from '../components/GlobalModals/IframeModal'
import PosterModal from '../components/GlobalModals/PosterModal'
import InfoModal from '../components/GlobalModals/InfoModal'
import VideoModal from '../components/GlobalModals/VideoModal'
import { COLORS } from '../styles/theme'
import hintPointer from '../images/gamification/finger.png'
import { Loader } from 'src/components/shared/Loader'
import { DocumentViewer } from 'src/components/GlobalModals/DocumentViewer'
import { fixedAssets } from 'src/containers/PreCache'
import BoothNamecardModal from 'src/components/GlobalModals/BoothNamecardModal'
import { useObject } from 'react-firebase-hooks/database'
import { TokenDb } from 'src/firebaseConfig'
import { HintComponent } from 'src/components/Gamification/HintComponent'
import { pointerButton } from 'src/utils/missionHelper'

const Page = styled.div`
  overflow: hidden;
  color: #505c7c;

  #button-container {
    position: absolute;
    bottom: 4vh;
    left: 2%;
    .hot-buttons {
      width: 125px;
      box-shadow: 0px 5px 4px -4px #000;
    }
  }
  .hot-buttons,
  .hot-actions {
    background: linear-gradient(#846CCC, #5D4591);
    border-radius: 40px;
    border: none;
    padding: 8px 16px;
    color: ${COLORS.hotButtonText};
    margin: 0 8px;
    svg {
      width: 12px;
      height: 26px;
    }
    small {
      margin: 0;
      display: block;
      font-weight: 500;
      font-size: 70%;
    }
  }

  .booth-container {
    /* border: 2px solid red; */
    position: fixed;
    width: 80vw;
    max-width: 1080px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .hot-actions {
      background: linear-gradient(#8468CE, #96F19C);
      border-radius: 50%;
      padding: 0;
      box-shadow: 0px 5px 14px -4px #8CD5B3;
      transition: all 300ms ease;
      
      img,
      svg {
        transition: all 300ms ease;
        width: 28px;
        height: 28px;
      }
      small {
        transition: all 300ms ease;
        font-size: 1px;
        opacity: 0;
        color: #fff;
      }
      &:hover {
        small {
          font-size: 9px;
          opacity: 1;
        }
        img,
        svg {
          width: 23px;
          height: 23px;
        }
      }
    }
  }
  .standard {
    width: 60vw;
    max-width: 1000px;
    top: 73%;
    left: 50%;
    transform: translate(-50%, -73%);
  }
  #tawk-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 40vw;
    height: 40vh;
    z-index: 9999;
  }
  .exhibitor-title {
    text-align: right;
    width: 40vw;
    position: fixed;
    bottom: 3%;
    right: 5%;
    font-weight: 600;
  }

`
const HotButton = styled.button`
  position: absolute;
  animation: heartbeat 7s infinite;
  width: 70px;
  height: 70px;
  ${(props) => props.css}
`

const Pointer = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  ${(props) => props.css}
  pointer-events: none;
  .circle {
    border-radius: 50%;
    border: 2px dashed #8468CE;
    height: 100%;
    width: 100%;
    transform: translate(-40%, -40%);
    opacity: 0;
    animation: appear 1s forwards;
  }
  .finger-pointer {
    position: absolute;
    z-index: 10;
    left: 30%;
    bottom: 60%;
    opacity: 0;
    animation: appear 2s 3s forwards, mousepoint 1s 3s infinite;
    animation-iteration-count: 1;
    width: 50px;
  }
`

export default function BoothPage({ currentUser }) {
  const history = useHistory()
  const [showBtn, setShowBtn] = useState(false)
  const [iframeOpen, setIframeOpen] = useState(false)
  const [posterOpen, setPosterOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [playVideo, setPlayVideo] = useState({})
  const [nameCardModal, setNameCardModal] = useState(false)
  const [documentSelected, setDocumentSelected] = useState(null)
  const [missionName, setMissionName] = useState("")
  const [showMission, setShowMission] = useState(false)

  const dispatch = useDispatch()
  const { viewBooth, booths, boothMissions } = useSelector((state) => state.booth)
  const { boothId } = useParams()
  const [snapshot, loading, error] = useObject(TokenDb)

  useEffect(() => {
    dispatch(getBooth(boothId))
    setShowMission(false)
    setMissionName("")
  }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.hall.id))
      Amplitude.visitBooth(viewBooth.title)
    }
  }, [boothId, currentUser.id, dispatch, viewBooth])

  const handleModal = (video) => {
    Amplitude.watchBoothVideo(video.filename)
    setPlayVideo(video)
    setIframeOpen(true)
    setVideoOpen(false)
  }
  const handleOpenDocument = (file) => {
    Amplitude.viewBoothPdf(file.filename)
    setDocumentSelected(file)
    // setDownloadOpen(!downloadOpen)
  }

  const handleDropNameCard = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'namecard')
    setNameCardModal(!nameCardModal)
  }
  
  const handleDownload = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setDownloadOpen(!downloadOpen)
  }

  const handleVideoModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'video')
    setVideoOpen(!videoOpen)
  }
 
  const handlePoster = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'poster')
    setPosterOpen(!posterOpen)
  }
  const handleInfo = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'info')
    setInfoOpen(!infoOpen)
  }
  const handleShowHint = () => {
    Amplitude.clickHint(viewBooth.title)
    setMissionName(boothMissions[0].display_name)
    setShowMission(true)
  }

  const navigatePrev = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    history.push(`/booths/${navigateTo}`)
  }
  const navigateNext = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    history.push(`/booths/${navigateTo}`)
  }

  if (!viewBooth) {
    return (
      <Loader fullPage loading />
    )
  }
  return (
    <Page>
      <PageWithBg bgImg={fixedAssets.boothBg} style={{ opacity: showBtn ? 1 : 0}}>
        <HintComponent showMission={showMission} handleShowHint={handleShowHint} missionName={missionName} />
        {!showBtn && <Loader loading />}
        {viewBooth && (
          <LazyLoadComponent>
            <div className={ `${viewBooth?.booth_tier === 'standard' ? "standard" : ""} booth-container`} >
              <LazyLoadImage
                effect="black-and-white"
                afterLoad={() => setShowBtn(true)}
                className="booth-img"
                src={viewBooth.background_image.url}
                width="100%"
                alt="booth image"
                style={{ opacity: showBtn ? 1 : 0}}
              />
              {showBtn && (
                <>
                  {!loading && !error && snapshot && snapshot.val() === "on" && boothMissions[0]?.mission_type &&
                    <Pointer css={viewBooth.button_position[pointerButton(boothMissions[0].mission_type)]}>
                      <img className="finger-pointer" src={hintPointer} alt="pointer" />
                      {showMission && <div className="circle"></div>}
                    </Pointer>
                  }
                  {viewBooth.booth_videos.length > 0 && 
                    <HotButton css={viewBooth.button_position.tv} className="hot-actions" onClick={handleVideoModal} >
                      <svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polygon points="10 8 16 12 10 16 10 8"></polygon>
                      </svg>
                      <small>Video<br />Gallery</small>
                    </HotButton>
                  }
                  <HotButton css={viewBooth.button_position.info} className="hot-actions" onClick={handleInfo}>
                    <svg width="37.098" height="37.098" viewBox="0 0 37.098 37.098">
                      <g transform="translate(-10.397 -16.423)">
                        <g transform="translate(10.397 16.423)" fill="none" stroke="#fff" strokeWidth="3">
                          <ellipse cx="18.549" cy="18.549" rx="18.549" ry="18.549" stroke="none"/>
                          <ellipse cx="18.549" cy="18.549" rx="17.049" ry="17.049" fill="none"/>
                        </g>
                        <text transform="translate(28.946 43.472)" fill="#fff" stroke="#fff" strokeWidth="0.5" fontSize="24" fontFamily="Montserrat-Regular, Montserrat"><tspan x="-3.228" y="0">i</tspan></text>
                      </g>
                    </svg>
                    <small>Booth<br />Info</small>
                  </HotButton>
                  {viewBooth.documents.length > 0 && 
                    <HotButton css={viewBooth.button_position.pdf} className="hot-actions" onClick={handleDownload}>
                      <svg width="35.678" height="35.678" viewBox="0 0 35.678 35.678">
                        <path d="M0,0H35.678V35.678H0Z" fill="none"/>
                        <path d="M28.758,2H10.919A2.982,2.982,0,0,0,7.946,4.973V22.812a2.982,2.982,0,0,0,2.973,2.973H28.758a2.982,2.982,0,0,0,2.973-2.973V4.973A2.982,2.982,0,0,0,28.758,2ZM16.123,13.149a2.227,2.227,0,0,1-2.23,2.23H12.406v1.858a1.115,1.115,0,1,1-2.23,0V10.92a1.491,1.491,0,0,1,1.487-1.487h2.23a2.227,2.227,0,0,1,2.23,2.23Zm7.433,2.973a2.227,2.227,0,0,1-2.23,2.23H18.352a.736.736,0,0,1-.743-.743V10.176a.736.736,0,0,1,.743-.743h2.973a2.227,2.227,0,0,1,2.23,2.23ZM29.5,10.548a1.123,1.123,0,0,1-1.115,1.115H27.272v1.487h1.115a1.115,1.115,0,0,1,0,2.23H27.272v1.858a1.115,1.115,0,1,1-2.23,0V10.92a1.491,1.491,0,0,1,1.487-1.487h1.858A1.123,1.123,0,0,1,29.5,10.548Zm-17.1,2.6h1.487V11.663H12.406Zm-8.919-5.2A1.491,1.491,0,0,0,2,9.433V28.759a2.982,2.982,0,0,0,2.973,2.973H24.3a1.487,1.487,0,0,0,0-2.973H6.46a1.491,1.491,0,0,1-1.487-1.487V9.433A1.491,1.491,0,0,0,3.487,7.946Zm16.352,8.176h1.487v-4.46H19.839Z" transform="translate(0.973 0.973)" fill="#fff"/>
                      </svg>
                      <small>PDF<br />Gallery</small>
                    </HotButton>
                  }
                  {viewBooth.wallpaper_gallery.length > 0 &&
                    <HotButton css={viewBooth.button_position.wp} className="hot-actions" onClick={()=>handlePoster("wallpaper")}>
                      <svg width="20" height="20" viewBox="0 0 45.448 45.448">
                        <path d="M0,0H45.448V45.448H0Z" fill="none"/>
                        <path d="M37.086,33.3V6.787A3.8,3.8,0,0,0,33.3,3H6.787A3.8,3.8,0,0,0,3,6.787V33.3a3.8,3.8,0,0,0,3.787,3.787H33.3A3.8,3.8,0,0,0,37.086,33.3ZM14.173,23.793l3.977,4.791,5.87-7.556a.951.951,0,0,1,1.515.019l6.647,8.862a.946.946,0,0,1-.757,1.515H8.719A.943.943,0,0,1,7.98,29.89L12.7,23.83A.934.934,0,0,1,14.173,23.793Z" transform="translate(2.681 2.681)" fill="#fff"/>
                      </svg>
                      <small>Image<br />Gallery</small>
                    </HotButton>
                  }
                  {viewBooth.booth_agents.length > 0 &&
                    <HotButton css={viewBooth.button_position.namecard} className="hot-actions" onClick={handleDropNameCard}>
                      <svg width="44.08" height="29.08" viewBox="0 0 44.08 29.08">
                        <path d="M19.869,41.094l-3.2-.439q-4.743-.65-9.487-1.3c-.87-.12-.869-.131-.869-1.017,0-4.036,0-8.073-.008-12.109a.728.728,0,0,1,.458-.776q8.934-4.483,17.848-9.011a.609.609,0,0,1,.678.029c3.643,2.194,7.3,4.374,10.935,6.573a2.491,2.491,0,0,0,1.367.375c3.079-.017,6.157-.009,9.236-.009a3.322,3.322,0,0,1,3.541,3.455q.034,7.579,0,15.158a3.327,3.327,0,0,1-3.506,3.405q-11.778.005-23.555,0a3.391,3.391,0,0,1-3.437-3.362c-.008-.307,0-.615,0-.974Zm3.393-12.062c.128.012.228.029.329.029,2.343,0,4.685.013,7.028,0a4.392,4.392,0,0,1,2.958.959,4.215,4.215,0,0,1-2.7,7.473c-2.387.006-4.774.008-7.16-.006-.368,0-.529.07-.511.483.034.794,0,1.591.018,2.386a1.716,1.716,0,0,0,1.5,1.7,6.193,6.193,0,0,0,.839.024c6.453,0,12.907-.018,19.359.018a1.938,1.938,0,0,0,2.121-2.1c-.055-3.757-.022-7.514-.021-11.271a1.727,1.727,0,0,0-1.95-1.959q-9.967-.006-19.935,0a1.787,1.787,0,0,0-1.873,2.27Z" transform="translate(-6.305 -16.35)" fill="#fff"/>
                      </svg>
                      <small>Drop<br />Namecard</small>
                    </HotButton>
                  }
                </>
              )}
            </div>
            <div id='button-container'>
              {/* <button className="hot-buttons back-hall" onClick={() => history.push(routes.lobby)}>
                <svg width="19" height="17.041" viewBox="0 0 19 17.041">
                  <path d="M14.25,17.042H4.75a2.378,2.378,0,0,1-2.375-2.375V6.578l-1.118.813a.786.786,0,0,1-.465.152A.792.792,0,0,1,.326,6.11L8.1.455a2.376,2.376,0,0,1,2.794,0L18.673,6.11a.791.791,0,0,1,.175,1.106.8.8,0,0,1-.641.326.783.783,0,0,1-.465-.152l-1.118-.813v8.088A2.378,2.378,0,0,1,14.25,17.042ZM8.708,8.334h1.583a2.377,2.377,0,0,1,2.375,2.374v4.75H14.25a.793.793,0,0,0,.792-.792V5.427L9.966,1.734a.793.793,0,0,0-.932,0L3.958,5.427v9.239a.793.793,0,0,0,.792.792H6.333v-4.75A2.377,2.377,0,0,1,8.708,8.334Zm0,1.583a.792.792,0,0,0-.792.791v4.75h3.167v-4.75a.792.792,0,0,0-.792-.791Z" transform="translate(0 0)" fill="#fff"/>
                </svg>
                <small className="extra-small">Back to Lobby</small>
              </button> */}
              {viewBooth.order !== 0 && (
                <button className="hot-buttons" onClick={navigatePrev}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <g transform="translate(17.301 26.839) rotate(180)">
                      <path d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z" transform="translate(17.301 26.839) rotate(180)" fill="#fff"/>
                    </g>
                  </svg>
                  <small>Previous Booth</small>
                </button>
              )}
              {viewBooth.order !== booths.length-1 && (
                <button className="hot-buttons" onClick={navigateNext}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <path d="M.459,12.376,13.147.432a1.637,1.637,0,0,1,2.216,0l1.48,1.393a1.415,1.415,0,0,1,0,2.084L6.79,13.419,16.845,22.93a1.415,1.415,0,0,1,0,2.084l-1.48,1.393a1.637,1.637,0,0,1-2.216,0L.459,14.462A1.415,1.415,0,0,1,.459,12.376Z" transform="translate(17.301 26.839) rotate(180)" fill="#fff"/>
                  </svg>
                  <small>Next Booth</small>
                </button>
              )}
            </div>
           
            <VideoModal videos={viewBooth.booth_videos} setVideoOpen={setVideoOpen} videoOpen={videoOpen} handleModal={handleModal} />
            
            {iframeOpen && <IframeModal mission={boothMissions[0]} iframeOpen={iframeOpen} setIframeOpen={setIframeOpen} iframe={playVideo} />}
            <PosterModal mission={boothMissions[0]} images={viewBooth.wallpaper_gallery} posterOpen={posterOpen} setPosterOpen={setPosterOpen} />
            <InfoModal mission={boothMissions[0]} booth={viewBooth} infoOpen={infoOpen} setInfoOpen={setInfoOpen} />
            <DownloadModal mission={boothMissions[0]} setDocumentSelected={handleOpenDocument} files={viewBooth.documents} setDownloadOpen={setDownloadOpen} downloadOpen={downloadOpen} />
            
            <BoothNamecardModal currentUser={currentUser} hideModal={()=>setNameCardModal(false)} modal={nameCardModal} booth={viewBooth}  />
            {documentSelected && <DocumentViewer file={documentSelected} setDocumentSelected={setDocumentSelected} />}
          </LazyLoadComponent>
        )}
      </PageWithBg>
    </Page>
  )
}
