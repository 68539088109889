/**
 * Mute background audio when a component with this custom hook is loaded
 * Return the mute state to user previously set state when componted is unmounted
 */

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { muteMusic, unmuteMusic } from 'src/ducks/layout'

export const useAutoMute = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])
}
