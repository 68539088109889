import amplitude from 'amplitude-js'
import config from '../config'

const Amplitude = {
  init: () => {
    amplitude.getInstance().init(config.API_KEYS.amplitude)
  },

  // TODO: Put all amplitude tracking functions here
  visit: (page) => {
    amplitude.getInstance().logEvent(`Visit ${page}`)
  },

  visitExhibition: (hallNumber) => {
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
      hallNumber: hallNumber,
    })
  },

  visitBooth: (boothName) => {
    amplitude.getInstance().logEvent(`Visit Booth`, {
      boothName: boothName,
    })
  },

  downloadGallery: (item) => {
    amplitude.getInstance().logEvent('Download Gallery', {
      title: item.title,
      type: item.itemType,
    })
  },
  clickExhibition: () => {
    amplitude.getInstance().logEvent('Click Exhibition Entry')
  },
  watchBoothVideo: (filename) => {
    amplitude.getInstance().logEvent(`Watch ${filename}`)
  },

  viewBoothPdf: (filename) => {
    amplitude.getInstance().logEvent(`View Pdf: ${filename}`)
  },

  clickDownloadPdf: (filename) => {
    amplitude.getInstance().logEvent(`Download Pdf: ${filename}`)
  },

  viewAbstractPresentation: (abstract, type) => {
    amplitude.getInstance().logEvent(`View Abstract Presentation`, {
      abstractId: abstract.id,
      abstractTitle: abstract.title,
      abstractType: type,
    })
  },
  
  viewGalleryVideo: (title, category) => {
    amplitude.getInstance().logEvent(`View Gallery Video`, {
      title,
      category,
    })
  },

  click: (event) => {
    amplitude.getInstance().logEvent(`Click ${event}`)
  },
  
  clickQuizKiosk: () => {
    amplitude.getInstance().logEvent('Click Booth Quiz')
  },
  submitQuiz: () => {
    amplitude.getInstance().logEvent('Quiz Submitted')
  },

  showQR: () => {
    amplitude.getInstance().logEvent('Show QR')
  },

  clickHelpDesk: () => {
    amplitude.getInstance().logEvent('Click Helpdesk')
  },

  clickMute: () => {
    amplitude.getInstance().logEvent('Click Mute button');
  },

  clickLogout: () => {
    amplitude.getInstance().logEvent('Click Logout');
  },

  clickMenu: () => {
    amplitude.getInstance().logEvent('Click Menu')
  },

  watchPlayback: (id) => {
    amplitude.getInstance().logEvent('Watch Playback', {
      sessionId: id
    })
  },

  clickNotification: () => {
    amplitude.getInstance().logEvent('Click Notifications')
  },

  clickAgenda: () => {
    amplitude.getInstance().logEvent('Click Agenda Modal')
  },

  clickSpeakers: () => {
    amplitude.getInstance().logEvent('Click Speakers Modal')
  },

  clickProgrammeButton: () => {
    amplitude.getInstance().logEvent('Click Programme List')
  },

  clickFaculty: () => {
    amplitude.getInstance().logEvent('Click Faculty Button')
  },

  clickFacultyModal: (id) => {
    amplitude.getInstance().logEvent('Click Faculty Modal', {
      speakerId: id
    })
  },

  clickHotButtons: (boothName, type) => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: boothName,
      hotButtonType: type,
    })
  },

  clickHint: (boothName) => {
    amplitude.getInstance().logEvent('Click Booth Hint', {
      boothName: boothName,
    })
  },

  collectCoin: (missionId) => {
    amplitude.getInstance().logEvent('Collect Coin', {
      missionId: missionId,
    })
  },

  enterDraw: () => {
    amplitude.getInstance().logEvent('Click Enter Draw')
  },

  dropNamecard: (details) => {
    amplitude.getInstance().logEvent('Drop name card', {
      SenderID: details.senderId,
      SenderFullName: details.senderFullName,
      SenderEmail: details.senderEmail,
      ReceiverFullName: details.receiverFullName,
      ReceiverEmail: details.receiverEmail,
    });
  }
}

export default Amplitude
