import { fixedAssets } from 'src/containers/PreCache'
import styled from 'styled-components'

export const PageStyle = styled.div`
  background-image: url('${fixedAssets.galleryBg}');
  background-size: cover;
  padding-top: 30px; // 30px notice bar
  height: 100vh;
  /* overflow: scroll; */

  // layout -> content -> grid-container OR infinite-scroll-container
  .page-layout {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(280px, 25%) 1fr;
  }

  .page-content {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.75);
    display: grid;
    grid-template-rows: 80px 1fr; // 80px header
    /* padding-right: 65px; // right navbar */
    overflow: scroll;

    .grid-container {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(auto-fill, 200px);
      margin: 20px 40px;
      grid-auto-rows: max-content;
    }
    .soon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #569099;
      font-weight: 800;
    }

    // header
    .back-btn {
      background: #9699A2;
      font-weight: bold;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 20px 30px;
      .back-icon {
        cursor: pointer;
        display: flex;
        width: 30px;
        flex-direction: column;
        img,
        svg {
          transition: 100ms linear;
          height: 24px;
        }
        .icon-label {
          transition: 100ms linear;
          text-align: left;
          font-size: 1px;
          opacity: 0;
        }
        &:hover {
          img,
          svg {
            transform: scale(0.5);
          }
          .icon-label {
            font-size: 8px;
            opacity: 1;
          }
        }
      }
      span {
        font-size: 22px;
        margin-left: 1em;
        color: #525252;
      }
    }
  }

  .page-sidebar {
    color: white;
    font-size: 20px;
    padding: 0;
    position: relative;
    background-image: url('${fixedAssets.galleryBanner}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: calc(100vh - 30px);
   
    .visual {
      position: sticky;
      top: 40%;
    }
    .page-title {
      padding: 18px 30px;
      background: #F5F5F5;
      width: 100%;
      
      h2 {
        text-align: center;
        font-weight: 800;
        font-size: 38px;
        margin: 0;
        text-transform: uppercase;
        color: #7C2AFF;
      }
    }
  }
  .card-thumbnail {
    display: block;
    cursor: pointer;
    position: relative;
    text-align: center;
    color: #525252;
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    img {
      transition: 150ms ease-in;
    }
    span {
      display: block;
      margin-top: 8px;
      font-size: 14px;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
`
