import config from '../config'

const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
const GET_AREA_OF_INTERESTS = 'GET_AREA_OF_INTERESTS'
const APPEND_POSTERS = 'APPEND_POSTERS'
const APPEND_ORALS = 'APPEND_ORALS'
const SET_PREVIOUS_PAGE = 'SET_PREVIOUS_PAGE'

export interface PosterType {
  [key: string]: any
}

export interface onDemandState {
  pageTitle: string

  areaOfInterests: Array<string>
  areaOfInterestsFetched: boolean

  posters: {
    [key: string]: {
      abstracts: Array<any>
      currentPage: number
      totalPages: number
      fetchedInitial: boolean
    }
  }

  orals: {
    [key: string]: {
      abstracts: Array<any>
      currentPage: number
      totalPages: number
      fetchedInitial: boolean
    }
  }

  previousPagePath?: string
}

export const initialAbstractState = {
  abstracts: [],
  currentPage: 1,
  totalPages: 9999, // we assume there are more pages until API tells us otherwise
  fetchedInitial: false,
}

const initialState: onDemandState = {
  pageTitle: '',

  areaOfInterests: [],
  areaOfInterestsFetched: false,

  posters: {},
  orals: {},
}

export default function layoutReducer(state = initialState, action): onDemandState {
  switch (action.type) {
    case SET_PAGE_TITLE:
      document.title = `${config.eventName} | ${action.pageTitle ?? `| ${action.pageTitle}`}`
      return {
        ...state,
        pageTitle: action.pageTitle,
      }

    case GET_AREA_OF_INTERESTS:
      return {
        ...state,
        areaOfInterests: action.areaOfInterests,
        areaOfInterestsFetched: true,
      }

    case APPEND_POSTERS:
      const currentAreaPosters = state.posters[action.area] ?? initialAbstractState
      return {
        ...state,
        posters: {
          ...state.posters,
          [action.area]: {
            abstracts: [...currentAreaPosters.abstracts, ...action.posters.abstracts],
            currentPage: currentAreaPosters.currentPage + 1,
            totalPages: action.posters.totalPages,
            fetchedInitial: true,
          },
        },
      }

    case APPEND_ORALS:
      const currentAreaOrals = state.orals[action.area] ?? initialAbstractState
      return {
        ...state,
        orals: {
          ...state.orals,
          [action.area]: {
            abstracts: [...currentAreaOrals.abstracts, ...action.orals.abstracts],
            currentPage: currentAreaOrals.currentPage + 1,
            totalPages: action.orals.totalPages,
            fetchedInitial: true,
          },
        },
      }

    case SET_PREVIOUS_PAGE:
      return {
        ...state,
        previousPagePath: action.previousPagePath,
      }

    default:
      return state
  }
}

export function setPageTitle(pageTitle) {
  return { type: SET_PAGE_TITLE, pageTitle }
}

export function getAreaOfInterests(areaOfInterests) {
  return { type: GET_AREA_OF_INTERESTS, areaOfInterests }
}

export function appendPosters(area, posters) {
  return { type: APPEND_POSTERS, posters, area }
}

export function appendOrals(area, orals) {
  return { type: APPEND_ORALS, orals, area }
}

export function setPreviousPage(previousPagePath) {
  return { type: SET_PREVIOUS_PAGE, previousPagePath }
}
