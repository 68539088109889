import axios from 'axios'
import { toast } from 'react-toastify'
import config from 'src/config'
import { refreshUser } from './auth'
import { updateBoothMission } from './booth'

const SEND_REQUEST = 'SEND_REQUEST'
const RECEIVE_MISSIONS = 'RECEIVE_MISSIONS'
const RECEIVE_WINNERS = 'RECEIVE_WINNERS'
const SEND_TASK = 'SEND_TASK'
const SEND_ENTRY = 'SEND_ENTRY'
const UPDATE_STATUS = 'UPDATE_STATUS'
const SUBMIT_MISSION = 'SUBMIT_MISSION'

const initialState = {
  isFetching: false,
  drawEntered: null,
  missions: [],
  winners: {},
  totalScore: 0,
  eligible: null,
  collected: false,
  isMissionLoading: false,
}


export default function missionReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case RECEIVE_MISSIONS:
      return {
        ...state,
        isFetching: false,
        drawEntered: action.entered,
        totalScore: action.totalScore,
        missions: action.missions
      }
    case RECEIVE_WINNERS:
      return {
        ...state,
        winners: action.winners
      }
    case SUBMIT_MISSION:
      return {
        ...state,
        isMissionLoading: true,
      }
    case SEND_TASK:
      return {
        ...state,
        totalScore: action.totalScore,
        collected: action.collected,
        isMissionLoading: false,
      }
    case UPDATE_STATUS:
      return {
        ...state,
        collected: action.collected
      }
    case SEND_ENTRY:
      return {
        ...state,
        drawEntered: action.entered,
        eligible: action.eligible,
      }
    default:
      return state
  }
}

export function getMissions() {
  return (dispatch) => {
    dispatch(sendRequest())  
    
    return axios
      .get(`${config.apiUrl}/api/missions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveMissions(data.missions, data.total_score, data.draw_entered))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function completeTask(ms) {
  return (dispatch) => {
    dispatch(submittingMission())

    return axios
      .post(`${config.apiUrl}/api/user_missions`, ms, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          toast("Token collected!")
          dispatch(recordTask(data.total_points, data.success))
          if (ms.mission_name) {
            refreshUser(dispatch)
          } 
          if (ms.booth_id) {
            dispatch(updateBoothMission(ms.mission_id))
          } 
        } else {
          toast(data.error)
          dispatch(recordTask(data.total_points, false))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function enterDraw(user_id) {
  return (dispatch) => {
    return axios
      .post(`${config.apiUrl}/api/lucky_draw_entries/${user_id}/enter`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(luckyDrawEntry(data.draw_entered, data.eligible))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getWinners() {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/lucky_draw_entries/results`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ data }) => {
        dispatch(receiveWinners(data))
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

function sendRequest() {
  return {
    type: SEND_REQUEST,
  }
}

function receiveMissions(missions, totalScore, entered) {
  return {
    type: RECEIVE_MISSIONS,
    entered,
    totalScore,
    missions,
  }
}
export function updateCollected(collected) {
  return {
    type: UPDATE_STATUS,
    collected,
  }
}
function recordTask(totalScore, collected) {
  console.log("collected?",collected)
  return {
    type: SEND_TASK,
    totalScore,
    collected,
  }
}
function luckyDrawEntry(entered, eligible) {
  return {
    type: SEND_ENTRY,
    entered,
    eligible,
  }
}
function receiveWinners(winners) {
  return {
    type: RECEIVE_WINNERS,
    winners,
  }
}
function submittingMission() {
  return {
    type: SUBMIT_MISSION,
  }
}
