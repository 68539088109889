import React, { useState, useEffect } from 'react'
import { SIZES } from 'src/styles/theme'
import styled from 'styled-components'
import { firebaseDb } from '../firebaseConfig'

const Page = styled.div`
  background: #2e2e2e;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 50px;

  #board {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 2px;
    border: none;
    border-top: 5px solid #FF9F2F;
    padding: 16px 32px;
    width: 40%;
    color: #004c6d;
    h5 {
      margin: 8px auto;
      font-weight: 700;
    }
    span {
      background-color: #FF6026;
      color: white;
      border-radius: 4px;
      padding: 2px 5px;
    }
  }
  ${SIZES.mobile} {
    padding: 35px;
    #board {
      width: 100%;
    }
  }
`

export default function ActiveUserCountPage() {
  const countDbRef = `${
    process.env.NODE_ENV === 'production' ? 'production' : 'local'
  }/userCount`

  const statusDbRef = `${
    process.env.NODE_ENV === 'production' ? 'production' : 'local'
  }/onlineStatus`

  const [liveCountData, setLiveCountData] = useState([])
  const [pageData, setPageData] = useState([])
  const hidePages = ['realtime']
  
  useEffect(() => {
    const ref = firebaseDb.ref(statusDbRef)
    ref.on(
      'value',
      function (snapshot) {
        const data = snapshot.val() || {}
        const activeUsers = Object.keys(data).filter((key) => data[key] === true)
        setLiveCountData(activeUsers)
        console.log(activeUsers.length)
      },
      function (errorObject) {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [statusDbRef])

  useEffect(() => {
    const ref = firebaseDb.ref(countDbRef)
    ref.on(
      'value',
      function (snapshot) {
        const data = snapshot.val() || {}

        setPageData(
          Object.keys(data).map((key) => ({
            pageName: key,
            pageCount: Object.keys(data[key]).length,
          }))
        )
      },
      function (errorObject) {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [countDbRef])

  return (
    <Page>
      <div id="board">
        <h5 className="text-secondary">Active Users In Hall</h5>
        {pageData.map(({ pageName, pageCount }) => (
          ( !hidePages.includes(pageName) ) && (
            <h6 key={pageName}>
              {pageName} : <span>{pageCount}</span>
            </h6>
          )
        ))}
      </div>
    </Page>
  )
}
