const TOGGLE_MUTE = 'TOGGLE_MUTE'
const MUTE_MUSIC = 'MUTE_MUSIC'
const UNMUTE_MUSIC = 'UNMUTE_MUSIC'
const SHOW_GLOBAL_MODAL = 'SHOW_GLOBAL_MODAL'
const HIDE_GLOBAL_MODAL = 'HIDE_GLOBAL_MODAL'
const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
const TOGGLE_HELPDESK = 'TOGGLE_HELPDESK'

const defaultMuted = () => {
  if (localStorage.getItem('muted') === null) {
    localStorage.setItem('muted', false)
    return false
  }
  return localStorage.getItem('muted') === 'true'
}

const initialState = {
  sideOpen: false,
  menuTab: "agenda",
  helpOpen: false,
  muted: defaultMuted(),
  globalModal: null,
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MUTE:
      localStorage.setItem('muted', !state.muted)
      return {
        ...state,
        muted: !state.muted,
      }
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sideOpen: true,
        menuTab: action.tab
      }
    case CLOSE_SIDEBAR:
      return {
        ...state,
        sideOpen: false
      }
    case TOGGLE_HELPDESK:
      return {
        ...state,
        helpOpen: !state.helpOpen
      }
    case MUTE_MUSIC:
      return {
        ...state,
        muted: true,
      }

    case UNMUTE_MUSIC:
      return {
        ...state,
        muted: false,
      }

    case SHOW_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: action.globalModal,
      }

    case HIDE_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: null,
      }

    default:
      return state
  }
}

export function toggleMute() {
  return { type: TOGGLE_MUTE }
}
export function closeSideBar() {
  return { type: CLOSE_SIDEBAR }
}

export function toggleSidebar(tab) {
  return {
    type: TOGGLE_SIDEBAR,
    tab: tab
  }
}

export function toggleHelpdesk() {
  return { type: TOGGLE_HELPDESK }
}

export function muteMusic() {
  return { type: MUTE_MUSIC }
}

export function unmuteMusic() {
  return { type: UNMUTE_MUSIC }
}

export function showGlobalModal(globalModal) {
  return { type: SHOW_GLOBAL_MODAL, globalModal: globalModal }
}

export function hideGlobalModal() {
  return { type: HIDE_GLOBAL_MODAL }
}
