import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { completeTask } from '../../ducks/mission'
import collectedCoin from '../../images/gamification/coin-collected.png'
import coin from '../../images/gamification/coin-to-collect.png'
import { SIZES } from 'src/styles/theme'
import Amplitude from 'src/utils/amplitudeHelper'
import { TokenDb } from 'src/firebaseConfig'
import { useObject } from 'react-firebase-hooks/database'
import { isEmpty } from 'lodash'

const Wrapper = styled.div`
  position: absolute;
  opacity: 0;
  animation: appear 1s 2s forwards;
  z-index: 1000;
  cursor: pointer;
  ${(props) => props.css}
  #coin {
    animation: bounce 3s infinite;
  }
  ${SIZES.mobile} {
    opacity: 1;
    ${(props) => props.isMobile && `
      position: static;
      img {
        margin-top: -20px;
        width: 30%;
      }
      .collected {
        margin: auto;
        width: 40%;
      }
    `}
   
  }
`

function CoinComponent ({ mission, hide=false, isMobile=false }) {
  const randomCoinPosition = [
    `top: 25%; left:-15%;`,
    `top: 30%; left:-18%;`,
    `top: 35%; right:-15%;`,
    `top: 45%; left:-20%;`,
    `top: 45%; right:-20%;`,
    `top: 33%; right:-20%;`,
  ]
  const visitPosition = [
    `top: 18%; left: 20%;`,
    `top: 25%; left: 15%;`,
    `top: 43%; left: 5%;`,
    `top: 75%; left: 18%;`,
    `top: 17%; left: 55%;`,
    `top: 25%; left: 30%;`,
  ]

  const dispatch = useDispatch()
  const [positionIndex, setPositionIndex] = useState(Math.floor(Math.random() * randomCoinPosition.length))
  const { isMissionLoading } = useSelector((state) => state.mission)
  const { boothMissions } = useSelector((state) => state.booth)
  const [completed, setCompleted] = useState(mission?.completed)
  const [snapshot, loading, error] = useObject(TokenDb)

  useEffect(() => {
    setCompleted(mission?.completed)
  }, [mission, boothMissions])

  if (!loading && !error && snapshot && snapshot.val() === "on" && !isEmpty(mission)) {
    const collectCoin = (mission) => {
      if (!isMissionLoading) {
        Amplitude.collectCoin(mission.id)
        dispatch(completeTask({
          mission_id: mission.id,
          mission_name: mission.task_name,
          booth_id: mission.booth_id,
        }))
      }
    }
  
    if (isMissionLoading) {
      return <></>
    }
  
    return (
      <Wrapper css={mission.booth_id ? randomCoinPosition[positionIndex] : visitPosition[positionIndex]} isMobile={isMobile} >
        {
          completed ?
          <img src={collectedCoin} className={`${hide ? `d-none` : `d-block`} collected`} width="95px" alt="coin" /> :
          <img id="coin" onClick={()=>collectCoin(mission)} src={coin} width="95px" alt="coin" />
        }
      </Wrapper>
    )
  }
  else {
    return null
  }
}
export { CoinComponent }