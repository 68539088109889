import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from 'src/components/shared/Loader'
import { cacheWebImages } from '../utils/cacheHelper'
import entranceBg from '../images/backdrops/entrance.jpg'
import lobby from '../images/backdrops/lobby.jpg'
import lobbyM from '../images/backdrops/lobbyM.jpg'
import lobbyBanner from '../images/backdrops/lobby-banner.png'
import auditorium from '../images/backdrops/auditorium.jpg'
import galleryBg from '../images/backdrops/gallery.jpg'
import galleryBanner from '../images/backdrops/gallery_banner.jpg'
import masterhead from '../images/backdrops/masterhead.png'
import auditoriumM from '../images/backdrops/auditoriumM.jpg'
import auditoriumBanner from '../images/backdrops/auditorium-banner.png'
import networking from '../images/backdrops/networking.jpg'
import networkingM from '../images/backdrops/networkingM.jpg'
import boothBg from '../images/backdrops/boothBg.jpg'
import headerBanner from '../images/backdrops/mobile-banner.jpg'
import speaker1 from '../images/backdrops/Speakers-Day-1.jpg'
import speaker2 from '../images/backdrops/Speakers-Day-2.jpg'
import agenda1 from '../images/backdrops/Agenda-Day-01.jpg'
import agenda2 from '../images/backdrops/Agenda-Day-02.jpg'
import entranceIcon from '../images/backdrops/entranceIcon.png'
import gameStand from '../images/backdrops/gameStand.png'


export const fixedAssets = {
  entranceBg,
  lobby,
  lobbyM,
  lobbyBanner,
  auditorium,
  auditoriumM,
  auditoriumBanner,
  networking,
  networkingM,
  headerBanner,
  boothBg, 
  speaker1,
  speaker2,
  agenda1,
  agenda2,
  entranceIcon,
  gameStand,
  galleryBg,
  galleryBanner,
  masterhead
}

export const PreCache = ({ deviceType, children }) => {
  const [pageLoading, setPageLoading] = useState(false)

  const preloadAssets = useCallback(async () => {
    setPageLoading(true)
    await cacheWebImages(Object.values(fixedAssets))
    setPageLoading(false)
  }, [])

  useEffect(() => {
    preloadAssets()
  }, [deviceType, preloadAssets])

  return (
    <Loader loading={pageLoading} fullPage>
      <>{children}</>
    </Loader>
  )
}
