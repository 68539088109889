import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import GlobalModalContainer from '../components/GlobalModals/GlobalModalContainer'

import NoticeBar from '../components/NoticeBar'
import { Loader } from '../components/shared/Loader'
import { refreshUser } from '../ducks/auth'
import routes from '../routes'
import AuthContainer from './AuthContainer'
import MobileNav from 'src/components/MobileNav'
import TopNav from 'src/components/TopNav'

export default function PrivateRoute(props) {
  const history = useHistory()
  const { fixedNav = false, mobileNav = false, blockedRoles, component: Component, navOptions, navColor, ...rest } = props
  const dispatch = useDispatch()

  const { currentUser, appReady, isAuthenticated } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isAuthenticated) {
      refreshUser(dispatch)
    } else {
      history.push(routes.login)
    }
  }, [dispatch, history, isAuthenticated])

  if (!appReady) return <Loader />

  if (blockedRoles && blockedRoles.length) {
    if (blockedRoles.includes(currentUser?.role)) {
      toast('You have no access to this room.', {
        toastId: 'accessToast',
      })
      return <Redirect to={{ pathname: '/home' }} />
    }
  }
  return (
    <Route
      exact
      {...rest}
      render={(props) => (
        <AuthContainer currentUser={currentUser}>
          <GlobalModalContainer />
          {fixedNav && appReady && <NoticeBar />}
          <Component currentUser={currentUser} {...props} />
          {mobileNav && <MobileNav />}
          {fixedNav && <TopNav fixedNav={fixedNav} currentUser={currentUser} />}
        </AuthContainer>
      )}
    />
  )
}
