// Clients will provide vimeo link.
// If vimeo link is https://vimeo.com/557230853/dcf7836b65
// then the vimeoId is 557230853, not the dcf..

import videoImage from '../../images/gallerium/onDemand/video-thumbnail.png'
import pdfImage from '../../images/gallerium/onDemand/pdf-thumbnail.png'

export interface onDemandVideosType {
  id?: number
  itemType?: string
  embedUrl: string
  title: string
  desc?: string
  thumbnail?: string
  /**
   * can be react routes, ie. /booths/1, /lobby
   * or external link, ie. https://something.com, mailto:hello@email.com
   */
  buttonUrl?: string
  buttonText?: string
}


const speakerSlides: Array<onDemandVideosType> = [
  {
    id: 1,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/1.+Prof.+Tan+Sri+Dr+Jemilah+-+Planetary+Boundaries+Living+within+Limits.pdf',
    title: 'Prof. Tan Sri Dr Jemilah - Planetary Boundaries Living within Limits',
    thumbnail: pdfImage,
  },
  {
    id: 2,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/2.+Prof+Ian+Goldin+-+The+Future+of+Social+Protection.pdf',
    title: 'Prof Ian Goldin - The Future of Social Protection',
    thumbnail: pdfImage,
  },
  {
    id: 3,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/3.+Dr+Stephen+Kidd+-+The+Common+Good+Society.pdf',
    title: 'Dr Stephen Kidd - The Common Good Society',
    thumbnail: pdfImage,
  },
  {
    id: 4,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/4.+Dr+Amjad+-+Demographic+Transition+and+Social+Change.pdf',
    title: 'Dr Amjad - Demographic Transition and Social Change',
    thumbnail: pdfImage,
  },
  {
    id: 5,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/5.+Prof.+Jomo+-+Improving+Social+Protection+and+Policy+in+Developing+Countries.pdf',
    title: 'Prof. Jomo - Improving Social Protection and Policy in Developing Countries',
    thumbnail: pdfImage,
  },
  {
    id: 6,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/6.+Tai+Fung+Wei+Tan+-+Exploring+The+Informal+Sector+Casualisation+of+Work.pdf',
    title: 'Tai Fung Wei Tan - Exploring The Informal Sector Casualisation of Work',
    thumbnail: pdfImage,
  },
  {
    id: 7,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/7.+William+Price+-+Social+Insurance+System+A+Cross-Country+Comparison.pdf',
    title: 'William Price - Social Insurance System A Cross-Country Comparison',
    thumbnail: pdfImage,
  },
  {
    id: 8,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/8.+Dr.Helmy+-+People+Centric+Policies.pdf',
    title: 'Dr.Helmy - People Centric Policies',
    thumbnail: pdfImage,
  },
  {
    id: 9,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/9.+Prof+Laurence+-+Social+Security+Rights+For+All.pdf',
    title: 'Prof Laurence - Social Security Rights For All',
    thumbnail: pdfImage,
  },
  {
    id: 10,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/10.+Dr+Juita+-+Innovative+Solutions+in+Public+Spending.pdf',
    title: 'Dr Juita - Innovative Solutions in Public Spending',
    thumbnail: pdfImage,
  },
  {
    id: 11,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/11.+Khailee+Ng+-+Social+Change+_+Nation+Building+through+Technology%2C+Venture+Capital%2C+and+Business.pdf',
    title: 'Khailee Ng - Social Change _ Nation Building through Technology, Venture Capital, and Business',
    thumbnail: pdfImage,
  },
  {
    id: 12,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/12.+Alvin+Chen+-+Social+Enterprises+In+Action+Showcasing+Impactful+Solutions+to+Local+and+Global+Challenges.pdf',
    title: 'Alvin Chen - Social Enterprises In Action Showcasing Impactful Solutions to Local and Global Challenges',
    thumbnail: pdfImage,
  },
  {
    id: 13,
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Speakers+Slides/13.+Dr+Nick+Boden+-+Social+Enterprises+In+Action+Showcasing+Impactful+Solutions+to+Local+and+Global+Challenges.pdf',
    title: 'Dr Nick Boden - Social Enterprises In Action Showcasing Impactful Solutions to Local and Global Challenges',
    thumbnail: pdfImage,
  },
]

const sketchpost: Array<onDemandVideosType> = [
  {
    id: 1,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/1.+Day+1+Opening+Remarks.png',
    title: "Day 1 Opening Remarks",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/1.+Day+1+Opening+Remarks.png',
  },
  {
    id: 2,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/2.+The+Path+To+Prosperity+In+A+Post+Global+World.png',
    title: "The Path To Prosperity In A Post Global World",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/2.+The+Path+To+Prosperity+In+A+Post+Global+World.png',
  },
  {
    id: 3,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/3.+Session+2+Plenatary+-+Boundaries+Living+Within+Limits.png',
    title: "Session 2 Plenatary - Boundaries Living Within Limits",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/3.+Session+2+Plenatary+-+Boundaries+Living+Within+Limits.png',
  },
  {
    id: 4,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/4.The+Future+of+Social+Protection.png',
    title: "The Future of Social Protection",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/4.The+Future+of+Social+Protection.png',
  },
  {
    id: 5,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/5.+The+Common+Good+Society.png',
    title: "The Common Good Society",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/5.+The+Common+Good+Society.png',
  },
  {
    id: 6,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/6.+ISWC+Panel+Session+1+-+A+Better+Life+Are+We+Speaking+The+Same+Language.png',
    title: "ISWC Panel Session 1 - A Better Life Are We Speaking The Same Language",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/6.+ISWC+Panel+Session+1+-+A+Better+Life+Are+We+Speaking+The+Same+Language.png',
  },
  {
    id: 7,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/7.+Demographic+Transition+And+Social+Change.png',
    title: "Demographic Transition And Social Change",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/7.+Demographic+Transition+And+Social+Change.png',
  },
  {
    id: 8,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/8.+Improving+Social+Protection+And+Policy+In+Developing+Countries.png',
    title: "Improving Social Protection And Policy In Developing Countries",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/8.+Improving+Social+Protection+And+Policy+In+Developing+Countries.png',
  },
  {
    id: 9,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/9.+ISWC+Exploring+The+Informal+Sector+Casualisation+Of+Work.png',
    title: "ISWC Exploring The Informal Sector Casualisation Of Work",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/9.+ISWC+Exploring+The+Informal+Sector+Casualisation+Of+Work.png',
  },
  {
    id: 10,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/10.+Panel+Session+2+-+The+Changing+World+of+Work.png',
    title: "Panel Session 2 - The Changing World of Work",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/10.+Panel+Session+2+-+The+Changing+World+of+Work.png',
  },
  {
    id: 11,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/11.+Day+1+Closing+Remarks.png',
    title: "Day 1 Closing Remarks",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/11.+Day+1+Closing+Remarks.png',
  },
  {
    id: 12,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/12.+Day+2+Welcoming+Remarks.png',
    title: "Day 2 Welcoming Remarks",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/12.+Day+2+Welcoming+Remarks.png',
  },
  {
    id: 13,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/13.+Keynote+Address+-+Launching+of+KWSP+i-Akaun+App.png',
    title: "Keynote Address - Launching of KWSP i-Akaun App",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/13.+Keynote+Address+-+Launching+of+KWSP+i-Akaun+App.png',
  },
  {
    id: 14,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/14.+ISWC+Session+1+-+The+Path+To+Prosperity+In+A+Post+Global+World.png',
    title: "ISWC Session 1 - The Path To Prosperity In A Post Global World",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/14.+ISWC+Session+1+-+The+Path+To+Prosperity+In+A+Post+Global+World.png',
  },
  {
    id: 15,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/15.+People+Centric+Policies.png',
    title: "People Centric Policies",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/15.+People+Centric+Policies.png',
  },
  {
    id: 16,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/16.+Panel+Session+3+-+Putting+Social+Protection+At+The+Forefront.png',
    title: "Panel Session 3 - Putting Social Protection At The Forefront",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/16.+Panel+Session+3+-+Putting+Social+Protection+At+The+Forefront.png',
  },
  {
    id: 17,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/17.+Social+Security+Rights+Of+All.png',
    title: "Social Security Rights Of All",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/17.+Social+Security+Rights+Of+All.png',
  },
  {
    id: 18,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/18.+Innovative+Solutions+in+Public+Spending.png',
    title: "Innovative Solutions in Public Spending",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/18.+Innovative+Solutions+in+Public+Spending.png',
  },
  {
    id: 19,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/19.+Social+Change+Nation+Building+Through+Technology%2C+Venture+Capital+and+Business.png',
    title: "Social Change Nation Building Through Technology, Venture Capital and Business",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/19.+Social+Change+Nation+Building+Through+Technology%2C+Venture+Capital+and+Business.png',
  },
  {
    id: 20,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/20.+Solidarity+In+The+Pursuit+Of+Wellbeing.png',
    title: "Solidarity In The Pursuit Of Wellbeing",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/20.+Solidarity+In+The+Pursuit+Of+Wellbeing.png',
  },
  {
    id: 21,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/21.+Social+Enterprises+in+Action.png',
    title: "Social Enterprises in Action",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/21.+Social+Enterprises+in+Action.png',
  },
  {
    id: 22,
    itemType: 'poster',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/22.+ISWC+Closing+Remarks.png',
    title: "ISWC Closing Remarks",
    thumbnail: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/Sketchpost/22.+ISWC+Closing+Remarks.png',
  },
]
const recordings: Array<onDemandVideosType> = [
  // {
  //   id: 1,
  //   itemType: 'video',
  //   embedUrl: 'https://player.vimeo.com/video/844457296?h=2fbb4b19b9',
  //   title: 'Day 1 Welcoming Remarks',
  //   desc: 'Datuk Seri Amir Hamzah',
  //   thumbnail: videoImage,
  // },
  // {
  //   id: 2,
  //   itemType: 'video',
  //   embedUrl: 'https://player.vimeo.com/video/844457701?h=58d39916cd',
  //   title: '(Session 1) The Path to Prosperity in a Post-Global World',
  //   desc: 'Rana Foroohar',
  //   thumbnail: videoImage,
  // },
  {
    id: 3,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844458516?h=633564aab4',
    title: '(Session 2) Planetary Boundaries: Living Within Limits',
    desc: 'Tan Sri Dr Jemilah Mahmood',
    thumbnail: videoImage,
  },
  {
    id: 4,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844459357?h=77a36de24a',
    title: '(Session 3) The Future of Social Protection',
    desc: 'Professor Ian Goldin',
    thumbnail: videoImage,
  },
  {
    id: 5,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844460165?h=0943221fbf',
    title: '(Session 4) The Common Good Society',
    desc: 'Dr. Stephen Kidd',
    thumbnail: videoImage,
  },
  {
    id: 6,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844461106?h=b7fb978785',
    title: '(Panel Session 1) A Better Life: Are We Speaking the Same Language?',
    desc: 'Nurhisham Hussein (Moderator)',
    thumbnail: videoImage,
  },
  {
    id: 7,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844461632?h=e296a13044',
    title: '(Session 5) Demographic Transition and Social Change',
    desc: 'Dr. Amjad Rabi',
    thumbnail: videoImage,
  },
  {
    id: 8,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844462214?h=98fd663c37',
    title: '(Session 6) Improving Social Protection and Policy in Developing Countries',
    desc: 'Professor Jomo Kwame Sundaram',
    thumbnail: videoImage,
  },
  {
    id: 9,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844463031?h=f15ff4abd9',
    title: '(Session 7) Exploring the Informal Sector: Casualisation of Work',
    desc: 'Tai Fung Wei Tan',
    thumbnail: videoImage,
  },
  {
    id: 10,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844463473?h=2efaa6a483',
    title: '(Panel Session 2) The Changing World of Work: Taking Our Next Steps',
    desc: 'Nurul Izzah Anwar (Moderator)',
    thumbnail: videoImage,
  },
  // {
  //   id: 11,
  //   itemType: 'video',
  //   embedUrl: 'https://player.vimeo.com/video/844464297?h=c44e86aeea',
  //   title: 'Day 1 Closing Remarks',
  //   desc: 'Dato’ Sri Dr Mohammed Azman',
  //   thumbnail: videoImage,
  // },
  // {
  //   id: 12,
  //   itemType: 'video',
  //   embedUrl: 'https://player.vimeo.com/video/844466952?h=7b8aee1f92',
  //   title: 'Day 2 Opening Remarks',
  //   desc: 'Tan Sri Ahmad Badri Mohd Zahir',
  //   thumbnail: videoImage,
  // },
  {
    id: 13,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844467215?h=0e66f58280',
    title: 'Officiating ISWC 2023 and Launching of KWSP i-Akaun App',
    desc: "YAB Dato’ Seri Anwar Ibrahim",
    thumbnail: videoImage,
  },
  {
    id: 14,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844474008?h=bce903fc57',
    title: 'Launching of KWSP i-Akaun App',
    desc: '',
    thumbnail: videoImage,
  },
  {
    id: 15,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844467538?h=e549f1ee41',
    title: '(Session 8) Social Insurance System: A Cross-Country Comparison',
    desc: 'William Price',
    thumbnail: videoImage,
  },
  {
    id: 16,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844468256?h=21b808b450',
    title: '(Session 9) People Centric Policies',
    desc: 'Dr. Helmy Haja Mydin',
    thumbnail: videoImage,
  },
  {
    id: 17,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844468808?h=d17b730864',
    title: '(Panel Session 3) Putting Social Protection at the Forefront',
    desc: 'Prof. Datuk Dr. Norma Binti Mansor (Moderator)',
    thumbnail: videoImage,
  },
  {
    id: 18,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844469395?h=bede1e18d2',
    title: '(Session 10) Social Security Rights For All',
    desc: 'Professor Laurence Kotlikoff',
    thumbnail: videoImage,
  },
  {
    id: 19,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844470209?h=8b3fd97f79',
    title: '(Session 11) Innovative Solutions in Public Spending',
    desc: 'Dr. Juita Mohamad',
    thumbnail: videoImage,
  },
  {
    id: 20,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844470689?h=8fc3482b1e',
    title: '(Session 12) Social Change & Nation Building through Technology, Venture Capital, and Business',
    desc: 'Khailee Ng',
    thumbnail: videoImage,
  },
  {
    id: 21,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844471298?h=bb13f2bd8d',
    title: '(Panel Session 4) Solidarity in the Pursuit of Wellbeing',
    desc: 'Rohaya Mohammad Yusof (Moderator)',
    thumbnail: videoImage,
  },
  {
    id: 22,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844472521?h=a0b30444ba',
    title: '(Session 13) Social Enterprises in Action: Showcasing Impactful Solutions to Local and Global Challenges',
    desc: 'Alvin Chen',
    thumbnail: videoImage,
  },
  {
    id: 23,
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844473391?h=6ed1f31f4e',
    title: '(Session 13) Social Enterprises in Action: Showcasing Impactful Solutions to Local and Global Challenges',
    desc: 'Dr. Nick Boden',
    thumbnail: videoImage,
  },
  // {
  //   id: 24,
  //   itemType: 'video',
  //   embedUrl: 'https://player.vimeo.com/video/844473775?h=71212d0d2f',
  //   title: 'Day 2 Closing Remarks',
  //   desc: "Dato’ Sri Wan Ahmad Dahlan bin Haji Abdul Aziz",
  //   thumbnail: videoImage,
  // },
]

const booklets: Array<onDemandVideosType> = [
  {
    itemType: 'pdf',
    embedUrl: 'https://iswc-2023.s3.ap-southeast-1.amazonaws.com/gallery/SPI+Vol6+202306030.pdf',
    title: 'SPI Booklet Vol.6',
    thumbnail: pdfImage,
  },
]

const eventVideos: Array<onDemandVideosType> = [
  {
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844817618?h=84605ef71b',
    title: 'EPF ISWC Promo Video',
    thumbnail: videoImage,
  },
  {
    itemType: 'video',
    embedUrl: 'https://player.vimeo.com/video/844817672?h=0d30a7ce92',
    title: 'EPF ISWC Opening Video',
    thumbnail: videoImage,
  },
]
export {
  speakerSlides,
  sketchpost,
  recordings,
  booklets,
  eventVideos,
}
