import videoImage from '../../images/gallerium/onDemand/video-thumbnail.png'
import pdfImage from '../../images/gallerium/onDemand/pdf-thumbnail.png'
import posterImage from '../../images/gallerium/onDemand/poster-thumbnail.png'

import {
  onDemandVideosType, speakerSlides, sketchpost, recordings, eventVideos, booklets
} from './videos'

export interface onDemandCategoryType {
  id: number
  title: string
  categoryName?: string
  thumbnail: string
  profile?: string
  destination?: string
  vimeoId?: string
  videos?: Array<onDemandVideosType>
}

const onDemandCategories: Array<onDemandCategoryType> = [
  {
    id: 1,
    title: "Speakers' Slides",
    thumbnail: pdfImage,
    destination: '/gallery/slides',
    videos: speakerSlides,
  },
  // {
  //   id: 2,
  //   title: 'Sketchpost',
  //   thumbnail: posterImage,
  //   destination: '/gallery/sketchpost',
  //   videos: sketchpost,
  // },
  {
    id: 3,
    title: 'Session Recording',
    thumbnail: videoImage,
    destination: '/gallery/recordings',
    videos: recordings,
  },
  {
    id: 4,
    title: "SPI Booklet Vol.6",
    thumbnail: pdfImage,
    destination: '/gallery/spi-booklet',
    videos: booklets,
  },
  {
    id: 5,
    title: 'Event Videos',
    thumbnail: videoImage,
    destination: '/gallery/event-videos',
    videos: eventVideos,
  },
]

export { onDemandCategories }
