import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { DocumentViewer } from 'src/components/GlobalModals/DocumentViewer.js'
import IframeModal from 'src/components/GlobalModals/IframeModal.js'
import { fixedAssets } from 'src/containers/PreCache.js'
import styled from 'styled-components'
import { getBooth, getBooths } from '../ducks/booth'
import Amplitude from '../utils/amplitudeHelper'
import pdf from '../images/booth/pdf-icon.png'
import video from '../images/booth/video-icon.png'
import info from '../images/booth/info-icon.png'
import namecard from '../images/booth/namecard-icon.png'
import wallpaper from '../images/booth/image-icon.png'
import MobileHeader from 'src/components/MobileHeader.js'
import { Loader } from 'src/components/shared/Loader'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom, EffectCoverflow } from 'swiper'
import BoothNameCard from 'src/components/BoothNameCard'
import { useObject } from 'react-firebase-hooks/database'
import { TokenDb } from 'src/firebaseConfig'
import { MobileCoinModal } from 'src/components/Gamification/MobileCoinModal'
import hintPointer from '../images/gamification/finger.png'
import { HintComponent } from 'src/components/Gamification/HintComponent'

const MobileBooth = ({ currentUser }) => {
  const history = useHistory()
  const { boothId } = useParams()
  const dispatch = useDispatch()
  const { viewBooth, booths, boothMissions } = useSelector((state) => state.booth)

  const [iframeOpen, setIframeOpen] = useState(false)
  const [playVideo, setPlayVideo] = useState({})
  const [documentSelected, setDocumentSelected] = useState(null)
  const [selectedTab, setSelectedTab] = useState('')
  const [missionName, setMissionName] = useState("")
  const [showMission, setShowMission] = useState(false)
  const [coinModal, setCoinModal] = useState(false)
  const [snapshot, loading, error] = useObject(TokenDb)

  useEffect(() => {
    dispatch(getBooth(boothId))
    setShowMission(false)
    setMissionName("")
  }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.hall.id))
      Amplitude.visitBooth(viewBooth.title)
    }
  }, [boothId, currentUser.id, dispatch, viewBooth])

  useEffect(() => {
    if (viewBooth) handleInfo()
  }, [viewBooth, boothMissions])

  const handleModal = (video) => {
    Amplitude.watchBoothVideo(video.filename)
    setPlayVideo(video)
    setIframeOpen(true)
    if (boothMissions[0]?.mission_type === "watch_video") openCoinModal()
  }

  const handleDownload = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setSelectedTab('pdf')
    if (boothMissions[0]?.mission_type === "download_pdf") openCoinModal()
  }
  
  const handleVideoModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'video')
    setSelectedTab('video')
  }
  const handlePoster = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'poster')
    setSelectedTab('poster')
    if (boothMissions[0]?.mission_type === "image_gallery") openCoinModal()
  }
  const handleInfo = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'info')
    setSelectedTab('info')
    if (boothMissions[0]?.mission_type === "check_company_info") openCoinModal()
  }
  const handleNameCard = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'namecard')
    setSelectedTab('namecard')
  }
  
  const goNext = () => {
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    const nextLink = `/booths/${navigateTo}`
    history.push(nextLink)
  }

  const goPrevious = () => {
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    const prevLink = `/booths/${navigateTo}`
    history.push(prevLink)
  }
  const handleShowHint = () => {
    Amplitude.clickHint(viewBooth.title)
    setMissionName(boothMissions[0]?.display_name)
    setShowMission(true)
  }
  const openCoinModal = () => {
    setCoinModal(true)
  }

  if (!viewBooth) {
    return (
      <Page>
        <Loader fullPage loading />
      </Page>
    )
  }
  return (
    <Page>
      <MobileHeader />
      { coinModal && <MobileCoinModal coinModal={coinModal} mission={boothMissions[0]} setCoinModal={setCoinModal} />  }
      <div className="container d-flex justify-content-end">
        <HintComponent showMission={showMission} handleShowHint={handleShowHint} missionName={missionName} />
      </div>
      <div className="outer-wrapper">
        <div className='navCon pt-1 pb-2'>
          <div className="container w-100">
            <div className="nav-wrapper">
              <p className="booth-name">{viewBooth.title}</p>
              <div className="nav-buttons-wrapper">
                {viewBooth.order !== 0 && (
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839" className="nav-button-icon" onClick={goPrevious}>
                    <g transform="translate(17.301 26.839) rotate(180)">
                      <path d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z" transform="translate(17.301 26.839) rotate(180)" fill="#000"/>
                    </g>
                  </svg>
                )}
                {viewBooth.order !== booths.length-1 && (
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839" className="nav-button-icon" onClick={goNext}>
                    <path d="M.459,12.376,13.147.432a1.637,1.637,0,0,1,2.216,0l1.48,1.393a1.415,1.415,0,0,1,0,2.084L6.79,13.419,16.845,22.93a1.415,1.415,0,0,1,0,2.084l-1.48,1.393a1.637,1.637,0,0,1-2.216,0L.459,14.462A1.415,1.415,0,0,1,.459,12.376Z" transform="translate(17.301 26.839) rotate(180)" fill="#000"/>
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="booth-image-wrapper">
          <img src={viewBooth.background_image.url} className="booth-image" alt="booth"/>
        </div>

        <div className='booth-material-container'>
          {viewBooth ? (
            <div className="booth-menu-list">
              <div className={`${(showMission && boothMissions[0]?.mission_type==="check_company_info") && "show-hint" } ${selectedTab === "info" && 'active'} booth-menu-item`} onClick={handleInfo}>
                <img src={info} className="booth-menu-item-icon" alt="resource-icon"/>
                {!loading && !error && snapshot && snapshot.val() === "on" && boothMissions[0]?.mission_type==="check_company_info" &&
                  <Finger>
                    <img className="finger-pointer" src={hintPointer} alt="pointer" />
                  </Finger>
                }
              </div>
              {viewBooth.booth_videos.length > 0 && 
                <div className={`${(showMission && boothMissions[0]?.mission_type==="watch_video") && "show-hint" } ${selectedTab === "video" && 'active'} booth-menu-item`} onClick={handleVideoModal}>
                  <img src={video} className="booth-menu-item-icon" alt="video-icon"/>
                  {!loading && !error && snapshot && snapshot.val() === "on" && boothMissions[0]?.mission_type==="watch_video" &&
                    <Finger>
                      <img className="finger-pointer" src={hintPointer} alt="pointer" />
                    </Finger>
                  }
                </div>
              }
              {viewBooth.documents.length > 0 && 
                <div className={`${(showMission && boothMissions[0]?.mission_type==="download_pdf") && "show-hint" } ${selectedTab === "pdf" && 'active'} booth-menu-item`} onClick={handleDownload}>
                  <img src={pdf} className="booth-menu-item-icon" alt="resource-icon"/>
                  {!loading && !error && snapshot && snapshot.val() === "on" && boothMissions[0]?.mission_type==="download_pdf" &&
                    <Finger>
                      <img className="finger-pointer" src={hintPointer} alt="pointer" />
                    </Finger>
                  }
                </div>
              }
              {viewBooth.wallpaper_gallery.length > 0 &&
                <div className={`${(showMission && boothMissions[0]?.mission_type==="image_gallery") && "show-hint" } ${selectedTab === "poster" && 'active'} booth-menu-item`} onClick={handlePoster}>
                  <img src={wallpaper} className="booth-menu-item-icon" alt="resource-icon"/>
                  {!loading && !error && snapshot && snapshot.val() === "on" && boothMissions[0]?.mission_type==="image_gallery" &&
                    <Finger>
                      <img className="finger-pointer" src={hintPointer} alt="pointer" />
                    </Finger>
                  }
                </div>
              }
              {viewBooth.booth_agents.length > 0 &&
                <div className={`${selectedTab === "namecard" && 'active'} booth-menu-item`} onClick={handleNameCard}>
                  <img src={namecard} className="booth-menu-item-icon" alt="resource-icon"/>
                </div>
              }
              
            </div>
          ) : (
            <div className="load-container">
              <Loader />
            </div>
          )}
        </div>
        {selectedTab === "info" &&
          <div className='info-tab tabs'>
            <h5>{viewBooth?.title}</h5>
            <div className="content" dangerouslySetInnerHTML={{ __html: viewBooth?.content }} />
            {viewBooth.company_website_url && <div>Visit Our Website: <a href={viewBooth.company_website_url} target="_blank" rel="noopener noreferrer">{viewBooth.company_website_url}</a></div>}
            {viewBooth.company_number &&  <div>Drop us Message: <a href={`https://wa.me/${viewBooth.company_number}`} target="_blank" rel="noopener noreferrer">{viewBooth.company_number}</a></div>}
            {viewBooth.company_video_chat_link && <div>Video Call: <a href={viewBooth.company_video_chat_link} target="_blank" rel="noopener noreferrer">Join Here!</a></div>}
            <br />
            {viewBooth.booth_agents.length > 0 && 
              <div className="contact">
                <div >
                  <h6 className="p-0">
                    <u>Contact Information</u>
                  </h6>
                </div>
                {viewBooth.booth_agents.map((agent,index)=>(
                  <div key={`agent-${index+1}`} className="mb-4" >
                    <p className="my-0 font-weight-bold">{agent.name}</p>
                    {agent.phone.length > 0 &&
                      <p className="my-1">
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" fill="#8468CE"/>
                        </svg>
                        <span className="ms-1"><a href={`https://wa.me/${agent.phone}`} target="_blank" rel="noopener noreferrer">{agent.phone}</a></span>
                      </p>
                    }
                    {agent.email.length > 0 &&
                      <p className="my-1">
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" fill="#8468CE"/>
                        </svg>
                        <span className="ms-1"><a href={`mailto:${agent.email}`} target="_blank" rel="noopener noreferrer">{agent.email}</a></span>
                      </p>
                    }
                  </div>
                ))}
              </div>
            }
          </div>
        }
        {selectedTab === "namecard" &&
          <div className='info-tab tabs'>
            <BoothNameCard booth={viewBooth} currentUser={currentUser} hideModal={() => console.log("close")} />
          </div>
        }
        {selectedTab === "video" &&
          <div className='video-tab tabs'>
            <h5 className='text-center'>Select video(s) to play</h5>
            {viewBooth.booth_videos.map((video,index)=>(
              <div key={`video-${index}`} className="file-container" onClick={()=>handleModal(video)}>
                <p>{video.filename}</p>
                <button>
                  <svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#846CCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <polygon points="10 8 16 12 10 16 10 8"></polygon>
                  </svg>
                </button>
              </div>
            ))}
          </div>
        }
        {selectedTab === "pdf" &&
          <div className='pdf-tab tabs'>
            <h5 className='text-center'>Select file(s) to preview</h5>
            {viewBooth.documents.map(file=>(
              <div key={file.filename} className="file-container" onClick={() => setDocumentSelected(file)}>
                <p>{file.filename}</p>
                <button>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#846CCC" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="#846CCC" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline stroke="#846CCC" points="7 10 12 15 17 10"></polyline>
                    <line stroke="#846CCC" x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                </button>
              </div>
            ))}
          </div>
        }
        {selectedTab === "poster" &&
          <div className='poster-tab tabs'>
            {viewBooth.wallpaper_gallery.length > 0 &&
              <Swiper
                className='mySwiper'
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom, EffectCoverflow]}
                slidesPerView={1}
                navigation={true}
                effect="coverflow"
                zoom={true}
                pagination={{ 
                  clickable: true,
                  renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                  },
                }}
                scrollbar={{ draggable: true }}
              >
                {viewBooth.wallpaper_gallery.map((poster,index)=>(
                  <SwiperSlide key={`poster-${index}`} zoom={true}>
                    <img className="d-block" src={poster.url} alt={`poster-${index}`} width="100%" />
                  </SwiperSlide>
                ))}
              </Swiper>
            }
          </div>
        }

        {iframeOpen &&  <IframeModal iframeOpen={iframeOpen} setIframeOpen={setIframeOpen} iframe={playVideo} />}
        {documentSelected && <DocumentViewer file={documentSelected} setDocumentSelected={setDocumentSelected} />}
      </div>
    </Page>
  )
}

const Finger = styled.div`
  position: absolute;
  width: 90px;
  height: 90px;
  top: 55%;
  pointer-events: none;
  ${(props) => props.css}

  .finger-pointer {
    position: absolute;
    z-index: 10;
    left: 30%;
    bottom: 60%;
    opacity: 0;
    animation: appear 2s 3s forwards, mousepoint 1s 3s infinite;
    animation-iteration-count: 1;
    width: 36px;
  }
`
const Page = styled.div`
  height: calc(100% - 50px);
  width: 100vw;
  overflow-y: scroll;
  position: absolute;
  background: #F9F8F8;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  
  scrollbar-width: none; 

  .container {
    width: 90%;
  }
  .nav-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .booth-name {
    font-size: 14px;
    margin: 0;
  }
  .tabs {
    width: 90%;
    padding: 4px;
    margin: 4px auto;
    color: #505c7c;
    opacity: 0;
    animation: appear 500ms 500ms forwards;
    
    h5 {
      font-size: 16px;
      color: #000;
    }
    .content {
      font-size: 14px;
      margin-bottom: 8px;
    }
    a {
      color: #8468CE;
    }
  }
  .info-tab {
    div, p {
      font-size: 14px;
    }
  }
  .file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    
    p {
      font-size: 14px;
      margin: 0;
    }
    button {
      background: transparent;
      border: none;
      color: #FFAD2C;
    }
  }
  .booth-image-wrapper {
    background-image: url(${fixedAssets.boothBg});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 200px;
    position: relative;
  }
  .booth-image {
    width: 100%;
    height: 80%;
    margin-top: 7.5%;
    margin-bottom: 2.5%;
    object-fit: contain;
    opacity: 0;
    animation: appear 500ms 500ms forwards;
  }
 
  .booth-material-container {
    position: relative;
  }
  .booth-menu-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0;
    padding: 8px 0;
    position: relative;
  }
  .booth-menu-item {
    width: 18%;
    padding: 8px 0;
    border-bottom: 3px solid transparent;
    transition: all 300ms ease-in;
    position: relative;
  }
  .show-hint {
    animation: bounce 3s infinite;
  }
  .active {
    border-bottom: 3px solid #8CD5B3;
  }
  .booth-menu-item-icon {
    width: 65%;
    max-width: 80px;
    margin: auto;
    display: block;
  }
  .nav-buttons-wrapper {
    width: 50px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-button-icon {
    height: 20px;
  }
  .contact {
    width: 100%;
    margin: 16px 0;
    p > svg {
      margin-right: 8px;
    }
    a {
      color: #505c7c;
      text-decoration: none;
    }
  }
  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }

    .booth-image-wrapper {
      height: 300px;
    }
  }
`

export default MobileBooth
