import React from 'react'
import styled from 'styled-components'
import success from '../../images/gamification/success.png'
import fail from '../../images/gamification/fail.png'
import { SIZES } from 'src/styles/theme'

const ResultWrapper = styled.div`
  padding: 12px;
  color: #022136;
  font-size: 90%;
  max-height: 350px;
  overflow-y: scroll;
  text-align: center;
  padding-top: 2rem;
  h6 {
    color: #F85D49;
    font-weight: 600;
  }
  h6, p {
    margin: 4px 0;
  }
  img {
    margin-bottom: 16px;
  }
  .link-style {
    text-decoration: underline;
    cursor: pointer;
  }
  ${SIZES.mobile} {
    min-height: 350px;
    max-height: inherit;
  }
`

function EntryResultTab({ drawEntered, setTab }) {
  return (
    <ResultWrapper>
      <img src={drawEntered ? success : fail } width="18%" alt="emoji" />

      { drawEntered ? 
        <>
          <h6>Well done! You are now in the Lucky Draw.</h6> 
          <p>Remember to check the winner’s list tomorrow</p>
          <p>to find out if you are one of the lucky winners.</p>
        </>
        :
        <>
          <h6>Oh no! You don’t have enough points to enter the Lucky Draw.</h6>
          <h6><span className="link-style" onClick={()=>setTab('Mission Tasks')}>Start completing tasks</span>&nbsp;now to gain points.</h6>
          <p>Points reset daily, be quick to collect minimum of 100 points to be in the draw.</p>
        </>
      }
      <br />
      

    </ResultWrapper>
  )
}

export { EntryResultTab }