import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { CoinComponent } from '../Gamification/CoinComponent'
import { useSelector } from 'react-redux'

const StyledModal = styled(Modal)`
  color: #505c7c;
  .modal-content {
    border: none;
    border-radius: 20px;
  }
  .modal-header {
    border: none;
    justify-content: flex-end;
  }
  .modal-body {
    padding: 32px;
    padding-top: 0;
    h5 {
      font-size: 22px;
      font-weight: 600;
    }
    a {
      color: #8468CE;
    }
    .content {
      width: 100%;
      overflow-x: hidden;
      padding-bottom: 16px;
    }
    .contact {
      width: 100%;
      margin: 16px 0;
      p > svg {
        margin-right: 8px;
      }
      a {
        color: #505c7c;
        text-decoration: none;
      }
    }
  }
`

export default function InfoModal({ booth, infoOpen, setInfoOpen }) {
  const { boothMissions } = useSelector((state) => state.booth)

  if (!infoOpen) return <></>

  return (
    <StyledModal
      show={infoOpen}
      onHide={()=>setInfoOpen(false)}
      size="lg"
      aria-labelledby="info-modal"
      centered
    > 
      { boothMissions[0]?.mission_type === "check_company_info" && <CoinComponent mission={boothMissions[0]} />}

      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h5 className='text-center'>{booth.title}</h5>
        <div className="content" dangerouslySetInnerHTML={{ __html: booth.content }} />
        {booth.company_website_url && <div>Visit Our Website: <a href={booth.company_website_url} target="_blank" rel="noopener noreferrer">{booth.company_website_url}</a></div>}
        {booth.company_number &&  <div>Drop us Message: <a href={`https://wa.me/${booth.company_number}`} target="_blank" rel="noopener noreferrer">{booth.company_number}</a></div>}
        {booth.company_video_chat_link && <div>Video Call: <a href={booth.company_video_chat_link} target="_blank" rel="noopener noreferrer">Join Here!</a></div>}
        {booth.booth_agents.length > 0 &&
          <div className="contact">
            <div >
              <h5 className="p-0 font-weight-bold">
                <u>Contact Information</u>
              </h5>
            </div>
            {booth.booth_agents.map((agent,index)=>(
              <div key={`agent-${index+1}`} className="mb-4" >
                <p className="my-0 font-weight-bold">{agent.name}</p>
                {agent.phone.length > 0 &&
                  <p className="my-1">
                    <svg width="20" height="20" viewBox="0 0 24 24">
                      <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" fill="#8468CE"/>
                    </svg>
                    <span className="ms-1"><a href={`https://wa.me/${agent.phone}`} target="_blank" rel="noopener noreferrer">{agent.phone}</a></span>
                  </p>
                }
                {agent.email.length > 0 &&
                  <p className="my-1">
                    <svg width="20" height="20" viewBox="0 0 24 24">
                      <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" fill="#8468CE"/>
                    </svg>
                    <span className="ms-1"><a href={`mailto:${agent.email}`} target="_blank" rel="noopener noreferrer">{agent.email}</a></span>
                  </p>
                }
              </div>
            ))}
          </div>
        }
      </Modal.Body>
    </StyledModal>
  )
}
