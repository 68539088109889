import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { enterDraw, getMissions, getWinners } from '../../ducks/mission'
import HeaderBanner from '../../images/gamification/headerM.png'
import routes from '../../routes'
import { GameNav } from '../../components/Gamification/GameNav'
import { PointSection } from '../../components/Gamification/PointSection'
import { MissionTaskTab } from '../../components/Gamification/MissionTaskTab'
import { WinnersTab } from '../../components/Gamification/WinnersTab'
import { EntryResultTab } from '../../components/Gamification/EntryResultTab'
import { TermsTab } from '../../components/Gamification/TermsTab'
import { InstructionTab } from '../../components/Gamification/InstructionTab'
import { IntroTab } from '../../components/Gamification/IntroTab'
import Amplitude from 'src/utils/amplitudeHelper'

const PageWrapper = styled.div`
  height: calc( 100% - 50px);
  background: rgba(255, 255, 255, 0.96);
  overflow-y: scroll;
  position: absolute;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  
  header {
    background: #fff;
    margin-bottom: 12px;
    a {
      display: block;
      width: 90%;
      text-decoration: none;
      color: #000;
      padding-top: 18px;
      margin: 0px auto;
    }
  }
  #content-container {
    min-height: 350px;
    position: relative;
    color: #022136;
    
    .start-button {
      background: #B2F3FF;
      color: #000;
      border: none;
      border-bottom: 4px solid #012F5B;
      box-shadow: 0px 2px -2px #012F5B;
      border-radius: 12px;
      display: block;
      width: 90%;
      margin: 0 auto;
      padding: 6px 0;
    }
  }
  #sub-section {
    width: 95%;
    margin: 12px auto;
    border: 2px solid #2A6CFF;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
  }
  
`

function MobileGamification({ currentUser }) {
  const dispatch = useDispatch()
  const { drawEntered, missions, winners } = useSelector((state) => state.mission)
  const [tab, setTab] = useState(null)

  useEffect(() => {
    dispatch(getMissions())
    dispatch(getWinners())
  }, [])

  useEffect(() => {
    Amplitude.visit('Game')
  }, [currentUser.id])

  const handleEnterDraw = () => {
    Amplitude.enterDraw()
    dispatch(enterDraw(currentUser.id))
    setTab("entry-result-tab")
  }

  return (
    <PageWrapper>
      <header>
        <Link to={routes.lobby}>
          <span>Token Hunter</span>
          {(tab && tab!=="entry-result-tab") &&
            <>
              <span>{` > `}</span>
              <span>{tab}</span>
            </>
          }
          
        </Link>
        <img id="header" src={HeaderBanner} width="100%" alt="lucky draw banner" />
      </header>

      <PointSection handleEnterDraw={handleEnterDraw} />

      <GameNav tab={tab} setTab={setTab} />

      <section id="content-container">
        {!tab ? <IntroTab setTab={setTab} />
          :
          <div id="sub-section">
            { tab === "How To Play" && <InstructionTab setTab={setTab} />}

            { tab === "Mission Tasks" && <MissionTaskTab missions={missions} />}

            { tab === "Winner List" && <WinnersTab winners={winners} />}

            { tab === "Terms & Conditions" && <TermsTab />}

            { tab === "entry-result-tab" &&  <EntryResultTab drawEntered={drawEntered} setTab={setTab} />}
          </div>
        }

        
      </section>
    </PageWrapper>
  )
}

export { MobileGamification }