import React from 'react'
import styled from 'styled-components'
import activePoint from '../../images/gamification/active-point.png'
import disabledPoint from '../../images/gamification/disabled-point.png'
import { getMissionRoute } from '../../utils/missionHelper'
import { useHistory } from 'react-router-dom'
import { SIZES } from '../../styles/theme'
import { useDispatch } from 'react-redux'
import { showGlobalModal } from 'src/ducks/layout'

const MissionTask = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
  color: #022136;
  font-size: 90%;
  max-height: 350px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  
  scrollbar-width: none; 

  .mission-item {
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
  }
  button {
    padding: 0;
    background: transparent;
    border: none; 
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      g.arrow {
        fill: #018806;
      }
    }
  }
  svg {
    width: 80%;
    g.arrow {
      fill: #2A6CFF;
    }
  }
  p.item-title {
    color: #2A6CFF;
    font-weight: 600;
    font-size: 11px;
  }
  small {
    font-weight: 500;
    color: #2A6CFF;
  }
  .disabled {
    p.item-title, small {
      color: #414141;
    }
    button svg g.arrow {
      fill: #414141;
    }
  }
  ${SIZES.mobile} {
    .mission-item {
      width: 100%;
      margin: auto;
      max-width: 450px;
    }
  }
`

function MissionTaskTab({ missions }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleRedirect = (ms) => {
    const url = getMissionRoute(ms.task_name)
    if (url.length > 0) {
      history.push(url)
    } else {
      history.push(`/booths/${ms.booth_id}`)
    }
    dispatch(showGlobalModal(null))
  }

  return (
    <MissionTask>
      {missions.map(mission => (
        <div className={`mission-item ${mission.completed ? 'disabled' : ''}`} key={mission.id}>
          <div className="w-75">
            <p className="item-title m-0 mb-1">{mission.display_name}</p>
            <img src={mission.completed ? disabledPoint : activePoint} width="28px" alt="point" />
            <small> + {mission.points} points</small>
          </div>
          <button onClick={() => handleRedirect(mission)}>
            <svg width="58" height="58" viewBox="0 0 58 58">
              <g transform="translate(-9617 -1262)">
                <g className="arrow" transform="translate(9617 1262)" stroke="#fff" strokeWidth="3">
                  <circle cx="29" cy="29" r="29" stroke="none"/>
                  <circle cx="29" cy="29" r="27.5" fill="none"/>
                </g>
                <g transform="translate(9666.925 1269.945) rotate(90)">
                  <path d="M20.924,0A20.924,20.924,0,1,1,0,20.924,20.924,20.924,0,0,1,20.924,0Z" transform="translate(0)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="5"/>
                  <path d="M0,28.4V0" transform="translate(20.924 11.957)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="5"/>
                  <path d="M0,10.462,10.462,0,20.924,10.462" transform="translate(10.462 10.462)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="5"/>
                </g>
              </g>
            </svg>
          </button>
        </div>
      ))}
    </MissionTask>
  )
}
export { MissionTaskTab }