const routes = {
  root: '/',
  landing: '/landing',
  register: '/registration',
  postRegister: '/registration/complete',
  resetPassword: '/registration/create-password',
  forgotPassword: '/registration/forgot-password',
  login: '/login',
  sso: '/sso',

  notifications: '/notifications',
  menu: '/menu',

  lobby: '/lobby',
  speakers: '/speakers',
  programme: '/agenda',
  helpdesk: '/helpdesk',

  networking: '/networking',
  cloudflix: '/highlights',

  auditorium: '/auditorium-hall',
  
  zoom: '/zoom',
  joinSession: '/join-session',
  leaveSession: '/leave-session',

  lucky: '/token-hunter',
  questions: '/live-questions',

  onDemand: '/gallery',
  onDemandOral: '/on-demand/oral-presentations',
  onDemandPoster: '/on-demand/e-poster-presentations',
  onDemandBbs: '/on-demand/best-basic-science-award',
} as const

type Route = typeof routes
export type RoutesType = Route[keyof Route]

export default routes
