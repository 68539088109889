import Axios from 'axios'
import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import { SIZES } from 'src/styles/theme'

const CustomModal = styled(Modal)`
  color: #505c7c;

  .modal-content {
    padding: 16px;
    border-radius: 24px;
  }
  .modal-header {
    border: none;
    padding: 0 16px;
  }
  .modal-body {
    padding: 0 16px;
  }
  .btn {
    display: block;
    width: 100%;
    margin-left: auto;
    border: none;
    border-radius: 28px;
    padding: 6px 16px;
    background: #8468CE;
  }
  textarea {
    border-radius: 10px;
    padding: 10px 15px;
    border: solid 1px #707070;
    &:focus {
      box-shadow: none;
      border: 1px solid #846CCC;
    }
  }
  ${SIZES.mobile} {
    font-size: 14px;
    .btn {
      font-size: 14px;
    }
    textarea {
      font-size: 14px;
      border-radius: 8px;
      padding: 8px;
      border: solid 1px #707070;
      &:focus {
        box-shadow: none;
        border: 1px solid #846CCC;
      }
      &::placeholder {
        font-size: 12px;
      }
    }
  }
`

export default function DropNamecardModal({ currentUser, user, modal, hideModal }) {
  const [message, setMessage] = useState('')
  const [load, setLoad] = useState(false)

  const handleInput = (e) => setMessage(e.target.value)

  const submitNamecard = () => {
    setLoad(true)
    Amplitude.dropNamecard({
      senderId: currentUser.id,
      senderFullName: currentUser.fullName,
      senderEmail: currentUser.email,
      receiverFullName: user.fullName,
      receiverEmail: user.email,
    })
    Axios({
      method: 'post',
      url: `${config.apiUrl}/api/drop_namecard`,
      data: {
        receiver_email: user.email,
        message,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    })
      .then(() => {
        toast(`An email has been sent to ${user.fullName}!`)
      })
      .catch((err) => {
        setLoad(false)
        setMessage('')
        toast('Sending name card failed. Something went wrong.');
      })
      .finally(() => {
        setLoad(false)
        hideModal(0)
        setMessage('')
      })
  }

  return (
    <CustomModal
      show={modal}
      onHide={hideModal}
      aria-labelledby="drop-name-card"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>
          Drop <strong>{user.fullName}</strong> your name card!
        </p>
        <p>
          This will send {user.fullName} an email with your contact information
          indicating that you wish to connect.
        </p>
        <p>You can also include custom message:</p>
        <Form.Control
          placeholder="(Optional)"
          value={message}
          onChange={handleInput}
          as="textarea"
          rows={3}
        />
        <Button disabled={load} onClick={submitNamecard} className="mt-2">
          {load ? 'Sending...' : 'Drop your name card'}
        </Button>
      </Modal.Body>
    </CustomModal>
  )
}
