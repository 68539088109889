import { getRefreshDb, getOnlineStatusDb, getUserSessionDb, getUserCountDb } from '../firebaseConfig'
import firebase from 'firebase/app'

const firebaseLogout = (currentUser, path) => {
  const pagePath = '/' + path.substring(1).replaceAll('/', ' | ')

  if (currentUser && currentUser.id) {
    getRefreshDb(currentUser.id).remove()
    getUserCountDb(currentUser.id, pagePath).remove()
    getOnlineStatusDb(currentUser.id).set(false)
    getUserSessionDb(currentUser.id)
      .child(`${localStorage.getItem('node')}/end`)
      .set(firebase.database.ServerValue.TIMESTAMP)
  }
}

export { firebaseLogout }
