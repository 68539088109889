import React, { useEffect, useState } from 'react'
import { SIZES } from 'src/styles/theme'
import styled from 'styled-components'

const WinnerWrapper = styled.div`
  padding: 12px;
  color: #022136;
  font-size: 90%;
  max-height: 350px;
  overflow-y: scroll;
  h6 {
    color: #F85D49;
    font-weight: 600;
  }
  .day-section {
    padding: 8px 12px;
  }
  .winner-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .winner-name {
    font-size: 12px;
    margin: 4px 0;
  }
  ${SIZES.mobile} {
    min-height: 350px;
    .winner-list {
      grid-template-columns: 1fr 1fr;
    }
  }
`

function WinnersTab({ winners }) {
  // 50 winners per day
  // const dummy = {
  //   '22-06-2021':[
  //     "name 1",
  //   ],
  //   '23-06-2021':[
  //     "name 1",
  //   ],
  //   '24-06-2021':[
  //     "name 1",
  //   ]
  // }
  const [pageData, setPageData] = useState([])

  useEffect(() => {
    setPageData(
      Object.keys(winners).map((key) => ({
        date: key,
        winnerList: winners[key],
      }))
    )
  }, [])
  
  return (
    <WinnerWrapper>
      {
        pageData.length == 0 &&
        <p className="text-center my-3">Collect minimum of 190 points to be in the draw.</p>
      }
      {pageData.map(({date, winnerList})=>(
        <section key={date} className="day-section">
          <h6>Winner List for {date}</h6>
          <div className="winner-list">
            {winnerList.map(name=>(
              <p className="winner-name" key={name}>{name}</p>
            ))}
          </div>
        </section>
      ))}
    </WinnerWrapper>
  )
}

export { WinnersTab }