import axios from 'axios'
import config from '../config'

const SEND_REQUEST = 'SEND_REQUEST'
const RECEIVE_HALLS = 'RECEIVE_HALLS'
const RECEIVE_BOOTHS = 'RECEIVE_BOOTHS'
const RECEIVE_BOOTH = 'RECEIVE_BOOTH'
const SUBMIT_ANSWER = 'SUBMIT_ANSWER'
const APPEND_BOOTH_ANSWER = 'APPEND_BOOTH_ANSWER'
const RESET_BOOTH_ANSWER = 'RESET_BOOTH_ANSWER'
const SENDING_ANSWER = 'SENDING_ANSWER'
const RECEIVE_BOOTH_MISSIONS = 'RECEIVE_BOOTH_MISSIONS'

const initialState = {
  halls: [],
  booths: [],
  viewBooth: null,
  userScore: null,
  boothQuizAnswers: [],
  boothMissions: [],
}

export default function boothReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_REQUEST:
      return {
        ...state,
        apiLoading: true,
        viewBooth: null,
      }
    case RECEIVE_HALLS:
      return {
        ...state,
        halls: action.halls,
      }

    case RECEIVE_BOOTHS:
      return {
        ...state,
        apiLoading: false,
        booths: action.booths,
      }

    case RECEIVE_BOOTH:
      return {
        ...state,
        viewBooth: action.viewBooth,
      }
    case RECEIVE_BOOTH_MISSIONS:
      return {
        ...state,
        boothMissions: action.boothMissions
      }
    case APPEND_BOOTH_ANSWER:
      return {
        ...state,
        boothQuizAnswers: {
          [action.boothId]: action.questionAnswer,
        },
      }
    case RESET_BOOTH_ANSWER:
      return {
        ...state,
        boothQuizAnswers: {
          [action.boothId]: [],
        },
      }
    case SENDING_ANSWER:
      return {
        ...state,
        submitting: true,
      }
    case SUBMIT_ANSWER:
      return {
        ...state,
        submitting: false,
        userScore: action.userScore,
      }
    default:
      return state
  }
}

export function getHalls() {
  return (dispatch) => {
    dispatch(requestData())

    return axios
      .get(`${config.apiUrl}/api/halls`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveHalls(data.halls))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getBooths(hallId) {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/halls/${hallId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveBooths(data.hall.booths))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getBooth(boothId) {
  return (dispatch) => {
    dispatch(requestData())

    return axios
      .get(`${config.apiUrl}/api/booths/${boothId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          if (data.questionaire) {
            dispatch(
              receiveBooth({
                ...data.booth,
                questions: {
                  ...data.questionaire.questions,
                  userScore: data.user_score,
                  questionCount: data.question_count,
                },
              })
            )
            dispatch(showScore(data.user_score))
          } else {
            dispatch(
              receiveBooth({
                ...data.booth,
                questions: null,
              })
            )
            dispatch(receiveMissions(data.booth.missions))
          }
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}
export function updateBoothMission(missionId) {
  return (dispatch, getState) => {
    const state = getState().booth

    return dispatch(receiveMissions(state.boothMissions.map(ms => {
      if (ms.id == missionId) return { ...ms, completed: true } 
      else return ms
    })))
  }
}

export function submitAnswer(boothId, data) {
  return (dispatch) => {
    dispatch(sendingAnswer())

    return axios
      .post(`${config.apiUrl}/api/booths/${boothId}/complete_questionaire`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (data.success) {
          dispatch(showScore(data.user_score))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

function requestData() {
  return {
    type: SEND_REQUEST,
  }
}

function receiveHalls(halls) {
  return {
    type: RECEIVE_HALLS,
    halls,
  }
}

function receiveBooths(booths) {
  return {
    type: RECEIVE_BOOTHS,
    booths,
  }
}

function receiveBooth(viewBooth) {
  return {
    type: RECEIVE_BOOTH,
    viewBooth,
  }
}
export function receiveMissions(boothMissions) {
  return {
    type: RECEIVE_BOOTH_MISSIONS,
    boothMissions,
  }
}
export function appendAnswers(boothId, questionAnswer) {
  return {
    type: APPEND_BOOTH_ANSWER,
    boothId,
    questionAnswer,
  }
}
export function resetAnswers(boothId) {
  return {
    type: RESET_BOOTH_ANSWER,
    boothId,
  }
}
function sendingAnswer() {
  return {
    type: SENDING_ANSWER,
  }
}
function showScore(userScore) {
  return {
    type: SUBMIT_ANSWER,
    userScore,
  }
}
