import React, { FC, useEffect, useState } from 'react'
import { CurrentUserType } from 'src/ducks/auth'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import { StyledLobby } from './styled'
import PageWithBg from 'src/components/PageWithBg'
import { fixedAssets } from 'src/containers/PreCache'
import { LobbyEntry } from 'src/components/LobbyEntry'
import { Loader } from 'src/components/shared/Loader'
import { CoinComponent } from 'src/components/Gamification/CoinComponent'

export const LobbyPage: FC<{ currentUser: CurrentUserType }> = ({ currentUser }) => {
  const [loading, setLoading] = useState(true)
  const [mission, setMission] = useState(null)

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
    Amplitude.visit('Lobby')
  }, [])
  
  useEffect(() => {
    setMission(currentUser.missions?.filter(ms=>ms.task_name==="lobby")[0])
  }, [currentUser.missions])

  return (
    <>
      <StyledLobby>
        <PageWithBg bgImg={fixedAssets.lobby}>
          {config.lobbyEntries.map((entry) => {
            return <LobbyEntry key={entry.id} {...entry} currentUser={currentUser} />
          })}

          <div className="trailer">
            {loading && <Loader loading />}
            <iframe
              width="100%"
              height="100%"
              title="Lobby Vimeo"
              src="https://player.vimeo.com/video/841923461?h=98bba1375b&autoplay=1"
              allow="autoplay; fullscreen"
              allowFullScreen
              frameBorder="0"
              onLoad={() => {
                setLoading(false)
              }}
            />
          </div>
          { !loading && <CoinComponent mission={mission} hide={true} />}

          <div id="game">
            <img src={fixedAssets.gameStand} width="100%" alt="Token Hunter Stand" />
          </div>

          <div id="current-user">Logged in as {currentUser.email}</div>
        </PageWithBg>
      </StyledLobby>
    </>
  )
}
