import React from 'react'
import { SIZES } from 'src/styles/theme'
import styled from 'styled-components'

const TermsWrapper = styled.div`
  padding: 12px;
  color: #022136;
  font-size: 90%;
  max-height: 350px;
  overflow-y: scroll;
  h6 {
    color: #2A6CFF;
    font-weight: 600;
  }
  h6, p {
    width: 90%;
    margin: 10px auto;
  }
  p {
    span {
      margin-left: 8px;
    }
  }
  ${SIZES.mobile} {
    min-height: 350px;
    max-height: inherit;
    p {
      font-size: 80%;
      span {
        margin-left: 6px;
      }
    }
  }
`

function TermsTab() {
  return (
    <TermsWrapper>
      <h6>Terms and Conditions</h6>
      <p>
        Disclaimer: Rewards are exclusively reserved for Malaysian citizens only, participating virtually or physically. Attendees must also visit auditorium at least once. 
      </p>
      <p>Cut-off Date: <strong className='text-danger'>11:59PM, 20<sup>th</sup> July 2023</strong></p>
    </TermsWrapper>
  )
}

export { TermsTab }