import React, { useState } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import { Loader } from '../shared/Loader'
import { SIZES } from 'src/styles/theme'
import DropNamecardModal from './DropNamecardModal'

const Directory = styled.div`
  border: 1px solid #846CCC;
  border-radius: 12px;
  height: 90%;
  background: #fff;

  .directory {
    height: 100%;
    text-align: left;
    position: relative;
    padding: 4px 6px;

    ul {
      padding: 0;
      margin: 0;
      color: #333333;
      font-size: 12px;
      overflow-y: scroll;
      height: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      li {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 1em 0.75em;
        cursor: pointer;
        &:hover {
          background: rgba(255, 255, 255, 0.9);
        }
        .profile-image {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .info {
          margin-left: 1em;
          .name {
            text-transform: capitalize;
          }
        }
      }
    }
  }
  .contact-card {
    color: #505c7c;
    .heading {
      font-weight: 500;
    }
    .profile-image {
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 10px auto;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .drop-namecard {
      text-align: center;
      button {
        background: linear-gradient(#846CCC, #5D4591);
        width: 100%;
        max-width: 150px;
        margin: 1em auto;
        border-radius: 0px;
        padding: 8px 16px;
        box-shadow: none;
        outline: none;
        border: none;
        transition: 100ms;
        display: flex;
        align-items: center;
        svg {
          width: 25%;
          display: block;
        }
        p {
          width: 75%;
          color: #FFF;
          font-weight: 500;
          font-size: 12px;
          text-align: left;
          padding-left: 8px;
        }
      }
    }
  }
  .back-btn {
    text-align: left;
    padding: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    small {
      font-size: 14px;
      margin-left: 8px;
      font-weight: 600;
    }
  }
  ${SIZES.mobile} {
    height: 80%;
  }
`

const UserDirectory = React.memo(({ currentUser, toggleAvatar, users = [], onlineUsers, showUser }) => {
  const [modal, setModal] = useState(false)
  if (users.length > 0)
    return (
      <Directory>
        {modal && <DropNamecardModal currentUser={currentUser} hideModal={()=>setModal(false)} modal={modal} user={showUser} />}

        {showUser ?
          <div className="contact-card">
            <div className='back-btn' onClick={toggleAvatar}>
              <svg width="23.81" height="6.985" viewBox="0 0 23.81 6.985">
                <g transform="translate(1.06 1.06)">
                  <path d="M699.175,759H677.491" transform="translate(-677.175 -754)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                  <path d="M716.5,746.126l-5.294,4.866" transform="translate(-711.207 -746.126)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
              </svg>
              <small>Back</small>
            </div>
            <div className="profile-image my-md-4">
              <Avatar round name={showUser.fullName} size="100px" />
            </div>
            <div className="details">
              <h6 className="text-center">{showUser.fullName}</h6>
              {/* <p className="contact">{showUser.category}</p> */}
              {showUser.email === currentUser.email ? (
                <p className="text-dark">
                  <em>This is your information</em>
                </p>
              ) : (
                <div className="drop-namecard">
                  <button onClick={() => setModal(!modal)}>
                    <svg width="44.08" height="29.08" viewBox="0 0 44.08 29.08">
                      <path d="M19.869,41.094l-3.2-.439q-4.743-.65-9.487-1.3c-.87-.12-.869-.131-.869-1.017,0-4.036,0-8.073-.008-12.109a.728.728,0,0,1,.458-.776q8.934-4.483,17.848-9.011a.609.609,0,0,1,.678.029c3.643,2.194,7.3,4.374,10.935,6.573a2.491,2.491,0,0,0,1.367.375c3.079-.017,6.157-.009,9.236-.009a3.322,3.322,0,0,1,3.541,3.455q.034,7.579,0,15.158a3.327,3.327,0,0,1-3.506,3.405q-11.778.005-23.555,0a3.391,3.391,0,0,1-3.437-3.362c-.008-.307,0-.615,0-.974Zm3.393-12.062c.128.012.228.029.329.029,2.343,0,4.685.013,7.028,0a4.392,4.392,0,0,1,2.958.959,4.215,4.215,0,0,1-2.7,7.473c-2.387.006-4.774.008-7.16-.006-.368,0-.529.07-.511.483.034.794,0,1.591.018,2.386a1.716,1.716,0,0,0,1.5,1.7,6.193,6.193,0,0,0,.839.024c6.453,0,12.907-.018,19.359.018a1.938,1.938,0,0,0,2.121-2.1c-.055-3.757-.022-7.514-.021-11.271a1.727,1.727,0,0,0-1.95-1.959q-9.967-.006-19.935,0a1.787,1.787,0,0,0-1.873,2.27Z" transform="translate(-6.305 -16.35)" fill="#fff"/>
                    </svg>
                    <p className='m-0'>Drop Your Name Card</p>
                  </button>
                </div>
              )}
            </div>
          </div>
          :
          <div className="directory">
            <ul>
              {onlineUsers.map(u => {
                if (u.online) {
                  const {email, fullName, id} = users.find(dbUser => dbUser.id == u.id)
                  return (
                    <li key={email} onClick={() => toggleAvatar(id)}>
                      <div className="profile-image">
                        <Avatar round name={fullName} size="36" />
                      </div>
                      <div className="info">
                        <div className="name">{fullName?.toLowerCase()}</div>
                      </div>
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        }
      </Directory>
    )
  else {
    return <Loader />
  }
})

export default UserDirectory
